import React, {useState} from 'react';
import {Jumbotron, Form, Button, InputGroup} from 'react-bootstrap';
import * as Constants from '../constants/constants'
import {setGlobalState} from '../GlobalState';
import axios from 'axios';
import {setCookie} from '../cookie';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function Login() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    let obj = {
        "username":username,
        "password":password
    }

    const handleChange = () =>{
        setPassword(document.getElementById('password').value);
        setUsername(document.getElementById('username').value);
    }

    return (
        <div className="container text-left col-sm-12 col-md-4 fade-in-top">
            <Jumbotron className="vertical-center" style={{marginTop:'20%', backgroundColor:'#f5f8fb'}}>
                <div style={{width:'100%', textAlign:'center', marginTop:-20, marginBottom:40}}>
                    <img style={{width:'40%'}} src={'https://advmanager.netaddiction.it/logo_netaddiction.png'}></img>
                </div>
                <Form >
                    <Form.Group>
                        <Form.Label>{Constants.username}</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><FontAwesomeIcon icon="user"/></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control id="username" type="text" placeholder={Constants.placeholderUsername} onChange={handleChange} className={error} required/>
                        </InputGroup>
                        <br/>
                        <Form.Label>{Constants.password}</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><FontAwesomeIcon icon="key"/></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control  id="password" type="password" placeholder={Constants.placeholderPassword} onChange={handleChange} className={error} required/>
                        </InputGroup>
                        <br/><br/>
                        <Button type="submit" className="col-md-12 col-sm-12 login-btn" variant="default" onClick={(e)=>{serviceApiLogin(obj, Constants.getToken,'POST'); e.preventDefault();}}>
                            {Constants.login}
                        </Button>
                    </Form.Group>
                </Form>
            </Jumbotron>
        </div>
    );


    /////////////API//////////////////////////////////

    function serviceApiLogin(obj = {}, url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            data:obj
        };
        axios(options)
        .then((response)=> {
            setGlobalState({showSpinner:false});
            if(response.status == 200){
                setCookie("accessToken", response.data.access, response.data.exp_access);
                setCookie("refreshToken", response.data.refresh, response.data.exp_refresh);
                setGlobalState({accessToken:response.data.access, refreshToken:response.data.refresh, user_id:response.data.user_id, username:response.data.username, email:response.data.email, permissions:response.data.permissions, last_login:response.data.last_login, groups:response.data.groups});
            }
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false});
            if(error.response != undefined){
                if(error.response.status == 400){
                    let Message = Constants.requiredFieldError;
                    var keys = [];
                    for (var k in error.response.data){
                        keys.push(k);
                        Message = Message + Constants[k] + ", ";
                        document.getElementById(k).classList.add('setErrorField');
                    }
                    setGlobalState({showSpinner:false, showAlert:true, messageAlert:Message.substring(0, Message.length - 2), typeAlert:'warning'});
                }
                if(error.response.status == 401){
                    setError('text-danger');
                    setGlobalState({showAlert:true, messageAlert:Constants.unautorizedError, typeAlert:'danger'});
                } 
                if(error.response.status == 500){
                    setGlobalState({showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
                }
            }
            else{
                setGlobalState({showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            }
        })
    }
}
