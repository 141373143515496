import './App.css';
import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useCreateGlobalState, useGlobalState, setGlobalState } from './GlobalState';
import Login  from './containers/login';
import {Alert, Spinner} from 'react-bootstrap';
import Dashboard  from './containers/dashboard';
import * as Constants from './constants/constants';
import axios from 'axios';
import {setCookie, getCookie, deleteCookie} from './cookie';
import { library } from '@fortawesome/fontawesome-svg-core';
import DetectAdBlock from './components/detectAdBlock';
import * as Sentry from '@sentry/browser';
import {faEllipsisH, faUser, faSignOutAlt, faKey, faAd, faAddressBook, faCalendar, faChevronLeft, faChevronRight, faSearch, faPuzzlePiece, faBoxOpen, faPlusCircle, faTrash, faInfo, faEdit, faTimes, faChevronDown, faFileExcel, faChevronUp, faFileImport, faEye, faCopy, faPhone, faPiggyBank, faPaperclip, faExclamationCircle, faHandPaper, faSyncAlt, faCheckSquare} from '@fortawesome/free-solid-svg-icons';
library.add(faUser, faSignOutAlt, faKey, faAd, faAddressBook, faCalendar, faChevronLeft, faChevronRight, faSearch, faPuzzlePiece, faBoxOpen, faPlusCircle, faTrash, faInfo, faEdit, faTimes, faChevronDown, faFileExcel, faChevronUp, faFileImport, faEye, faCopy, faPhone, faPiggyBank, faPaperclip, faExclamationCircle, faHandPaper, faSyncAlt, faEllipsisH, faCheckSquare);

export default function App() {
  const [content, setContent] = useState();
  
  useCreateGlobalState({
    accessToken: getCookie('accessToken'),
    refreshToken: getCookie('refreshToken'),
    showAlert:false,
    messageAlert:"",
    typeAlert:"danger",
    showSpinner:false,
    user_id:null,
    username:null,
    email:null,
    currentPage:null,
    permissions:null,
    groups:null,
    isMobile:false,
    last_login:null
  });

  let globalState = useGlobalState();


  useEffect(() => {
    Sentry.init({dsn: "https://e385d068a2d54e26bead110282a24d73@o384714.ingest.sentry.io/5216361"});
    detectmob();
    if(globalState.refreshToken != null)
      serviceApiVerify(Constants.verifyToken, 'POST');
  },[]);

  useEffect(() => {
    if(globalState.accessToken == null){
      refreshToken();
    }
  },[globalState.accessToken]);

  useEffect(() => {
    if(globalState.refreshToken != null)
      setContent(globalState.permissions!=null?<Dashboard/>:null)
    else
      setContent(<Login/>);
  },[globalState.refreshToken, globalState.permissions]);

  useEffect(() => {
    if(globalState.showAlert == true){
      setTimeout(() => {
        setGlobalState({showAlert:false});
      }, 5000);
    }
  },[globalState.showAlert]);

  const detectmob = () => { 
    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    )
        setGlobalState({isMobile:true});
    else 
        setGlobalState({isMobile:false});
  }

  return (
    <div className="App">
      <DetectAdBlock/>
      <Alert className="alert-position" show={globalState.showAlert} variant={globalState.typeAlert} >
        {globalState.messageAlert}
      </Alert>

      <div className="custom-overlay" style={{display:globalState.showSpinner?'block':'none'}}>
        <div className='spinner-position fade-in'>
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
      {content}
    </div>
  );

  /////////////API//////////////////////////////////
  
  function serviceApiVerify(url, method){
    setGlobalState({showSpinner:true});
    const options = {
        url: process.env.REACT_APP_BASE_URL + url,
        method: method,
        data:{'token': globalState.refreshToken},
    };
    axios(options).then((response)=> {
        setGlobalState({showSpinner:false, user_id:response.data.user_id, username:response.data.username, email:response.data.email, permissions:response.data.permissions, groups:response.data.groups});
    })
    .catch(function (error) {
        if(error.response!=undefined && error.response.status == 401){
          refreshToken();
        }
        else{
          setGlobalState({accessToken:null, refreshToken:null, showSpinner:false});
          deleteCookie('accessToken');
          deleteCookie('refreshToken');
          setContent(<Login/>);
        }
    })
  }

  function refreshToken(){
    const options = {
        url: `${process.env.REACT_APP_BASE_URL}${Constants.refreshToken}`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data:{"refresh": getCookie('refreshToken')}
    };
    axios(options)
    .then((response)=> {
        setCookie("accessToken", response.data.access, response.data.exp_access);
        setGlobalState({accessToken:response.data.access});
        
    })
    .catch(function (error) {
        setGlobalState({accessToken:null, refreshToken:null, showSpinner:false});
        deleteCookie('accessToken');
        deleteCookie('refreshToken');
        setContent(<Login/>); 
    })
  }
}
