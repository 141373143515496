import React, {useEffect, useState} from 'react';
import {Modal, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import * as Constants from '../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function DetectAdBlock(props){
    const [content, setContent] = useState();
    useEffect(()=>{
        // Function called if AdBlock is not detected
        function adBlockNotDetected() {
            setContent();
        }
        // Function called if AdBlock is detected
        function adBlockDetected() {
            setContent(
                <Modal onHide={(e)=>{return}} show={true} centered>
                    <Modal.Header style={{backgroundColor:'#dc3545'}}>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {Constants.adBlockTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{textAlign:'center'}}>
                        <FontAwesomeIcon style={{color:'#dc3545', fontSize:45}} icon="hand-paper"/>
                        <br/><br/>
                        <strong>{Constants.messageAdBlock}</strong>
                    </Modal.Body>
                    <Modal.Footer>
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.verifyAdBlock}</Tooltip>}>
                            <Button variant="danger" onClick={()=>window.location.reload()}>
                                {Constants.verifyAdBlock}
                            </Button>
                        </OverlayTrigger>
                    </Modal.Footer>
                </Modal>
            ); 
        }

        // Recommended audit because AdBlock lock the file 'blockadblock.js' 
        // If the file is not called, the variable does not exist 'blockAdBlock'
        // This means that AdBlock is present
        if(typeof blockAdBlock === 'undefined') {
            adBlockDetected();
        } else {
            window.blockAdBlock.onDetected(adBlockDetected);
            window.blockAdBlock.onNotDetected(adBlockNotDetected);
            // and|or
            window.blockAdBlock.on(true, adBlockDetected);
            window.blockAdBlock.on(false, adBlockNotDetected);
            // and|or
            window.blockAdBlock.on(true, adBlockDetected).onNotDetected(adBlockNotDetected);
        }
    },[])


    return (
        <div id="adblock-wrapper">
            {content}
        </div>
    )
    
}
