import React, {useState, useEffect} from 'react';
import {Table, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useGlobalState, setGlobalState} from '../GlobalState';
import * as Constants from '../constants/constants';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalCampagne from '../components/modalCampagne';
import ModalDelete from '../components/modalDelete';
import TableAction from '../components/tableAction';
import Filtri from '../components/filter';
import TablePagination from '../components/tablePagination';
import moment from 'moment';
import {deleteCookie} from '../cookie';

export default function Campagne(props) {
    const [dataSource, setDatasource] = useState();
    const [content, setContent] = useState();
    const [modalTitle, setModalTitle] = useState();
    const [modalVisibility, setModalVisibility] = useState(false);
    const [modalDeleteVisibility, setModalDeleteVisibility] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [currentEndPoint, setCurrentEndPoint] = useState();
    let emptyPosition={
        fixed:[],
        unfixed:[]
    }
    let emptyObj = {
        impression_booked:null,
        description:"",
        beginning_date:"",
        end_date:"",
        referent:"",
        note:"",
        note_cliente:"",
        is_direct:false,
        positions:emptyPosition,
        direct_customer:null,
        agency:null,
        sector:null,
        user:null,
        payment_type:null,
        unique_code:null,
        states_campaign:null,
        discount:null,
        discount_reason:null,
        amount:null,
        amount_back:null,
        secondary_seller:null,
        secondary_seller_percentage:null,
        invoice_to_direct_customer:null,
        invoice_to_agency:null,
        admanager_report:null,
        media_center_category:null,
        purchase_order:null
    }

    const [fieldValue, setFieldValue] = useState(emptyObj);


    let globalState = useGlobalState();

    useEffect(() => {
        setGlobalState({currentPage:0});
        if(props.unic_code_shared!=undefined && props.unic_code_shared!=null && props.unic_code_shared!=''){
            window.history.pushState('', '', '/');
            serviceApiGet(Constants.getCampagne + '?search=' + props.unic_code_shared, 'GET');
        }
        else
            serviceApiGet(Constants.getCampagne, 'GET');
    },[]);

    useEffect(() => {
        setContent(loadBody())
    },[dataSource])

    const loadBody = () =>{
        if(dataSource != undefined){
            return(
                <tbody>
                    {(dataSource.results.length > 0)
                    ?
                        dataSource.results.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.description}</td>
                                    <td>{item.user!=null?item.user.first_name + " " + item.user.last_name:null}</td>
                                    <td>{item.referent}</td>
                                    <td>{moment(item.beginning_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
                                    <td>{moment(item.end_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
                                    <td>{item.direct_customer!= null?item.direct_customer.description:''}</td>
                                    <td>{item.agency!= null?item.agency.description:''}</td>
                                    <td style={{fontWeight:'bold', color:'#'+item.states_campaign.color}}>{item.states_campaign.description}</td>
                                    <TableAction 
                                        isReadOnly={item.read_only}
                                        detailVisible = {globalState.permissions.siteMenu.campaigns.show!= undefined}
                                        updateVisible = {globalState.permissions.siteMenu.campaigns.update!= undefined}
                                        deleteVisible = {globalState.permissions.siteMenu.campaigns.delete!= undefined}
                                        item = {item}
                                        setModalIsCreate={()=>setIsCreate(false)}
                                        setModalIsCreateTrue={()=>setIsCreate(true)}
                                        setModalTitleCopy={()=>setModalTitle(Constants.copiaCampagna)}
                                        setModalTitleShow={()=>setModalTitle(Constants.modalTitleShow)}
                                        setModalTitleEdit={()=>setModalTitle(Constants.modalTitleEdit)}
                                        setModalVisibility={()=>setModalVisibility(true)}  
                                        setFieldValue={()=>setFieldValue(item)}
                                        setFieldValueForCopy={setFieldValue}
                                        setDeleteId={()=>setDeleteId(item.id)} 
                                        setIsEdit={()=>setIsEdit(true)} 
                                        disableEdit={()=>setIsEdit(false)} 
                                        setModalDeleteVisibility={()=>setModalDeleteVisibility(true)}/>
                                </tr>
                            );
                        })
                    :
                    <tr>
                        <td colSpan="9" style={{textAlign:'center', color:'#ccc'}}><FontAwesomeIcon style={{marginRight:40, fontSize:30}} icon="box-open"/> {Constants.noDataFound}</td>
                    </tr>
                }
                </tbody>
            );    
        }
    }

    
    

    return (
        <div className="container col-sm-12 table-responsive fade-in-left" style={{padding:'40px'}}>
            {globalState.permissions.siteMenu.campaigns.create!= undefined?
                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.nuovaCampagna}</Tooltip>}>
                    <Button variant="default" onClick={()=>{setIsCreate(true); setFieldValue(emptyObj); setModalTitle(Constants.modalTitleNew); setModalVisibility(true);  setIsEdit(true);}}>
                        <FontAwesomeIcon style={{marginRight:10}} icon="plus-circle"/>
                        {Constants.nuovaCampagna}
                    </Button>
                </OverlayTrigger>
                :null
            }
            <br/><br/>
            <Filtri endPoint={Constants.getCampagne} setDatasource={setDatasource} setCurrentEndPoint={setCurrentEndPoint} dataSource={dataSource} />
            <br/>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{Constants.descrizioneCampagna}</th>
                        <th>{Constants.creatore}</th>
                        <th>{Constants.referent}</th>
                        <th>{Constants.dataInizioCampagna}</th>
                        <th>{Constants.dataFineCampagna}</th>
                        <th>{Constants.cliente}</th>
                        <th>{Constants.agenzia}</th>
                        <th>{Constants.states_campaign}</th>
                        <th>{Constants.azioni}</th>
                    </tr>
                </thead>
                {content}
            </Table>
            <TablePagination 
                    dataSource={dataSource} 
                    serviceApiGetPrev={()=>serviceApiGet(Constants.getCampagne + dataSource.previous, 'GET')} 
                    serviceApiGetNext={()=>serviceApiGet(Constants.getCampagne + dataSource.next, 'GET')} />

            <ModalCampagne 
                isCreate={isCreate} 
                reloadTable={()=>serviceApiGet(currentEndPoint, 'GET')} 
                closeModal={()=>setModalVisibility(false)} 
                visible={modalVisibility} 
                dataSource={fieldValue} 
                isEdit={isEdit} 
                title={modalTitle}
                setIsCreate={setIsCreate}
                setModalTitle={setModalTitle}
                setIsEdit={setIsEdit}
                setModalVisibility={setModalVisibility}
                setFieldValue={setFieldValue}/>

            <ModalDelete 
                reloadTable={()=>serviceApiGet(currentEndPoint, 'GET')} 
                closeModal={()=>setModalDeleteVisibility(false)} 
                visible={modalDeleteVisibility} 
                rowid={deleteId} 
                endpoint={Constants.deleteCampagne}/>
        </div>
    );


    /////////////API//////////////////////////////////

    function serviceApiGet(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setDatasource(response.data);
            setCurrentEndPoint(url);
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }
}