import React, {useState, useEffect} from 'react';
import Campagne from './campagne';
import Clienti from './clienti';
import Calendario from './calendario';
import {Navbar, Nav, DropdownButton, Dropdown, Modal, Form, InputGroup, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import * as Constants from '../constants/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {deleteCookie} from '../cookie';
import axios from 'axios';
import {setGlobalState, useGlobalState } from '../GlobalState';
import Posizioni from './posizioni';
import Report from './report'
import Forecast from './forecast'


export default function Dashboard() {
    const [content, setContent] = useState();
    const [title, setTitle] = useState();
    const [currentPage, setCurrentPage] = useState();
    const [modalPasswordVisibility, setModalPasswordVisibility] = useState(false);

    document.onkeydown = function(evt) {
        evt = evt || window.event;
        if (evt.keyCode == 27) {
            if(globalState.last_login !=null){
                if(globalState.last_login)
                    closeModal();
            }
        }
    };

    let globalState = useGlobalState();

    const logOut = () =>{
        setGlobalState({accessToken:null, refreshToken:null, showSpinner:false});
        deleteCookie('accessToken');
        deleteCookie('refreshToken');
    }

    const cambiaPassword = () =>{
        setModalPasswordVisibility(true)
    }

    const closeModal = () =>{
        document.getElementById('oldPassword').value="";
        document.getElementById('newPassword').value="";
        document.getElementById('confPassword').value="";
        setModalPasswordVisibility(false)
    }

    useEffect(()=>{
        if(globalState.last_login != null){
            if(!globalState.last_login)
                setModalPasswordVisibility(true);
        }
    },globalState.last_login)

    const verifyNewPass = () =>{

        if(document.getElementById('oldPassword').value == '')
            return setGlobalState({showAlert:true, messageAlert:Constants.inserisciVecchiaPass, typeAlert:'warning'});
        else if(document.getElementById('confPassword').value != document.getElementById('newPassword').value)
            return setGlobalState({showAlert:true, messageAlert:Constants.passwordNonCorrispondono, typeAlert:'warning'});
        else{
            let tempObj = {
                old_password:document.getElementById('oldPassword').value,
                new_password:document.getElementById('newPassword').value
            }
            serviceApiChangePass(tempObj, Constants.changePassword, 'PUT') 
        }
    }

    const loadShared = () => {
        let urlsplit = window.location.href.split('/');
        if(urlsplit.length > 3)
            return urlsplit[urlsplit.length-1];
        else
            return ''; 
    }

    const renderContent = () =>{
        if(globalState.permissions != null){
            let pageSetted = false;
            if(globalState.isMobile){
                return(
                    <div>
                        <Navbar style={{zIndex:5}} collapseOnSelect expand="lg" variant="dark">
                            <Navbar.Brand>
                                <img style={{width:200, marginTop:-5}} src={'https://advmanager.netaddiction.it/netaddiction-logo.png'}></img>
                            </Navbar.Brand>
                            <div className="popup-menu-custom" style={{right:80, top:14}}>
                                <DropdownButton drop="left" id="dropdown-basic-button" title={<FontAwesomeIcon icon="user"/>}>
                                    <Dropdown.Item onClick={()=>cambiaPassword()}><FontAwesomeIcon icon="key"/><span> {Constants.cambiaPassword}</span></Dropdown.Item>
                                    <Dropdown.Item onClick={()=>logOut()}><FontAwesomeIcon icon="sign-out-alt"/><span> {Constants.esci}</span></Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <Navbar.Toggle style={{zIndex:5}} aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav style={{zIndex:5}} className="mr-auto">
                                    {globalState.permissions.siteMenu.campaigns.show != undefined?
                                        <>
                                        {content == null && !pageSetted?setContent(<Campagne unic_code_shared={loadShared()}/>):null}
                                        {title == null && !pageSetted?setTitle(Constants.campagne):null}
                                        {pageSetted = true}
                                        <Nav.Link style={{color:'#fff'}} href="#" onClick={()=>{setGlobalState({currentPage:0}); setContent(<Campagne/>); setTitle(Constants.campagne);}}><FontAwesomeIcon icon="ad"/> {Constants.campagne}</Nav.Link>
                                        </>
                                    :null}
                                    {globalState.permissions.siteMenu.calendar.show != undefined?
                                        <>
                                        {content == null && !pageSetted?setContent(<Calendario/>):null}
                                        {title == null && !pageSetted?setTitle(Constants.calendario):null}
                                        {pageSetted = true}
                                        <Nav.Link style={{color:'#fff'}} href="#" onClick={()=>{setGlobalState({currentPage:1}); setContent(<Calendario/>); setTitle(Constants.calendario);}}><FontAwesomeIcon icon="calendar"/> {Constants.calendario}</Nav.Link>
                                        </>
                                    :null} 
                                    {globalState.permissions.siteMenu.customers.show != undefined?
                                        <>
                                        {content == null && !pageSetted?setContent(<Clienti/>):null}
                                        {title == null && !pageSetted?setTitle(Constants.clienti):null}
                                        {pageSetted = true}
                                        <Nav.Link style={{color:'#fff'}} href="#" onClick={()=>{setGlobalState({currentPage:2}); setContent(<Clienti/>); setTitle(Constants.clienti);}}><FontAwesomeIcon icon="address-book"/> {Constants.clienti}</Nav.Link>
                                        </>
                                    :null}  
                                    {globalState.permissions.siteMenu.positions.show != undefined?
                                        <>
                                        {content == null && !pageSetted?setContent(<Posizioni/>):null}
                                        {title == null && !pageSetted?setTitle(Constants.position):null}
                                        {pageSetted = true}
                                        <Nav.Link style={{color:'#fff'}} href="#" onClick={()=>{setGlobalState({currentPage:3}); setContent(<Posizioni/>); setTitle(Constants.position);}}><FontAwesomeIcon icon="puzzle-piece"/> {Constants.position}</Nav.Link>
                                        </>
                                    :null}
                                    {globalState.permissions.siteMenu.reports.show != undefined?
                                        <>
                                        {content == null && !pageSetted?setContent(<Report/>):null}
                                        {title == null && !pageSetted?setTitle(Constants.report):null}
                                        {pageSetted = true}
                                        <Nav.Link style={{color:'#fff'}} href="#" onClick={()=>{setGlobalState({currentPage:4}); setContent(<Report/>); setTitle(Constants.report);}}><FontAwesomeIcon icon="file-excel"/> {Constants.report}</Nav.Link>
                                        </>
                                    :null}
                                    {globalState.permissions.siteMenu.forecast.show != undefined?
                                        <>
                                        {content == null && !pageSetted?setContent(<Forecast/>):null}
                                        {title == null && !pageSetted?setTitle(Constants.forecast):null}
                                        {pageSetted = true}
                                        <Nav.Link style={{color:'#fff'}} href="#" onClick={()=>{setGlobalState({currentPage:5}); setContent(<Forecast/>); setTitle(Constants.forecast);}}><FontAwesomeIcon icon="piggy-bank"/> {Constants.forecast}</Nav.Link>
                                        </>
                                    :null}
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                        <h1 className="title-page">{title}</h1>
                        <hr/>
                        {globalState.currentPage == 1?
                            <div id="landscape-device" className="rotate-device">
                                <div style={{marginTop:250}}>
                                    <div className="phone">
                                    </div>
                                    <div className="message">
                                        {Constants.rotateDevice}
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {content}
                    </div>
                );
            }
            else{
                return(
                    <div className="dash-containter">
                        <div>
                            <img src={'https://advmanager.netaddiction.it/netaddiction-logo.png'}></img>
                            <div className="popup-menu-custom">
                                <DropdownButton drop="left" title={
                                    <div>
                                        <div className="img-profile">
                                            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 22c-3.123 0-5.914-1.441-7.749-3.69.259-.588.783-.995 1.867-1.246 2.244-.518 4.459-.981 3.393-2.945-3.155-5.82-.899-9.119 2.489-9.119 3.322 0 5.634 3.177 2.489 9.119-1.035 1.952 1.1 2.416 3.393 2.945 1.082.25 1.61.655 1.871 1.241-1.836 2.253-4.628 3.695-7.753 3.695z"/></svg>
                                        </div>
                                        <span>{globalState.username}</span>
                                    </div>
                                    
                                }>
                                    <Dropdown.Item onClick={()=>cambiaPassword()}><FontAwesomeIcon icon="key"/><span> {Constants.cambiaPassword}</span></Dropdown.Item>
                                    <Dropdown.Item onClick={()=>logOut()}><FontAwesomeIcon icon="sign-out-alt"/><span> {Constants.esci}</span></Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div className="sidebar-custom">
                                {globalState.permissions.siteMenu.campaigns.show != undefined?
                                    <>
                                    {content == null && !pageSetted?setContent(<Campagne unic_code_shared={loadShared()}/>):null}
                                    {title == null && !pageSetted?setTitle(Constants.campagne):null}
                                    {currentPage == null && !pageSetted?setCurrentPage(0):null}
                                    {pageSetted = true}
                                    <p onClick={()=>{setContent(<Campagne/>); setTitle(Constants.campagne); setCurrentPage(0);}} className={currentPage==0?'sidebar-active':""}><FontAwesomeIcon icon="ad"/> {Constants.campagne}</p>
                                    </>
                                :null}
                                {globalState.permissions.siteMenu.calendar.show != undefined?
                                    <>
                                    {content == null && !pageSetted?setContent(<Calendario/>):null}
                                    {title == null && !pageSetted?setTitle(Constants.calendario):null}
                                    {currentPage == null && !pageSetted?setCurrentPage(1):null}
                                    {pageSetted = true}
                                    <p onClick={()=>{setContent(<Calendario/>); setTitle(Constants.calendario); setCurrentPage(1);}} className={currentPage==1?'sidebar-active':""}><FontAwesomeIcon icon="calendar"/> {Constants.calendario}</p>
                                    </>
                                :null}
                                {globalState.permissions.siteMenu.customers.show != undefined?
                                    <>
                                    {content == null && !pageSetted?setContent(<Clienti/>):null}
                                    {title == null && !pageSetted?setTitle(Constants.clienti):null}
                                    {currentPage == null && !pageSetted?setCurrentPage(2):null}
                                    {pageSetted = true}
                                    <p onClick={()=>{setContent(<Clienti/>); setTitle(Constants.clienti); setCurrentPage(2);}} className={currentPage==2?'sidebar-active':""}><FontAwesomeIcon icon="address-book"/> {Constants.clienti}</p>
                                    </>
                                :null}
                                {globalState.permissions.siteMenu.positions.show != undefined?
                                    <>
                                    {content == null && !pageSetted?setContent(<Posizioni/>):null}
                                    {title == null && !pageSetted?setTitle(Constants.position):null}
                                    {currentPage == null && !pageSetted?setCurrentPage(3):null}
                                    {pageSetted = true}
                                    <p onClick={()=>{setContent(<Posizioni/>); setTitle(Constants.position); setCurrentPage(3);}} className={currentPage==3?'sidebar-active':""}><FontAwesomeIcon icon="puzzle-piece"/> {Constants.position}</p>
                                    </>
                                :null}
                                {globalState.permissions.siteMenu.reports.show != undefined?
                                    <>
                                    {content == null && !pageSetted?setContent(<Report/>):null}
                                    {title == null && !pageSetted?setTitle(Constants.report):null}
                                    {currentPage == null && !pageSetted?setCurrentPage(4):null}
                                    {pageSetted = true}
                                    <p onClick={()=>{setContent(<Report/>); setTitle(Constants.report); setCurrentPage(4);}} className={currentPage==4?'sidebar-active':""}><FontAwesomeIcon icon="file-excel"/> {Constants.report}</p>
                                    </>
                                :null}
                                {globalState.permissions.siteMenu.forecast.show != undefined?
                                    <>
                                    {content == null && !pageSetted?setContent(<Forecast/>):null}
                                    {title == null && !pageSetted?setTitle(Constants.forecast):null}
                                    {currentPage == null && !pageSetted?setCurrentPage(5):null}
                                    {pageSetted = true}
                                    <p onClick={()=>{setContent(<Forecast/>); setTitle(Constants.forecast); setCurrentPage(5);}} className={currentPage==5?'sidebar-active':""}><FontAwesomeIcon icon="piggy-bank"/> {Constants.forecast}</p>
                                    </>
                                :null}
                            </div>
                        </div>
                        <div /* style={/* {overflow:'hidden'} */>
                            <div className="title-container fade-in-left">
                                <h1 className="title-page">{title}</h1>
                            </div>
                            {content}
                        </div>
                    </div>
                );
            }
        }
    }

    return (
        <div>
            {renderContent()}
            <Modal onHide={(e)=>{return}} show={modalPasswordVisibility} centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {Constants.cambiaPassword}
                        {/* {globalState.last_login? */}
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.chiudi}</Tooltip>}>
                            <Button style={{right:17, top:17, position:'absolute'}} variant="danger" onClick={()=>closeModal()}>
                                <FontAwesomeIcon style={{fontSize:30}} icon="times"/>
                            </Button>
                        </OverlayTrigger>
                            {/* :null
                        } */}
                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>{Constants.vecchiaPassword}</Form.Label>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text><FontAwesomeIcon icon="key"/></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control  id="oldPassword" type="password" placeholder={Constants.placeholderPassword} required/>
                    </InputGroup>
                    <br/>
                    <Form.Label>{Constants.nuovaPassword}</Form.Label>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text><FontAwesomeIcon icon="key"/></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control  id="newPassword" type="password" placeholder={Constants.placeholderPassword} required/>
                    </InputGroup>
                    <br/>
                    <Form.Label>{Constants.confermaNuovaPassword}</Form.Label>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text><FontAwesomeIcon icon="key"/></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control  id="confPassword" type="password" placeholder={Constants.placeholderPassword} required/>
                    </InputGroup>
                    <br/>
                </Modal.Body>
                <Modal.Footer>
                    {/* {globalState.last_login? */}
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.annulla}</Tooltip>}>
                        <Button variant="secondary" onClick={()=>closeModal()}>{Constants.annulla}</Button>
                    </OverlayTrigger>
                        {/* :null
                    } */}
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.salva}</Tooltip>}>
                        <Button variant="success" onClick={()=>verifyNewPass()}>{Constants.salva}</Button>
                    </OverlayTrigger>
                </Modal.Footer>
            </Modal>
        </div>
    );


    function serviceApiChangePass(obj, url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{
                'Content-Type':'application/json', 
                'Authorization':'Bearer ' + globalState.accessToken,
                'Accept-Language': 'it'
            },
            data:obj
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.passAggiornata, last_login:true, typeAlert:'success'});
            closeModal();
        })
        .catch(function (error) {
            if(error.response.status == 400)
                setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.vecchiaPassErrata, typeAlert:'warning'});
            else
                setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});

            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }
}
