import React, {useEffect, useState} from 'react';
import * as Constants from '../constants/constants';
import {Row, Col, Form, Button, OverlayTrigger, Tooltip, Accordion, Card, Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {useGlobalState, setGlobalState} from '../GlobalState';
import axios from 'axios';
import {deleteCookie} from '../cookie';

export default function SchedulerCard(props) {
    const [chevron, setChevron] = useState(false);
    let globalState = useGlobalState();
    
    const showInAdManager = (dfp_id) =>{
        window.open(Constants.goToAD.replace('*', Constants.networkID) + dfp_id);
    }

    const loadAdModal = () =>{
        return(
            <Modal onHide={(e)=>{return}} style={{backgroundColor:'rgba(0,0,0,0.3)'}} show={true} centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {Constants.importAdManager}
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.chiudi}</Tooltip>}>
                            <Button style={{right:17, top:17, position:'absolute'}} variant="danger" onClick={()=>props.setModalAdManager()}>
                                <FontAwesomeIcon style={{fontSize:30}} icon="times"/>
                            </Button>
                        </OverlayTrigger>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Constants.importAdManagerQuestion}
                </Modal.Body>
                <Modal.Footer>
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.annulla}</Tooltip>}>
                        <Button variant="secondary" onClick={()=>props.setModalAdManager()}>{Constants.annulla}</Button>
                    </OverlayTrigger>
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.importAdManager}</Tooltip>}>
                        <Button variant="success" onClick={()=>ApiImportInAdManager(Constants.importToAdManager.replace('*', props.item.campaign.id), 'POST')}>{Constants.importAdManager}</Button>
                    </OverlayTrigger>
                </Modal.Footer>
            </Modal>
        );
    }

    const checkListStatus = () =>{
        let status;
        props.item.checklist.map((item, index)=>{
            if(!item.completed && status == null){
                if(item.task.priority == 2){
                    status = 'danger';
                }
                else if (item.task.priority < 2 ){
                    status = 'warning';
                }
            }
        })

        if(status == null)
            return 'success';
        return status;
    }

    return (
        <Accordion defaultActiveKey={props.item.got_material?'1':'0'} style={{marginBottom:20, marginBottom:20}} key={props.index}>
            <Card>
            <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.dettaglio}</Tooltip>}>
                <Button style={{position:'absolute', right:10, top:5, zIndex:10}} variant="success" onClick={()=>{props.setFieldValue(); props.setModalVisibility()}}>
                    <FontAwesomeIcon icon="info"/>
                </Button>
            </OverlayTrigger>
            <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.toDO}</Tooltip>}>
                <Button style={{position:'absolute', right:55, top:5, zIndex:10}} variant={checkListStatus()} onClick={()=>{props.setModalChecklistVisibility()}}>
                    <FontAwesomeIcon icon="check-square"/>
                </Button>
            </OverlayTrigger>
            {globalState.permissions.siteMenu.ADaction.import != undefined && props.item.campaign.dfp_id==null?
                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.importAdManager}</Tooltip>}>
                    <Button variant='warning' style={{Index:10, right:105, top:5, position:'absolute'}} onClick={()=>props.setModalAdManager(loadAdModal())}>
                        <FontAwesomeIcon icon='file-import'/>
                    </Button>
                </OverlayTrigger>
                :null
            }
            {globalState.permissions.siteMenu.ADaction.show != undefined && props.item.campaign.dfp_id!=null?
                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.viewonADManager}</Tooltip>}>
                    <Button variant='success' style={{Index:10, right:105, top:5, position:'absolute'}} onClick={()=>showInAdManager(props.item.campaign.dfp_id)}>
                        <FontAwesomeIcon icon='eye'/>
                    </Button>
                </OverlayTrigger>
                :null
            }
            <Accordion.Toggle onClick={()=>setChevron(!chevron)} className={props.item.got_material?'dark-accordion':''} as={Card.Header} eventKey="0" style={{cursor:'pointer', color:props.item.got_material?'#FFFFFF':'#3d3d3d'}}>
                {props.item.got_material?
                    <FontAwesomeIcon style={{marginRight:10, position:'absolute', top:30}} icon={chevron?'chevron-up':'chevron-down'}/>
                    :
                    <FontAwesomeIcon style={{marginRight:10, position:'absolute', top:30}} icon={chevron?'chevron-down':'chevron-up'}/>
                }
                
                <div style={{maxWidth:'95%', marginLeft:30}}>
                    <span style={{fontSize:10}}><strong>{Constants.nomeCampagna + ': ' }</strong></span>
                    <br/>
                    {props.item.campaign.description}
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
                <Card.Body style={{fontSize:13, position:'relative'}}>
                    <Form.Check style={{fontSize:15, position:'absolute', top:20, right:20}} custom id={props.item.id} type="checkbox" label={Constants.materialiPresenti} onChange={()=>props.updateMaterials(Constants.updateCampagnePositionsMaterial.replace('*', props.item.id), 'PUT', {got_material:!props.item.got_material})} checked={props.item.got_material}/>
                    <br/>
                    {props.item.position!=null?
                        <>
                            <span style={{fontSize:10}}><strong>{Constants.posizione + ': ' }</strong></span>
                            <br/>
                            {props.item.position.site.description + ' / ' + props.item.position.area.description + ' / ' + props.item.position.type.description}
                            <br/>
                        </>
                        :null
                    }
                    <hr/>
                    <Row>
                    {props.item.campaign.agency!=null?
                        <Col>
                            <span style={{fontSize:10}}><strong>{Constants.agenzia + ': ' }</strong></span>
                            <br/>
                            {props.item.campaign.agency.description}
                            <br/>
                        </Col>
                        :null
                    }
                    {props.item.campaign.direct_customer!=null?
                        <Col>
                            <span style={{fontSize:10}}><strong>{Constants.cliente + ': ' }</strong></span>
                            <br/>
                            {props.item.campaign.direct_customer.description}
                            <br/>
                        </Col>
                        :null
                    }
                    </Row>
                    <hr/>
                    <Row>
                    {props.item.beginning_date!=null?
                        <Col>
                            <span style={{fontSize:10}}><strong>{Constants.dataInizio + ': ' }</strong></span>
                            <br/>
                            {moment(props.item.beginning_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                            <br/>
                        </Col>
                        :null
                    }
                    {props.item.beginning_date!=null?
                        <Col>
                            <span style={{fontSize:10}}><strong>{Constants.dataFine + ': ' }</strong></span>
                            <br/>
                            {moment(props.item.end_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                            <br/>
                        </Col>
                        :null
                    }
                    </Row>
                    <hr/>
                    <Row>
                    {props.item.impression!=null?
                        <Col>
                            <span style={{fontSize:10}}><strong>{Constants.impression + ': ' }</strong></span>
                            <br/>
                            {props.item.impression}
                            <br/>
                        </Col>
                        :null
                    }
                    {props.item.cpm_sale || props.item.forfait_sale!=null?
                        <Col>
                            <span style={{fontSize:10}}><strong>{props.item.cpm_sale!=null?Constants.cpmVendita:Constants.forfaitVendita + ': ' }</strong></span>
                            <br/>
                            {props.item.cpm_sale!=null?props.item.cpm_sale:props.item.forfait_sale}
                            <br/>
                        </Col>
                        :null
                    }
                    {props.item.campaign.states_campaign!=null?
                        <Col>
                            <span style={{fontSize:10}}><strong>{Constants.states_campaign + ': ' }</strong></span>
                            <br/>
                            {props.item.campaign.states_campaign.description}
                            <br/>
                        </Col>
                        :null
                    }
                    </Row>
                </Card.Body>
            </Accordion.Collapse>
            </Card>
        </Accordion>
    );


    function ApiImportInAdManager(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:response.data.message, typeAlert:'success'}); 
            props.setModalAdManager();
            props.reload();
        })
        .catch(function (error) {
            if(error.response!=undefined && error.response.status == 500){
                setGlobalState({showSpinner:false, showAlert:true, messageAlert:error.response.data.message, typeAlert:'danger'});
            }
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }
}


    

