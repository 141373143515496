import React from 'react';
import {Button, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {setGlobalState, useGlobalState} from '../GlobalState';
import * as Constants from '../constants/constants';
import axios from 'axios';
import {deleteCookie} from '../cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ModalDelete(props) {
    let globalState = useGlobalState();
    
    document.onkeydown = function(evt) {
        evt = evt || window.event;
        if (evt.keyCode == 27) {
            props.closeModal()
        }
    };

    return (
        <Modal onHide={(e)=>{return}} show={props.visible} centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {Constants.elimina}
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.chiudi}</Tooltip>}>
                        <Button style={{right:17, top:17, position:'absolute'}} variant="danger" onClick={()=>props.closeModal()}>
                            <FontAwesomeIcon style={{fontSize:30}} icon="times"/>
                        </Button>
                    </OverlayTrigger>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {Constants.deleteQuestion}
            </Modal.Body>
            <Modal.Footer>
                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.annulla}</Tooltip>}>
                    <Button variant="secondary" onClick={()=>props.closeModal()}>{Constants.annulla}</Button>
                </OverlayTrigger>
                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.elimina}</Tooltip>}>
                    <Button variant="danger" onClick={()=>serviceApiDelete(props.endpoint.replace('*', props.rowid), 'DELETE')}>{Constants.elimina}</Button>
                </OverlayTrigger>
            </Modal.Footer>
        </Modal>
    );


    /////////////API//////////////////////////////////

    function serviceApiDelete(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Authorization':'Bearer ' + globalState.accessToken},
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.successMessage, typeAlert:'success'});
            props.reloadTable();
            props.closeModal()
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }
}


    

