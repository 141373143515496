import React, {useState, useEffect} from 'react';
import {useGlobalState, setGlobalState} from '../GlobalState';
import * as Constants from '../constants/constants';
import axios from 'axios';
import {Button, Accordion, Card, Form, Col, Row, OverlayTrigger, Tabs, Tab, Tooltip, Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {deleteCookie} from '../cookie';
import moment from 'moment';
import ModalCampagne from '../components/modalCampagne';
import SchedulerCard from '../components/schedulerCards';
import Select from 'react-select';

export default function Report() {
    const [fileEndpoint, setFileEndpoint] = useState(null);
    const [chevron, setChevron] = useState(false);
    const [key, setKey] = useState();
    const [currentTab, setCurrentTab] = useState();
    const [content, setContent] = useState();
    const [modalAdManager, setModalAdManager] = useState();
    const [secondarySeller, setSecondarySeller] = useState();
    const [site, setSite] = useState();
    const [type, setType] = useState();
    const [status, setStatus] = useState();
    const [sector, setSector] = useState();
    const [customers, setCustomers] = useState();
    const [agency, setAgency] = useState();
    const [selectedSite, setSelectedSite] = useState();
    const [selectedType, setSelectedType] = useState();
    const [selectedSector, setSelectedSector] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedCustomers, setSelectedCustomers] = useState();
    const [selectedAgency, setSelectedAgency] = useState();
    const [fileEndpointPosition, setFileEndpointPosition] = useState(null);
    const [modalCampagne, setModalCampagne] = useState();
    const [modalCheck, setModalCheck] = useState();
    const [endPointStatus, setEndPointStatus] = useState();

    
    let endPoint;

    let emptyPosition={
        fixed:[],
        unfixed:[]
    }
    let emptyObj = {
        description:"",
        beginning_date:"",
        end_date:"",
        referent:"",
        note:"",
        note_cliente:"",
        is_direct:false,
        positions:emptyPosition,
        direct_customer:null,
        agency:null,
        sector:null,
        user:null,
        payment_type:null,
        unique_code:null,
        states_campaign:null,
        discount:null,
        discount_reason:null,
        amount:null,
        amount_back:null,
        secondary_seller:null,
        secondary_seller_percentage:null
    }
    const [fieldValue, setFieldValue] = useState(emptyObj);
    const [modalVisibility, setModalVisibility] = useState();
    const [modalChecklistVisibility, setModalChecklistVisibility] = useState();
    
    useEffect(()=>{
        if(fieldValue != null){
            setModalCampagne(
                <ModalCampagne isCreate={false} reloadTable={()=>{}} closeModal={()=>{setModalVisibility(false); setModalCampagne(); setFieldValue();}} visible={modalVisibility} dataSource={fieldValue} isEdit={false} title={Constants.modalTitleShow}/>
            );
        }
    },[fieldValue])

    useEffect(()=>{
        if(modalChecklistVisibility != null)
            serviceApiGetChecklist(Constants.getCampagneChecklist.replace('*', modalChecklistVisibility),'GET')
    },[modalChecklistVisibility])

    let globalState = useGlobalState();

    let multiState ={
        Settori:null,
        Siti:null,
        Tipi:null,
        Stati:null,
        Cliente:null,
        Agenzia:null
    }
    const [multiselectState, setMultiselectState] = useState(multiState);


    useEffect(()=>{
        if(selectedAgency != null){
            let cloneEmptyObj = {...multiState}
            let cloneObj = {...multiselectState};
            cloneEmptyObj.Agenzia = cloneObj.Agenzia
            setMultiselectState(cloneEmptyObj);
            setSelectedCustomers();
        }    
    },[selectedAgency])

    useEffect(()=>{
        if(selectedCustomers != null){
            let cloneEmptyObj = {...multiState}
            let cloneObj = {...multiselectState};
            cloneEmptyObj.Cliente = cloneObj.Cliente
            setMultiselectState(cloneEmptyObj);
            setSelectedAgency();
        }
    },[selectedCustomers])
    

    useEffect(()=>{
        setGlobalState({currentPage:4});
        let firstTabSelected = false;

        if(globalState.permissions.siteMenu.reports.report != undefined){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.reportPosizione);
                setCurrentTab(Constants.reportPosizione);
            } 
        }

        if(globalState.permissions.siteMenu.reports.report != undefined){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.reportCampagna);
                setCurrentTab(Constants.reportCampagna);
            } 
        }

        if(globalState.permissions.siteMenu.reports.report != undefined){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.reportCliente);
                setCurrentTab(Constants.reportCliente);
            } 
        }

        if(globalState.permissions.siteMenu.reports.scheduler != undefined){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.scheduler);
                setCurrentTab(Constants.scheduler);
            } 
        }
    },[])


    useEffect(()=>{
        if(key == Constants.reportPosizione){
            serviceApiGetSecondarySeller(Constants.getUtentiAttivi + '?is_seller=true&size=9999', 'GET');
            serviceApiGetSiti(Constants.getSiti + '?size=9999&is_visible=True', 'GET');
            serviceApiGetType(Constants.getTipi + '?size=9999', 'GET');
            serviceApiGetStatus(Constants.getStatus + '?size=9999', 'GET');
            serviceApiGetSectors(Constants.getSettori + '?size=9999', 'GET');
        }

        if(key == Constants.reportCliente){
            serviceApiGetClienti(Constants.getClientiDiretti + '?size=9999', 'GET')
            serviceApiGetAgency(Constants.getAgenzie + '?size=9999', 'GET')
        }

        if(key == Constants.scheduler){
            serviceApiGetFilterResult(Constants.getScheduler + '?size=9999&day=' + moment(new Date()).format('YYYY-MM-DD') + '&states_campaign=2,3,4', 'GET', true);
        }
    },[key])




    useEffect(()=>{
        if(currentTab!= null){
            if(currentTab == Constants.scheduler){
                if(document.getElementById('dateSelected') != null){
                    choseDate(document.getElementById('dateSelected').value);
                }
            }
        }
    },[currentTab])

    const createCheck = (response) =>{
        let obj = []
        response.data.map((item,index)=>{
            obj.push(<div style={{display:'flex', marginBottom:20}}><Form.Check key={index} type="checkbox" label={item.task.name} onChange={()=>updateChecklist(Constants.updateCampagneChecklist.replace('*', item.campaign).replace('+', item.id), 'PUT', {completed:!item.completed}, item.campaign)} checked={item.completed}/> {item.completed_by != null?<small style={{color:'#28a745', marginLeft:10}}>Completato da: {item.completed_by.first_name + ' ' + item.completed_by.last_name}</small>:''}</div>);
        })
        return obj
    }

    const verifyDateFilter = (start, end) =>{
        let sDate;    
        let eDate;

        sDate = document.getElementById(start);
        eDate = document.getElementById(end);
        
        if(parseInt(sDate.value.replace(/-/g,''))>parseInt(eDate.value.replace(/-/g,''))){
            //sDate.value=""
            eDate.value="";
            setGlobalState({showAlert:true, messageAlert:Constants.dateError, typeAlert:'warning'});
        }
    }

    const createReport = (e) =>{
        e.preventDefault();
        if(document.getElementById('startDateFilter').value == "" || document.getElementById('startDateFilter').value == null)
            return setGlobalState({showAlert:true, messageAlert:Constants.needDate, typeAlert:'warning'});
        if(document.getElementById('endDateFilter').value == "" || document.getElementById('endDateFilter').value == null)
            return setGlobalState({showAlert:true, messageAlert:Constants.needDate, typeAlert:'warning'});
        
        serviceApiGet(Constants.getReport + 'campaigns/' + '?beginning_date=' + document.getElementById('startDateFilter').value + '&end_date=' + document.getElementById('endDateFilter').value + '&is_new_method=' + document.getElementById('methodFilter').checked, 'GET', currentTab)
    }

    const createReportClienti = (e) =>{
        e.preventDefault();
        if(document.getElementById('startDateClienteFilter').value == "" || document.getElementById('startDateClienteFilter').value == null)
            return setGlobalState({showAlert:true, messageAlert:Constants.needDate, typeAlert:'warning'});
        if(document.getElementById('endDateClienteFilter').value == "" || document.getElementById('endDateClienteFilter').value == null)
            return setGlobalState({showAlert:true, messageAlert:Constants.needDate, typeAlert:'warning'});
        if(selectedCustomers != null || selectedAgency != null){
            let is_agency;
            let id;

            let selCustomers = '';
            if(selectedCustomers !=undefined){
                selectedCustomers.map((item, index)=>{
                    selCustomers = selCustomers + item.value + ",";
                })
                if(selCustomers.length > 0)
                    selCustomers = selCustomers.substring(0, selCustomers.length - 1);
            }

            let selAgency = '';
            if(selectedAgency !=undefined){
                selectedAgency.map((item, index)=>{
                    selAgency = selAgency + item.value + ",";
                })
                if(selAgency.length > 0)
                    selAgency = selAgency.substring(0, selAgency.length - 1);
            }

            if(selCustomers != ''){
                is_agency = false;
                id = selCustomers;
            }
            if(selAgency != ""){
                is_agency = true;
                id = selAgency;
            }

            let fatturazione = false;
            if(document.getElementById('cliente_fatturazione_check') != null)
                fatturazione = document.getElementById('cliente_fatturazione_check').checked;

            serviceApiGet(Constants.getReport + 'agency_customer/' + '?beginning_date=' + document.getElementById('startDateClienteFilter').value + '&end_date=' + document.getElementById('endDateClienteFilter').value + '&is_agency=' + is_agency + '&id=' + id + '&invoice=' + fatturazione, 'GET', currentTab)
        } 
        else{
            return setGlobalState({showAlert:true, messageAlert:Constants.customersOrAgencyRequired, typeAlert:'warning'});
        }       
    }

    const createReportPosizioni = (e) =>{
        e.preventDefault();
        if(document.getElementById('startDateFilterPosition').value == "" || document.getElementById('startDateFilterPosition').value == null)
            return setGlobalState({showAlert:true, messageAlert:Constants.needDate, typeAlert:'warning'});
        if(document.getElementById('endDateFilterPosition').value == "" || document.getElementById('endDateFilterPosition').value == null)
            return setGlobalState({showAlert:true, messageAlert:Constants.needDate, typeAlert:'warning'});
        
        let positionType = '';
        if(selectedType !=undefined){
            selectedType.map((item, index)=>{
                positionType = positionType + item.value + ",";
            })
            if(positionType.length > 0)
                positionType = positionType.substring(0, positionType.length - 1);
        }
        
        let positionSite = '';
        if(selectedSite !=undefined){
            selectedSite.map((item, index)=>{
                positionSite = positionSite + item.value + ",";
            })
            if(positionSite.length > 0)
            positionSite = positionSite.substring(0, positionSite.length - 1);
        }

        let selSector = '';
        if(selectedSector !=undefined){
            selectedSector.map((item, index)=>{
                selSector = selSector + item.value + ",";
            })
            if(selSector.length > 0)
                selSector = selSector.substring(0, selSector.length - 1);
        }

        let selStatus = '';
        if(selectedStatus !=undefined){
            selectedStatus.map((item, index)=>{
                selStatus = selStatus + item.value + ",";
            })
            if(selStatus.length > 0)
                selStatus = selStatus.substring(0, selStatus.length - 1);
        }

        serviceApiGet(Constants.getReport + 'positions/' + '?beginning_date=' + document.getElementById('startDateFilterPosition').value + '&end_date=' + document.getElementById('endDateFilterPosition').value + '&position_types=' + positionType + '&site=' + positionSite + '&seller=' + document.getElementById('seller').value + '&status=' + selStatus + '&sectors=' + selSector, 'GET', currentTab)
    }

    const loadButtonTab= (tabName) =>{
        let permitted = false;
        
        if(tabName == Constants.reportPosizione){
            permitted=globalState.permissions.siteMenu.reports.report != undefined;
            if(permitted){
                return(
                    <Tab eventKey={tabName} title={tabName}>
                        {loadReportPosizioni()}
                    </Tab>
                )
            }
        }

        if(tabName == Constants.reportCampagna){
            permitted=globalState.permissions.siteMenu.reports.report != undefined;
            if(permitted){
                return(
                    <Tab eventKey={tabName} title={tabName}>
                        {loadReportCampagne()}
                    </Tab>
                )
            }
        }

        if(tabName == Constants.reportCliente){
            permitted=globalState.permissions.siteMenu.reports.report != undefined;
            if(permitted){
                return(
                    <Tab eventKey={tabName} title={tabName}>
                        {loadReportCliente()}
                    </Tab>
                )
            }
        }
            
        if(tabName == Constants.scheduler){
            permitted=globalState.permissions.siteMenu.reports.scheduler != undefined;
            if(permitted){
                return(
                    <Tab eventKey={tabName} title={tabName}>
                        {loadSchedueler()}
                    </Tab>
                )
            }
        }
    }

    const choseDate = (e) =>{
        if(e != ""){
            let startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD').toString();
            let endOfWeek = moment().endOf('isoWeek').format('YYYY-MM-DD').toString();
            if(e == 'allWeek')
                serviceApiGetFilterResult(Constants.getScheduler + '?size=9999&beginning_date=' + startOfWeek + '&end_date=' + endOfWeek + '&states_campaign=2,3,4', 'GET', true);
            else if(e == 'nextWeek')
                serviceApiGetFilterResult(Constants.getScheduler + '?size=9999&beginning_date=' + moment(moment(endOfWeek).add(1,'d')).format('YYYY-MM-DD') + '&end_date=' + moment(moment(endOfWeek).add(7,'d')).format('YYYY-MM-DD') + '&states_campaign=2,3,4' , 'GET', true);
            else if(e == 'allPreorder')
                serviceApiGetFilterResult(Constants.getScheduler + '?size=9999&beginning_date=' + moment(new Date()).format('YYYY-MM-DD') + '&states_campaign=2,3,4', 'GET', true);
            else if(e == 'proiection')
                serviceApiGetFilterResult(Constants.getScheduler + '?size=9999&beginning_date=' + moment(new Date()).format('YYYY-MM-DD') + '&states_campaign=1,2,3,4,7', 'GET', true);
            else if(e == 'hd')
                serviceApiGetSiti(Constants.getSiti + '?search=hdblog.it', 'GET');
            else if(e == 'multi')
                serviceApiGetSiti(Constants.getSiti + '?search=multiplayer.it', 'GET');
            else
                serviceApiGetFilterResult(Constants.getScheduler + '?size=9999&day=' + e + '&states_campaign=2,3,4', 'GET', true);
        }
        else{
            setContent();
        }
    }

    const loadSchedueler = () =>{
        return(
                <>
                    <br/>
                    <Form.Label>{Constants.tipologia}</Form.Label>
                    <Form.Control onChange={(e)=>choseDate(e.target.value)} id="dateSelected" as="select">
                        <option value={moment(new Date()).format('YYYY-MM-DD')}>{Constants.oggi}</option>
                        <option value={moment(moment(new Date()).add(1, 'd')).format('YYYY-MM-DD')}>{Constants.domani}</option>
                        <option value={moment(moment(new Date()).add(2, 'd')).format('YYYY-MM-DD')}>{Constants.tra2gg}</option>
                        <option value={moment(moment(new Date()).add(3, 'd')).format('YYYY-MM-DD')}>{Constants.tra3gg}</option>
                        <option value={moment(moment(new Date()).add(4, 'd')).format('YYYY-MM-DD')}>{Constants.tra4gg}</option>
                        <option value={moment(moment(new Date()).add(5, 'd')).format('YYYY-MM-DD')}>{Constants.tra5gg}</option>
                        <option value={moment(moment(new Date()).add(6, 'd')).format('YYYY-MM-DD')}>{Constants.tra6gg}</option>
                        <option value='allWeek'>{Constants.thisWeek}</option>
                        <option value='nextWeek'>{Constants.nextWeek}</option>
                        <option value='allPreorder'>{Constants.tuttoIlPrenotato}</option>
                        <option value='proiection'>{Constants.proiezione}</option>
                        <option value='hd'>{Constants.hd}</option>
                        <option value='multi'>{Constants.multiplayer}</option>
                    </Form.Control>
                    <br/><br/>
                    {content}
                    {modalAdManager}
                    {modalCampagne}
                </>
        );
    }



    const loadReportCampagne = () =>{
        return(
                <>
                    <Accordion defaultActiveKey="0">
                        <Card>
                        <Accordion.Toggle onClick={()=>setChevron(!chevron)} as={Card.Header} eventKey="0" style={{cursor:'pointer'}}>
                            <h5><FontAwesomeIcon style={{marginRight:10}} icon={chevron?'chevron-down':'chevron-up'}/>{Constants.generaReport}</h5>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body style={{padding:20}}>
                            <Form>
                                {globalState.isMobile?
                                    <>
                                        <Form.Label>{Constants.dataInizio}</Form.Label>
                                        <Form.Control id="startDateFilter" type="date" onChange={()=>verifyDateFilter('startDateFilter', 'endDateFilter')}/>
                                        <br/>
                                        <Form.Label>{Constants.dataFine}</Form.Label>
                                        <Form.Control id="endDateFilter" type="date" onChange={()=>verifyDateFilter('startDateFilter', 'endDateFilter')}/>
                                        <br/>
                                        <Form.Check custom id="methodFilter" type="checkbox" label={Constants.nuovoMetodoQuestion} />
                                    </>
                                    :
                                    <Row>
                                        <Col>
                                            <Form.Label>{Constants.dataInizio}</Form.Label>
                                            <Form.Control id="startDateFilter" type="date" onChange={()=>verifyDateFilter('startDateFilter', 'endDateFilter')}/>
                                        </Col>
                                        <Col>
                                            <Form.Label>{Constants.dataFine}</Form.Label>
                                            <Form.Control id="endDateFilter" type="date" onChange={()=>verifyDateFilter('startDateFilter', 'endDateFilter')}/>
                                        </Col>
                                        <Col>
                                            <Form.Check custom style={{paddingTop:40}} id="methodFilter" type="checkbox" label={Constants.nuovoMetodoQuestion} />
                                        </Col>
                                    </Row>
                                }
                                
                                <br/>
                                <Row>
                                    <Col style={{display:'flex'}}>
                                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.generaReport}</Tooltip>}>
                                            <Button variant="default" type="submit" style={{marginRight:20}} onClick={(e)=>createReport(e)}>
                                                <FontAwesomeIcon style={{marginRight:10}} icon="search"/>
                                                {Constants.generaReport}
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.reset}</Tooltip>}>
                                            <Button variant="default" onClick={()=>setFileEndpoint(null)} type="reset" style={{marginRight:20}}>
                                                <FontAwesomeIcon style={{marginRight:10}} icon="times"/>
                                                {Constants.reset}
                                            </Button>
                                        </OverlayTrigger>
                                        <a id="download-report" style={{textDecoration:'none', display:fileEndpoint!=null?'block':'none'}}>
                                            <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.downloadReport}</Tooltip>}>
                                                <Button variant="success">
                                                    <FontAwesomeIcon style={{marginRight:10}} icon="file-excel"/>
                                                    {Constants.downloadReport}
                                                </Button>
                                            </OverlayTrigger>
                                        </a>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    </Accordion>
                    <br/>
                    {fileEndpoint}
                </>
        );
    }



    const loadReportCliente = () =>{
        return(
                <>
                    <Accordion defaultActiveKey="0">
                        <Card>
                        <Accordion.Toggle onClick={()=>setChevron(!chevron)} as={Card.Header} eventKey="0" style={{cursor:'pointer'}}>
                            <h5><FontAwesomeIcon style={{marginRight:10}} icon={chevron?'chevron-down':'chevron-up'}/>{Constants.generaReport}</h5>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body style={{padding:20}}>
                            <Form>
                                {globalState.isMobile?
                                    <>
                                        <Form.Label>{Constants.dataInizio}</Form.Label>
                                        <Form.Control id="startDateClienteFilter" type="date" onChange={()=>verifyDateFilter('startDateClienteFilter','endDateClienteFilter')}/>
                                        <br/>
                                        <Form.Label>{Constants.dataFine}</Form.Label>
                                        <Form.Control id="endDateClienteFilter" type="date" onChange={()=>verifyDateFilter('startDateClienteFilter','endDateClienteFilter')}/>
                                        <br/>
                                        <Form.Label>{Constants.cliente}</Form.Label>
                                        {loadMultiselect(customers, Constants.cliente)}
                                        <br/>
                                        <Form.Label>{Constants.agenzia}</Form.Label>
                                        {loadMultiselect(agency, Constants.agenzia)}
                                        <br/>
                                    </>
                                    :
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Label>{Constants.dataInizio}</Form.Label>
                                                <Form.Control id="startDateClienteFilter" type="date" onChange={()=>verifyDateFilter('startDateClienteFilter','endDateClienteFilter')}/>
                                            </Col>
                                            <Col>
                                                <Form.Label>{Constants.dataFine}</Form.Label>
                                                <Form.Control id="endDateClienteFilter" type="date" onChange={()=>verifyDateFilter('startDateClienteFilter','endDateClienteFilter')}/>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col>
                                                <Form.Label>{Constants.cliente}</Form.Label>
                                                {loadMultiselect(customers, Constants.cliente)}
                                            </Col>
                                            <Col>
                                                <Form.Label>{Constants.agenzia}</Form.Label>
                                                {loadMultiselect(agency, Constants.agenzia)}
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <br/>
                                <Form.Check custom id="cliente_fatturazione_check" type="checkbox" label={Constants.perFatturazione} />
                                <br/>
                                <Row>
                                    <Col style={{display:'flex'}}>
                                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.generaReport}</Tooltip>}>
                                            <Button variant="default" type="submit" style={{marginRight:20}} onClick={(e)=>createReportClienti(e)}>
                                                <FontAwesomeIcon style={{marginRight:10}} icon="search"/>
                                                {Constants.generaReport}
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.reset}</Tooltip>}>
                                            <Button variant="default" onClick={()=>setFileEndpoint(null)} type="reset" style={{marginRight:20}}>
                                                <FontAwesomeIcon style={{marginRight:10}} icon="times"/>
                                                {Constants.reset}
                                            </Button>
                                        </OverlayTrigger>
                                        <a id="download-report-cliente" style={{textDecoration:'none', display:fileEndpoint!=null?'block':'none'}}>
                                            <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.downloadReport}</Tooltip>}>
                                                <Button variant="success">
                                                    <FontAwesomeIcon style={{marginRight:10}} icon="file-excel"/>
                                                    {Constants.downloadReport}
                                                </Button>
                                            </OverlayTrigger>
                                        </a>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    </Accordion>
                    <br/>
                    {fileEndpoint}
                </>
        );
    }

    const populateSecondarySeller = (obj) =>{
        return obj.results.map((item, index) => {
            return <option key={index} value={item.id}>{item.first_name + " " + item.last_name}</option>;
        })
    }

    const resetFieldPosition = (e) =>{
        e.preventDefault();
        setFileEndpointPosition(null);
        setMultiselectState(multiState);
        document.getElementById('startDateFilterPosition').value = '';
        document.getElementById('endDateFilterPosition').value = '';
        document.getElementById('seller').value = '';
    }

    const loadMultiselect = (obj, name) => {
        if(obj!= null){
            if(obj.results.length > 0){
                let newObj = []
                obj.results.map((item, index)=>{
                    let objItem = {value:null, label:null};
                    objItem.value = item.id;
                    objItem.label = item.description;
                    newObj.push(objItem);          
                })
                return(
                    <>
                        <Select
                            value = {multiselectState[name]}
                            onChange = {(e)=>setSelectedMultiselect(e, name)}
                            closeMenuOnSelect={false}
                            //components={animatedComponents}
                            isMulti
                            options={newObj}
                            noOptionsMessage={() => Constants.noDataFound}
                        />
                    </>
                );
            }
        }
    }

    const setSelectedMultiselect = (e, name) =>{
        let newObj = {...multiselectState};
        if(name==Constants.siti)
            setSelectedSite(e);
        else if(name==Constants.tipi)
            setSelectedType(e)
        else if(name==Constants.settori)
            setSelectedSector(e)
        else if(name==Constants.cliente)
            setSelectedCustomers(e)
        else if(name==Constants.agenzia)
            setSelectedAgency(e)
        else
            setSelectedStatus(e)
        if(e!=null){
            newObj[name] = e.value;
            setMultiselectState(newObj);
        }
    }

    

    const loadReportPosizioni = () =>{
        return(
                <>
                    <Accordion defaultActiveKey="0">
                        <Card>
                        <Accordion.Toggle onClick={()=>setChevron(!chevron)} as={Card.Header} eventKey="0" style={{cursor:'pointer'}}>
                            <h5><FontAwesomeIcon style={{marginRight:10}} icon={chevron?'chevron-down':'chevron-up'}/>{Constants.generaReport}</h5>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body style={{padding:20}}>
                            <Form>
                                {globalState.isMobile?
                                    <>
                                        <Form.Label>{Constants.dataInizio}</Form.Label>
                                        <Form.Control id="startDateFilterPosition" type="date" onChange={()=>verifyDateFilter('startDateFilterPosition', 'endDateFilterPosition')}/>
                                        <br/>
                                        <Form.Label>{Constants.dataFine}</Form.Label>
                                        <Form.Control id="endDateFilterPosition" type="date" onChange={()=>verifyDateFilter('startDateFilterPosition', 'endDateFilterPosition')}/>
                                        <br/>
                                        <Form.Label>{Constants.settori}</Form.Label>
                                        {loadMultiselect(sector, Constants.settori)}
                                        <br/>
                                        <Form.Label>{Constants.siti}</Form.Label>
                                        {loadMultiselect(site, Constants.siti)}
                                        <br/>
                                        <Form.Label>{Constants.tipi}</Form.Label>
                                        {loadMultiselect(type, Constants.tipi)}
                                        <br/>
                                        <Form.Label>{Constants.stati}</Form.Label>
                                        {loadMultiselect(status, Constants.stati)}
                                        <br/>
                                        <Form.Label>{Constants.creatore}</Form.Label>
                                        <Form.Control id="seller" as="select">
                                            <option value="">{Constants.seleziona}</option>
                                            {secondarySeller}
                                        </Form.Control>
                                    </>
                                    :
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Label>{Constants.dataInizio}</Form.Label>
                                                <Form.Control id="startDateFilterPosition" type="date" onChange={()=>verifyDateFilter('startDateFilterPosition', 'endDateFilterPosition')}/>
                                            </Col>
                                            <Col>
                                                <Form.Label>{Constants.dataFine}</Form.Label>
                                                <Form.Control id="endDateFilterPosition" type="date" onChange={()=>verifyDateFilter('startDateFilterPosition', 'endDateFilterPosition')}/>
                                            </Col>
                                            {secondarySeller != null?
                                                <Col>
                                                    <Form.Label>{Constants.creatore}</Form.Label>
                                                    <Form.Control id="seller" as="select">
                                                        <option value="">{Constants.seleziona}</option>
                                                        {secondarySeller}
                                                    </Form.Control>
                                                </Col>
                                                :null
                                            }
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col>
                                                <Form.Label>{Constants.settori}</Form.Label>
                                                {loadMultiselect(sector, Constants.settori)}
                                            </Col>
                                            <Col>
                                                <Form.Label>{Constants.siti}</Form.Label>
                                                {loadMultiselect(site, Constants.siti)}
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col>
                                                <Form.Label>{Constants.tipi}</Form.Label>
                                                {loadMultiselect(type, Constants.tipi)}
                                            </Col>
                                            <Col>
                                                <Form.Label>{Constants.stati}</Form.Label>
                                                {loadMultiselect(status, Constants.stati)}
                                            </Col>
                                        </Row>
                                    </>
                                }
                                
                                <br/>
                                <Row>
                                    <Col style={{display:'flex'}}>
                                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.generaReport}</Tooltip>}>
                                            <Button variant="default" type="submit" style={{marginRight:20}} onClick={(e)=>createReportPosizioni(e)}>
                                                <FontAwesomeIcon style={{marginRight:10}} icon="search"/>
                                                {Constants.generaReport}
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.reset}</Tooltip>}>
                                            <Button variant="default" onClick={(e)=>resetFieldPosition(e)} type="reset" style={{marginRight:20}}>
                                                <FontAwesomeIcon style={{marginRight:10}} icon="times"/>
                                                {Constants.reset}
                                            </Button>
                                        </OverlayTrigger>
                                        <a id="download-report-position" style={{textDecoration:'none', display:fileEndpointPosition!=null?'block':'none'}}>
                                            <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.downloadReport}</Tooltip>}>
                                                <Button variant="success">
                                                    <FontAwesomeIcon style={{marginRight:10}} icon="file-excel"/>
                                                    {Constants.downloadReport}
                                                </Button>
                                            </OverlayTrigger>
                                        </a>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    </Accordion>
                    <br/>
                    {fileEndpointPosition}
                </>
        );
    }

    const createCards = (tempObj) =>{
        let obj = [];
        
        tempObj.results.map((item, index) =>{
            obj.push(
                <SchedulerCard key={index} reload={()=>serviceApiGetFilterResult(endPoint, 'GET', false)} setModalAdManager={setModalAdManager} item={item} setFieldValue={()=>setFieldValue(item.campaign)} setModalVisibility={()=>setModalVisibility(true)} setModalChecklistVisibility={()=>setModalChecklistVisibility(item.campaign.id)} index={index} updateMaterials={updateMaterials}/>
            )
        })
        return obj
    }

    return (
        <div className="container col-sm-12 table-responsive fade-in-left" style={{padding:'40px', minHeight:!globalState.isMobile?1000:1200}}>
            <Tabs activeKey={key} onSelect={k => {setKey(k); setCurrentTab(k);}}>
                {loadButtonTab(Constants.reportPosizione)}
                {loadButtonTab(Constants.reportCampagna)}
                {loadButtonTab(Constants.reportCliente)}
                {process.env.REACT_APP_CUSTOM != null?
                    loadButtonTab(Constants.scheduler)
                    :null
                }
            </Tabs> 
            {modalCheck}
        </div>
    );

    /////////////API//////////////////////////////////

    function serviceApiGet(url, method, tab){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{},
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            let a = document.getElementById('download-report');
            a.href = response.data.link;

            if(tab == Constants.reportCampagna){
                let a = document.getElementById('download-report');
                a.href = response.data.link;
                setFileEndpoint(<iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + response.data.link} style={{width:'100%', height:'65vh'}} frameBorder='0'> </iframe>)
            }
            else if(tab == Constants.reportCliente){
                let a = document.getElementById('download-report-cliente');
                a.href = response.data.link;
                setFileEndpoint(<iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + response.data.link} style={{width:'100%', height:'65vh'}} frameBorder='0'> </iframe>)
            }
            else if(tab == Constants.reportPosizione){
                let a = document.getElementById('download-report-position');
                a.href = response.data.link;
                setFileEndpointPosition(<iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + response.data.link} style={{width:'100%', height:'65vh'}} frameBorder='0'> </iframe>)
            }
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetFilterResult(url, method, showMsg){
        setGlobalState({showSpinner:true});
        if(showMsg)
            setContent();
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setEndPointStatus(url);
            endPoint = url;
            setContent(createCards(response.data));
            if(showMsg)
                setGlobalState({showAlert:true, messageAlert:Constants.searchMessagePositions + ' ' + response.data.count, typeAlert:'primary'});
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetSecondarySeller(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setSecondarySeller(populateSecondarySeller(response.data))
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    
    function updateMaterials(url, method, obj){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:obj
        };
        axios(options).then((response)=> {
            serviceApiGetFilterResult(endPoint, 'GET', false);
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.successMessage, typeAlert:'success'});
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetSiti(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            if(response.data.results.length > 0)
                serviceApiGetFilterResult(Constants.getScheduler + '?size=9999&beginning_date=' + moment(new Date()).format('YYYY-MM-DD') + '&site=' + response.data.results[0].id + '&states_campaign=1,2,3,4,7', 'GET', true);
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetSiti(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setSite(response.data)
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetType(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setType(response.data)
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetStatus(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setStatus(response.data);
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetSectors(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setSector(response.data);
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetClienti(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setCustomers(response.data)
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetAgency(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setAgency(response.data)
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetChecklist(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            if(response.data != null){
                setModalCheck(
                    <Modal show="true" centered>
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {Constants.toDO}
                                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.chiudi}</Tooltip>}>
                                    <Button style={{right:17, top:17, position:'absolute'}} variant="danger" onClick={()=>{setModalCheck(); setModalChecklistVisibility(); serviceApiGetFilterResult(endPointStatus, 'GET', false);}}>
                                        <FontAwesomeIcon style={{fontSize:30}} icon="times"/>
                                    </Button>
                                </OverlayTrigger>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                           {createCheck(response)} 
                        </Modal.Body>
                    </Modal>
                );
            }
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function updateChecklist(url, method, obj, campaign_id){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:obj
        };
        axios(options).then((response)=> {
            setModalChecklistVisibility();
            setModalChecklistVisibility(campaign_id);
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }
}


    

