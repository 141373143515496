import React, {useState} from 'react';
import * as Constants from '../constants/constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useGlobalState, setGlobalState} from '../GlobalState';
import moment from 'moment';
import {Button, Row, Col, InputGroup, Accordion, Card, Form, FormControl, OverlayTrigger, Tooltip} from 'react-bootstrap';

export default function PositionAccordion(props) {
    let globalState = useGlobalState();
    const [chevron, setChevron] = useState(false);

    const positionSelectChange = (e, fixed, elementPosition) =>{
        let oldObj = {...props.positionElement};
        if(fixed)
            oldObj.fixed[elementPosition].position = e.target.value;
        else
            oldObj.unfixed[elementPosition].position = e.target.value;

        props.setPositionElement(oldObj)
    }

    const beginningDateChange = (fixed, elementPosition) =>{
        let sDate = document.getElementById((fixed?'fix':'unfix') + 'posDateStart' + elementPosition).value;
        let sHour = document.getElementById((fixed?'fix':'unfix') + 'posHourStart' + elementPosition).value;

        if(sDate!=""){
            if(sHour == "")
                sHour = "T00:01" ;
            else
                sHour="T"+ sHour;
            
            let oldObj = {...props.positionElement};
            if(fixed)
                oldObj.fixed[elementPosition].beginning_date = sDate + sHour;
            else
                oldObj.unfixed[elementPosition].beginning_date = sDate + sHour;
            props.setPositionElement(oldObj)
        }
    }

    const endDateChange = (fixed, elementPosition) =>{
        let eDate = document.getElementById((fixed?'fix':'unfix') + 'posDateEnd' + elementPosition).value;
        let eHour = document.getElementById((fixed?'fix':'unfix') + 'posHourEnd' + elementPosition).value;

        if(eDate!=""){
            if(eHour == "" || eHour=="00:00")
                eHour = "T23:59" ;
            else
                eHour="T"+ eHour;

            let oldObj = {...props.positionElement};
            if(fixed)
                oldObj.fixed[elementPosition].end_date = eDate + eHour;
            else
                oldObj.unfixed[elementPosition].end_date = eDate + eHour;
            props.setPositionElement(oldObj)
        }
    }

    const cpmVenditaChange = (e, fixed, elementPosition) =>{
        let oldObj = {...props.positionElement};
        if(fixed)
            oldObj.fixed[elementPosition].forfait_sale = e.target.value;
        else
            oldObj.unfixed[elementPosition].cpm_sale = e.target.value;
            
        props.setPositionElement(oldObj)
    }

    const cpmGiroChange = (e, fixed, elementPosition) =>{
        let oldObj = {...props.positionElement};
        if(fixed)
            oldObj.fixed[elementPosition].forfait_back = e.target.value;
        else
            oldObj.unfixed[elementPosition].cpm_back = e.target.value;
            
        props.setPositionElement(oldObj)
    }

    const impressionChange = (e, fixed, elementPosition) =>{
        let oldObj = {...props.positionElement};
        if(fixed)
            oldObj.fixed[elementPosition].impression = e.target.value;
        else
            oldObj.unfixed[elementPosition].impression = e.target.value;
            
        props.setPositionElement(oldObj)
    }


    const verifyDatePosition = (fixed, index) =>{     
        let sDate = document.getElementById((fixed?'fix':'unfix') + 'posDateStart' + index);
        let eDate = document.getElementById((fixed?'fix':'unfix') + 'posDateEnd' + index);
        let sHour = document.getElementById((fixed?'fix':'unfix') + 'posHourStart' + index);
        let eHour = document.getElementById((fixed?'fix':'unfix') + 'posHourEnd' + index);
        if(parseInt(sDate.value.replace(/-/g,''))>parseInt(eDate.value.replace(/-/g,''))){
            //sDate.value=""
            eDate.value="";
            return setGlobalState({showAlert:true, messageAlert:Constants.dateError, typeAlert:'warning'});
        }
        else if(parseInt(sDate.value.replace(/-/g,'')) == parseInt(eDate.value.replace(/-/g,''))){
            if(parseInt(sHour.value.replace(/T/g,'').replace(/:/g,''))>=parseInt(eHour.value.replace(/T/g,'').replace(/:/g,''))){
                //sHour.value=""
                eHour.value="";
                return setGlobalState({showAlert:true, messageAlert:Constants.hourError, typeAlert:'warning'});
            }
        }
    }
    
    const composeTitle = () =>{
        if(props.item != undefined){
            if(props.item.position !=null){
                if(props.item.position.id == undefined){
                    if(props.fixed){
                        if(props.positionsFixed != null){
                            for(let i = 0; i<props.positionsFixed.length; i++){
                                if(props.positionsFixed[i].props.value == props.item.position){
                                    return props.positionsFixed[i].props.children
                                }
                            }
                        }
                    }   
                    else {
                        if(props.positionsUnfixed != null){
                            for(let i = 0; i<props.positionsUnfixed.length; i++){
                                if(props.positionsUnfixed[i].props.value == props.item.position){
                                    return props.positionsUnfixed[i].props.children
                                }
                            }
                        }
                    }    
                }
                else{
                    if(props.fixed){
                        if(props.positionsFixed != null){
                            for(let i = 0; i<props.positionsFixed.length; i++){
                                if(props.positionsFixed[i].props.value == props.item.position.id){
                                    return props.positionsFixed[i].props.children
                                }
                            }
                        }
                    }   
                    else {
                        if(props.positionsUnfixed != null){
                            for(let i = 0; i<props.positionsUnfixed.length; i++){
                                if(props.positionsUnfixed[i].props.value == props.item.position.id){
                                    return props.positionsUnfixed[i].props.children
                                }
                            }
                        }
                    }    
                }
            }
        }
    }

    return (
        <Accordion defaultActiveKey={props.isCreate?'0':'1'} key={props.index} style={{marginBottom:15}}>
            <Card>
                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.copiaPosizione}</Tooltip>}>
                    <Button style={{position:'absolute', right:60, top:10, width:40, zIndex:10}} variant="primary" onClick={()=>props.loadCopyPos()} disabled={!props.isEdit}>
                        <FontAwesomeIcon icon="copy"/>
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.elimina}</Tooltip>}>
                    <Button style={{position:'absolute', right:10, top:10, width:40, zIndex:10}} variant="danger" onClick={()=>props.setModalDeletePosition()} disabled={!props.isEdit}>
                        <FontAwesomeIcon icon="trash"/>
                    </Button>
                </OverlayTrigger>
                <Accordion.Toggle onClick={()=>setChevron(!chevron)} as={Card.Header} eventKey="0" style={{cursor:'pointer'}}>
                    <h6><FontAwesomeIcon style={{marginRight:10}} icon={chevron?'chevron-up':'chevron-down'}/>{(props.index + 1) +". " + Constants.posizione  + " " + (props.fixed?Constants.fissa:Constants.nonFissa)}</h6>
                    <br/>
                    {(chevron==true && props.item.impression!=null && props.isCreate) || (chevron==false && props.item.impression!=null && !props.isCreate)?
                        
                            <div style={{position:'absolute', display:'flex', flexWrap:'wrap', left:10, fontSize:9, bottom:20}}>
                                <div>
                                    <span>
                                        {Constants.impression + ': ' + props.item.impression}
                                    </span>
                                </div>
                                <div style={{marginLeft:20, color:'#FF0000'}}>
                                    {!props.fix && props.item.deficit != null?
                                        <>
                                            <FontAwesomeIcon style={{marginRight:5}} icon='exclamation-circle'/>
                                            <span>
                                                {Constants.deficit + ': ' + props.item.deficit}
                                            </span>
                                        </>
                                        :null
                                    }
                                </div>
                            </div>
                        :null
                    }
                    <span style={{position:'absolute', left:10, fontSize:9, bottom:5}}>
                        {composeTitle()}
                    </span>
                    <span style={{position:'absolute', right:10, fontSize:9, bottom:5}}>
                        {moment(props.item.beginning_date).format('DD/MM/YYYY') + " - " + moment(props.item.end_date).format('DD/MM/YYYY')}
                    </span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body style={{padding:20}}>
                        <Form.Label>{Constants.posizione}</Form.Label>
                        {props.item.position==null?
                            <>
                            <Form.Control id={'pos' + props.index} as="select" disabled={!props.isEdit} value={props.item.position !=null?props.item.position:''} onChange={(e)=>positionSelectChange(e, props.fixed, props.index)}>
                                <option value="">{Constants.seleziona}</option>
                                {props.fixed?props.positionsFixed:props.positionsUnfixed}
                            </Form.Control>
                            </>
                        :
                            <>
                            <Form.Control id={'pos' + props.index} as="select" disabled={!props.isEdit} value={props.item.position.id == undefined? props.item.position:props.item.position.id} onChange={(e)=>positionSelectChange(e, props.fixed, props.index)}>
                                <option value="">{Constants.seleziona}</option>
                                {props.fixed?props.positionsFixed:props.positionsUnfixed}
                            </Form.Control>
                            </>
                        }
                        
                        <Row>
                            <Col>
                                <Form.Label>{Constants.dataInizio}</Form.Label>
                                <Form.Control 
                                    id={(props.fixed?'fix':'unfix') + 'posDateStart' + props.index} 
                                    type="date" 
                                    disabled={!props.isEdit}
                                    value={moment(props.item.beginning_date).format('YYYY-MM-DD')} 
                                    onChange={()=>{beginningDateChange(props.fixed, props.index); verifyDatePosition(props.fixed, props.index);}} 
                                    onKeyDown={(e) => e.preventDefault()} required/>
                            </Col>
                            <Col>
                                <Form.Label>{Constants.oraInizio}</Form.Label>
                                <Form.Control id={(props.fixed?'fix':'unfix') + 'posHourStart' + props.index} type="time" disabled={!props.isEdit} value={moment(props.item.beginning_date).format('HH:mm')} onChange={()=>{beginningDateChange(props.fixed, props.index); verifyDatePosition(props.fixed, props.index);}} required/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>{Constants.dataFine}</Form.Label>
                                <Form.Control
                                    id={(props.fixed?'fix':'unfix') + 'posDateEnd' + props.index} 
                                    type="date" 
                                    disabled={!props.isEdit} 
                                    value={moment(props.item.end_date).format('YYYY-MM-DD')} 
                                    onChange={()=>{endDateChange(props.fixed, props.index); verifyDatePosition(props.fixed, props.index);}} 
                                    onKeyDown={(e) => e.preventDefault()} required/>
                            </Col>
                            <Col>
                                <Form.Label>{Constants.oraFine}</Form.Label>
                                <Form.Control id={(props.fixed?'fix':'unfix') + 'posHourEnd' + props.index} type="time" disabled={!props.isEdit} value={moment(props.item.end_date).format('HH:mm')} onChange={()=>{endDateChange(props.fixed, props.index); verifyDatePosition(props.fixed, props.index);}} required/>
                            </Col>
                        </Row>
                        {!globalState.groups.includes(Constants.underground)?
                            <>
                                <Form.Label>{props.fixed?Constants.forfaitVendita:Constants.cpmVendita}</Form.Label>
                                <InputGroup>
                                    {props.fixed?
                                        <FormControl id={'posSell' + props.index} type="number" min="0" value={props.item.forfait_sale!=null?props.item.forfait_sale:''} placeholder={Constants.inserisciValorePlaceholder} disabled={!props.isEdit} onChange={(e)=>cpmVenditaChange(e, props.fixed, props.index)} required/>
                                        :
                                        <FormControl id={'posSell' + props.index} type="number" min="0" value={props.item.cpm_sale!=null?props.item.cpm_sale:''} placeholder={Constants.inserisciValorePlaceholder} disabled={!props.isEdit} onChange={(e)=>cpmVenditaChange(e, props.fixed, props.index)} required/>
                                    }
                                    <InputGroup.Append>
                                    <InputGroup.Text>€</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </>
                            :null
                        }
                        
                        <Form.Label>{props.fixed?Constants.forfaitGiro:Constants.cpmGiro}</Form.Label>
                        <InputGroup>
                            {props.fixed?
                                <FormControl id={'posBack' + props.index} type="number" min="0" value={props.item.forfait_back!=null?props.item.forfait_back:''} placeholder={Constants.inserisciValorePlaceholder} disabled={!props.isEdit} onChange={(e)=>cpmGiroChange(e, props.fixed, props.index)} required/>
                                :
                                <FormControl id={'posBack' + props.index} type="number" min="0" value={props.item.cpm_back!=null?props.item.cpm_back:''} placeholder={Constants.inserisciValorePlaceholder} disabled={!props.isEdit} onChange={(e)=>cpmGiroChange(e, props.fixed, props.index)} required/>
                            }
                            
                            <InputGroup.Append>
                            <InputGroup.Text>€</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        {!globalState.groups.includes(Constants.underground)?
                            <>
                                <Form.Label>{Constants.impression}</Form.Label>
                                <Form.Control id={'posImpression' + props.index} type="number" placeholder={Constants.impressionPlaceholder} value={props.item.impression!=null?props.item.impression:''} disabled={!props.isEdit} onChange={(e)=>impressionChange(e, props.fixed, props.index)} required/>
                            </>
                            :null
                        }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}


    

