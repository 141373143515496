import React, {useState, useEffect} from 'react';
import {Table, Button, Modal, Form, Tabs, Tab, InputGroup, FormControl, Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useGlobalState, setGlobalState} from '../GlobalState';
import * as Constants from '../constants/constants';
import axios from 'axios';
import {deleteCookie} from '../cookie';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalDelete from '../components/modalDelete';
import TableAction from '../components/tableAction';
import TablePagination from '../components/tablePagination';
import Filtri from '../components/filter';

export default function Posizioni() {
    
    const [dataSource, setDatasource] = useState();
    const [key, setKey] = useState();
    const [content, setContent] = useState();
    const [modalTitle, setModalTitle] = useState();
    const [modalVisibility, setModalVisibility] = useState(false);
    const [modalDeleteVisibility, setModalDeleteVisibility] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [currentTab, setCurrentTab] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [endPoint, setEndPoint] = useState()
    const [activeUsers, setActiveUsers] = useState();
    const [endPointDelete, setEndPointDelete] = useState();
    const [endPointCreate, setEndPointCreate] = useState();
    const [endPointUpdate, setEndPointUpdate] = useState();
    const [area, setArea] = useState();
    const [site, setSite] = useState();
    const [type, setType] = useState();
    const [fullArea, setFullArea] = useState();
    const [fullSite, setFullSite] = useState();
    const [fullType, setFullType] = useState();
    const [unselectedAdUnits, setUnselectedAdUnits] = useState();
    const [selectedAdUnits, setSelectedAdUnits] = useState();
    const [unselectedCustomTargeting, setUnselectedCustomTargeting] = useState();
    const [selectedCustomTargeting, setSelectedCustomTargeting] = useState();
    const [unselectedDominancePosition, setUnselectedDominancePosition] = useState();
    const [selectedDominancePosition, setSelectedDominancePosition] = useState();
    const [fullPositions, setFullPositions] = useState();
    const [positions, setPositions] = useState();
    const [isCreate, setIsCreate] = useState(false);
    const [dominancePosIdVisibility,setDominancePosIdVisibility] = useState(false);
    const [customTargetingVisibility,setCustomTargetingVisibility] = useState(false);
    const [currentEndPoint, setCurrentEndPoint] = useState();
    const [selectAdUnitVisible, setSelectAdUnitVisible] = useState(false);
    const [modalIncompatiblePositionShow, setModalIncompatiblePositionShow] = useState(false);
    const [unselectedIncompatiblePosition, setUnselectedIncompatiblePosition] = useState();
    const [selectedIncompatiblePosition, setSelectedIncompatiblePosition] = useState();
    const [excludedPositions,setExcludedPositions] = useState();
    const [currentPosition,setCurrentPosition] = useState();
    
    
    let emptyObj = {
        description:'',
        fixed:null,
        fieldValue:null,
        referents:[],
        area:null,
        site:null,
        type:null,
        is_visible:true,
        impression_month:null,
        is_netaddiction_site:false,
        position:null,
        is_dominance:false,
        dfp_adunit_id:null,
        width:null,
        height:null,
        line_item_name:null,
        dominance_adunits:null,
        name:null,
        adunits:null,
        percentage_distribution:null,
        dfp_key_id:null,
        dfp_value_id:null,
        custom_targeting:null
    }
    const [fieldValue, setFieldValue] = useState(emptyObj);

    useEffect(()=>{
        if(currentTab == Constants.adUnit){
            if(isCreate)
                setDominancePosIdVisibility(false);
            else
                setDominancePosIdVisibility(fieldValue.is_dominance);
        }
    },[modalVisibility])
    
    useEffect(()=>{
        if(fieldValue.site != null){
            serviceApiGetAdUnitFromSite(Constants.getAdUnit + '?position__site=' + fieldValue.site.id + '&size=9999','GET');
            setSelectAdUnitVisible(true);
        }   
    },[fieldValue.site])

    useEffect(()=>{
        if(fieldValue.position != null){
            if(fullPositions != undefined){
                for(let i = 0; i<fullPositions.results.length; i++){
                    if(fullPositions.results[i].id == fieldValue.position.id){
                        if(fullPositions.results[i].type.description.includes('Dominance') || fullPositions.results[i].type.description.includes('dominance')){
                            ApiSelectPositionBySite(Constants.getAdUnit + '?position__site=' + fullPositions.results[i].site.id + '&size=9999','GET');
                        }
                        else{
                            setDominancePosIdVisibility(false);
                        }
                            
                        break;
                    }
                }
            }
        }
    },[fieldValue.position])

    useEffect(()=>{
        if(fieldValue.custom_targeting != null){
            getCustomTargeting(Constants.getCustomTargeting + '?&size=9999','GET');
            setCustomTargetingVisibility(true);
        }
    },[fieldValue.custom_targeting])

    let globalState = useGlobalState();

    useEffect(() => {
        setGlobalState({currentPage:3});
        let firstTabSelected = false;
        if(globalState.permissions.siteMenu.positions.aggregate.show != null){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.aggregate);
                setEndPoint(Constants.getAggregate);
                setCurrentTab(Constants.aggregate);
            } 
        }

        if(globalState.permissions.siteMenu.positions.ADunits.show != null){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.adUnit);
                setEndPoint(Constants.getAdUnit);
                setCurrentTab(Constants.adUnit);
            } 
        }

        if(globalState.permissions.siteMenu.positions.position.show != null){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.position);
                setEndPoint(Constants.getPosition);
                setCurrentTab(Constants.position);
            } 
        }

        if(globalState.permissions.siteMenu.positions.site.show != null){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.siti);
                setEndPoint(Constants.getSiti);
                setCurrentTab(Constants.siti);
            } 
        }

        if(globalState.permissions.siteMenu.positions.area.show != null){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.aree);
                setEndPoint(Constants.getArea);
                setCurrentTab(Constants.aree);
            } 
        }

        if(globalState.permissions.siteMenu.positions.type.show != null){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.tipi);
                setEndPoint(Constants.getTipi);
                setCurrentTab(Constants.tipi);
            } 
        }

        if(globalState.permissions.siteMenu.positions.customtargeting.show != null){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.customTargeting);
                setEndPoint(Constants.getCustomTargeting);
                setCurrentTab(Constants.customTargeting);
            } 
        }
    },[]);

    const populatePositions = (obj) =>{
        let position = [];
        obj.results.map((item, index) => {
            position.push(<option key={index} value={item.id}>{item.site.description + " / " + item.area.description + " / " + item.type.description}</option>);
        })
        setPositions(position)
    }

    useEffect(() => {
        if(currentTab == Constants.tipi){
            setEndPoint(Constants.getTipi);
            setEndPointDelete(Constants.deleteTipi);
            setEndPointCreate(Constants.insertTipi);
            setEndPointUpdate(Constants.updateTipi);
        }
            
        if(currentTab == Constants.aree){
            setEndPoint(Constants.getArea);
            setEndPointDelete(Constants.deleteArea);
            setEndPointCreate(Constants.insertArea);
            setEndPointUpdate(Constants.updateArea);
        }

        if(currentTab == Constants.aggregate){
            serviceApiGetSiti(Constants.getSiti  + '?size=9999&is_visible=True', 'GET')
            getCustomTargeting(Constants.getCustomTargeting + '?size=9999', 'GET');
            setEndPoint(Constants.getAggregate);
            setEndPointDelete(Constants.deleteAggregate);
            setEndPointCreate(Constants.insertAggregate);
            setEndPointUpdate(Constants.updateAggregate);
        }
           
        if(currentTab == Constants.siti){
            serviceApiGetUtentiAttivi(Constants.getUtentiAttivi  + '?size=9999', 'GET');
            setEndPoint(Constants.getSiti);
            setEndPointDelete(Constants.deleteSiti);
            setEndPointCreate(Constants.insertSiti);
            setEndPointUpdate(Constants.updateSiti);
        }

        if(currentTab == Constants.position){
            serviceApiGetArea(Constants.getArea  + '?size=9999', 'GET')
            serviceApiGetSiti(Constants.getSiti  + '?size=9999', 'GET')
            serviceApiGetType(Constants.getTipi  + '?size=9999', 'GET')
            setEndPoint(Constants.getPosition);
            setEndPointDelete(Constants.deletePosition);
            setEndPointCreate(Constants.insertPosition);
            setEndPointUpdate(Constants.updatePosition);
        }

        if(currentTab == Constants.adUnit){
            serviceApiGetPosizioni(Constants.getPosition + '?size=9999', 'GET');
            getCustomTargeting(Constants.getCustomTargeting + '?size=9999', 'GET');
            setEndPoint(Constants.getAdUnit);
            setEndPointDelete(Constants.deleteAdUnit);
            setEndPointCreate(Constants.insertAdUnit);
            setEndPointUpdate(Constants.updateAdUnit); 
        }

        if(currentTab == Constants.customTargeting){
            setEndPoint(Constants.getCustomTargeting);
            setEndPointDelete(Constants.deleteCustomTargeting);
            setEndPointCreate(Constants.insertCustomTargeting);
            setEndPointUpdate(Constants.updateCustomTargeting); 
        }
    },[currentTab]);

    useEffect(() => {
        if(endPoint != undefined)
            serviceApiGet(endPoint, 'GET');
    },[endPoint]);

    useEffect(() => {
        setContent(loadBody());
    },[dataSource]);

    document.onkeydown = function(evt) {
        evt = evt || window.event;
        if (evt.keyCode == 27) {
            setModalVisibility(false); 
            setSelectAdUnitVisible(false);
            setFieldValue(emptyObj); 
            setIsEdit(true);
            setModalIncompatiblePositionShow(false);
        }
    };

    const populateAreaSiteTipe = (obj) =>{
        return obj.results.map((item, index) => {
            return <option key={index} value={item.id}>{item.description}</option>;
        });
    }

    const verifyDominance = (e) =>{
        if(fullPositions != null){
            for(let i = 0; i<fullPositions.results.length; i++){
                if(fullPositions.results[i].id == e.target.value){
                    if(fullPositions.results[i].type.description.includes('Dominance') || fullPositions.results[i].type.description.includes('dominance')){
                        ApiSelectPositionBySite(Constants.getAdUnit + '?position__site=' + fullPositions.results[i].site.id + '&size=9999','GET');
                    }
                    else{
                        setDominancePosIdVisibility(false);
                    }
                        
                    break;
                }
            }
        }
    }

    const savePositionIncompatibili = (e)=>{
        e.preventDefault();
        let tempObj ={
            id:null,
            position:null,
            position_excluded:null
        }
        if(globalState.isMobile){
            const selected = document.querySelectorAll('#select-1 option:checked');
            const values = Array.from(selected).map(el => el.id);
        
            if(excludedPositions[0] !=null)
                tempObj.id = excludedPositions[0].id;

            tempObj.position = currentPosition;
            tempObj.position_excluded = values;
        }
        else{
            if(document.getElementById('positions-incompatible')!=null){
                let obj = [];
                let values = document.getElementById('positions-incompatible').children;
                
                for(let i=0; i< values.length; i++){
                    obj.push(parseInt(values[i].id));
                }

                if(excludedPositions[0] !=null)
                    tempObj.id = excludedPositions[0].id;

                tempObj.position = currentPosition;
                tempObj.position_excluded = obj;
            }
            
        }
        if(tempObj.id != null)
            serviceApiSendData(tempObj, Constants.updatePositionIncompatible.replace('*', tempObj.id), 'PUT');
        else{
            if(tempObj.position_excluded!=null){
                if(tempObj.position_excluded.length>0)
                serviceApiSendData(tempObj, Constants.insertPositionIncompatible, 'POST');
                else{
                    document.getElementById('positions-incompatible').classList.add('setErrorField');
                    return setGlobalState({showAlert:true, messageAlert:Constants.requiredFieldError + Constants.positionSelected, typeAlert:'warning'});
                }
            }
        }
    }

    const saveData = (e) =>{
        e.preventDefault();
        let tempObj = {...fieldValue};

        if(currentTab == Constants.aree){
            tempObj.description = document.getElementById('description').value;
        }

        if(currentTab == Constants.tipi){
            tempObj.description = document.getElementById('description').value;
            tempObj.fixed = document.getElementById('fissa').checked;
        }

        if(currentTab == Constants.aggregate){
            tempObj.description = document.getElementById('description').value;
            tempObj.site = parseInt(document.getElementById('site').value.trim());
            if(globalState.isMobile){
                const selected = document.querySelectorAll('#select-1 option:checked');
                const values = Array.from(selected).map(el => el.id);
                tempObj.adunits = values;

                if(values.length>0)
                    tempObj.adunits = values;
                    else
                        return setGlobalState({showAlert:true, messageAlert:Constants.requiredFieldError + Constants.adUnitSelezionate, typeAlert:'warning'});
            }
            else{
                if(document.getElementById('adunit')!=null){
                    let obj = [];
                    let adunits = document.getElementById('adunit').children;
                    
                    for(let i=0; i< adunits.length; i++){
                        obj.push(parseInt(adunits[i].id));
                    }
                    if(obj.length>0)
                        tempObj.adunits = obj;
                    else{
                        document.getElementById('adunit').classList.add('setErrorField');
                        return setGlobalState({showAlert:true, messageAlert:Constants.requiredFieldError + Constants.adUnitSelezionate, typeAlert:'warning'});
                    }
                }
                
            }

            if(globalState.isMobile){
                const selected2 = document.querySelectorAll('#select-2 option:checked');
                const values = Array.from(selected2).map(el => el.id);
                tempObj.custom_targeting = values;
            }
            else{
                let obj = [];
                let custom_targeting = document.getElementById('custom_targeting').children;
                
                for(let i=0; i< custom_targeting.length; i++){
                    obj.push(parseInt(custom_targeting[i].id));
                }
                if(obj.length>0)
                    tempObj.custom_targeting = obj;
                else
                    tempObj.custom_targeting = [];
            }
        }

        if(currentTab == Constants.siti){
            tempObj.description = document.getElementById('description').value;
            if(globalState.isMobile){
                const selected = document.querySelectorAll('#select-1 option:checked');
                const values = Array.from(selected).map(el => el.id);
                tempObj.referents = values;
            }
            else{
                let obj = [];
                let referent = document.getElementById('referents').children;
                
                for(let i=0; i< referent.length; i++){
                    obj.push(parseInt(referent[i].id));
                }
                if(obj.length>0)
                    tempObj.referents = obj;
                else
                    tempObj.referents = [];
            }
            tempObj.percentage = document.getElementById('percentage').value;
            tempObj.is_netaddiction_site = document.getElementById('netSite').checked;
            tempObj.is_visible = document.getElementById('visible').checked;
        }

        if(currentTab == Constants.position){
            tempObj.area = document.getElementById('area').value;
            tempObj.type = document.getElementById('type').value;
            tempObj.site = parseInt(document.getElementById('site').value.trim());
            tempObj.is_visible = document.getElementById('visibile').checked;
            tempObj.impression_month = document.getElementById('impression_month').value;
        }

        if(currentTab == Constants.adUnit){
            tempObj.position = document.getElementById('position').value;
            
            if(document.getElementById('percentage_distribution').value!="")
                tempObj.percentage_distribution = document.getElementById('percentage_distribution').value;
            else
                tempObj.percentage_distribution=100;

            if(document.getElementById('name').value.trim() == ''){
                tempObj.name=null;
            }
            tempObj.name = document.getElementById('name').value;

            if(globalState.isMobile){
                if(document.getElementById('select-1')!=undefined){
                    const selected = document.querySelectorAll('#select-1 option:checked');
                    const values = Array.from(selected).map(el => el.id);

                    if(values.length>0)
                        tempObj.dominance_adunits = values;
                    else
                        return setGlobalState({showAlert:true, messageAlert:Constants.requiredFieldError + Constants.positionToSelect, typeAlert:'warning'});
                    tempObj.is_dominance = true;
                }
                else{
                    tempObj.is_dominance = false;
                    tempObj.dominance_adunits = [];
                }
            }
            else{
                if(document.getElementById('positions-dominance')!=undefined){
                    let obj = [];
                    let position_dominance = document.getElementById('positions-dominance').children;
                    if(position_dominance.length == 0){
                        document.getElementById('positions-dominance').classList.add('setErrorField');
                        return setGlobalState({showAlert:true, messageAlert:Constants.dominancePositionRequired, typeAlert:'warning'});
                    }
                    for(let i=0; i< position_dominance.length; i++){
                        obj.push(parseInt(position_dominance[i].id));
                    }
                    tempObj.is_dominance = true;
                    tempObj.dominance_adunits = obj;
                }
                else{
                    tempObj.is_dominance = false;
                    tempObj.dominance_adunits = [];
                } 
            }

            if(globalState.isMobile){
                const selected2 = document.querySelectorAll('#select-2 option:checked');
                const values = Array.from(selected2).map(el => el.id);
                tempObj.custom_targeting = values;
            }
            else{
                let obj = [];
                let custom_targeting = document.getElementById('custom_targeting').children;
                
                for(let i=0; i< custom_targeting.length; i++){
                    obj.push(parseInt(custom_targeting[i].id));
                }
                if(obj.length>0)
                    tempObj.custom_targeting = obj;
                else
                    tempObj.custom_targeting = [];
            }
            
            tempObj.width = document.getElementById('width').value.trim();
            tempObj.height = document.getElementById('height').value.trim();
            tempObj.dfp_adunit_id = document.getElementById('dfp_adunit_id').value.trim();
            tempObj.line_item_name = document.getElementById('line_item_name').value;
        }

        if(currentTab == Constants.customTargeting){
            tempObj.name = document.getElementById('name').value;
            tempObj.dfp_key_id = document.getElementById('dfp_key_id').value;
            tempObj.dfp_value_id = document.getElementById('dfp_value_id').value;
        }


        if(tempObj.id != null)
            serviceApiSendData(tempObj, endPointUpdate.replace('*', tempObj.id), 'PUT');
        else
            serviceApiSendData(tempObj, endPointCreate, 'POST');
    }

    const verifyDimension = (e) =>{

        let verify = e.target.value.trim();
        for (var i = 0; i < verify.length; i++) {
            if(verify.charAt(i) !== 'v' && isNaN(verify.charAt(i))){
                e.target.value="";
                return setGlobalState({showAlert:true, messageAlert:Constants.unsupportedDimension, typeAlert:'warning'}); 
            }
        }
    }

    const bindSelectedAdUnit = (fullSelect, tempObj) =>{
        if(globalState.isMobile){
            if(tempObj != undefined){
                let obj  = [...tempObj];
                return obj.map((item, index) => {
                    if(fieldValue.adunits != null){
                        let selected = []
                        fieldValue.adunits.map((item, index)=>{
                            selected.push(item.id)
                        });
                        if(selected.indexOf(item.id) > -1)
                            return <option selected={true} key={index} id={item.id}>{item.name}</option>;
                        else
                            return <option key={index} id={item.id}>{item.name}</option>;
                    }
                    else
                        return <option key={index} id={item.id}>{item.name}</option>;
                })
            }
        }
        else{
            if(tempObj != undefined){
                let obj  = [...tempObj];
                let newObj  = [];
                
                if(fieldValue.adunits!=null){
                    for (let i = 0; i<fieldValue.adunits.length; i++){
                        for(let e = 0; e<obj.length; e++){
                            if(obj[e].id == fieldValue.adunits[i].id){
                                newObj.push(obj[e]);
                                obj.splice(e, 1 );
                                break
                            }   
                        }
                    }
                    if(!fullSelect){
                        return newObj.map((item, index) => {
                            return <option onClick={(e)=>selectAdUnit(e)} key={index} id={item.id}>{item.name}</option>;
                        })
                    }
                    else{
                        return obj.map((item, index) => {
                            return <option onClick={(e)=>selectAdUnit(e)} key={index} id={item.id}>{item.name}</option>;
                        })
                    }
                }
                else{
                    if(fullSelect){
                        return obj.map((item, index) => {
                            return <option onClick={(e)=>selectAdUnit(e)} key={index} id={item.id}>{item.name}</option>;
                        })
                    }
                } 
            }
        }
    }


    const bindSelectedPosition = (fullSelect, tempObj) =>{
        if(globalState.isMobile){
            if(tempObj != undefined){
                let obj  = [...tempObj];
                return obj.map((item, index) => {
                    if(fieldValue.dominance_adunits != null){
                        let selected = []
                        fieldValue.dominance_adunits.map((item, index)=>{
                            selected.push(item.id)
                        });
                        if(selected.indexOf(item.id) > -1)
                            return <option selected={true} key={index} id={item.id}>{item.name + ' (' + item.dfp_adunit_id + ')'}</option>;
                        else
                            return <option key={index} id={item.id}>{item.name + ' (' + item.dfp_adunit_id + ')'}</option>;
                    }
                    else
                        return <option key={index} id={item.id}>{item.name + ' (' + item.dfp_adunit_id + ')'}</option>;
                })
            }
        }
        else{
            if(tempObj != undefined){
                let obj  = [...tempObj];
                let newObj  = [];
                if(fieldValue.dominance_adunits!=null){
                    for (let i = 0; i<fieldValue.dominance_adunits.length; i++){
                        for(let e = 0; e<obj.length; e++){
                            if(obj[e].id == fieldValue.dominance_adunits[i].id){
                                newObj.push(obj[e]);
                                obj.splice(e, 1 );
                                break
                            }   
                        }
                    }
                    if(!fullSelect){
                        return newObj.map((item, index) => {
                            return <option onClick={(e)=>selectPosition(e)} key={index} id={item.id}>{item.name + ' (' + item.dfp_adunit_id + ')'}</option>;
                        })
                    }
                    else{
                        return obj.map((item, index) => {
                            return <option onClick={(e)=>selectPosition(e)} key={index} id={item.id}>{item.name + ' (' + item.dfp_adunit_id + ')'}</option>;
                        })
                    }
                }
                else{
                    if(fullSelect){
                        return obj.map((item, index) => {
                            return <option onClick={(e)=>selectPosition(e)} key={index} id={item.id}>{item.name + ' (' + item.dfp_adunit_id + ')'}</option>;
                        })
                    }
                } 
            }
        }
    }


    const selectPosition = (e) =>{
        if(e.target.parentNode.id == 'select-1'){
            let select2 = document.getElementById('positions-dominance');
            select2.append(e.target);
        }
        else{
            let select1 = document.getElementById('select-1');
            select1.append(e.target);
        }
    }


    const bindSelectedIncompatiblePosition = (fullSelect, tempObj, excPos) =>{
        if(globalState.isMobile){
            if(tempObj != undefined){
                let obj  = [...tempObj];
                return obj.map((item, index) => {
                    if(excPos != null && excPos.length>0){
                        let selected = []
                        excPos[0].position_excluded.map((item, index)=>{
                            selected.push(item.id)
                        });
                        if(selected.indexOf(item.id) > -1)
                            return <option selected={true} key={index} id={item.id}>{item.site.description + " / " + item.area.description + " / " + item.type.description}</option>;
                        else
                            return <option key={index} id={item.id}>{item.site.description + " / " + item.area.description + " / " + item.type.description}</option>;
                    }
                    else
                        return <option key={index} id={item.id}>{item.site.description + " / " + item.area.description + " / " + item.type.description}</option>;
                })
            }
        }
        else{
            if(tempObj != undefined){
                let obj  = [...tempObj];
                let newObj  = [];
                
                if(excPos!=null && excPos.length>0){
                    for (let i = 0; i<excPos[0].position_excluded.length; i++){
                        for(let e = 0; e<obj.length; e++){
                            if(obj[e].id == excPos[0].position_excluded[i].id){
                                newObj.push(obj[e]);
                                obj.splice(e, 1 );
                                break
                            }   
                        }
                    }
                    if(!fullSelect){
                        return newObj.map((item, index) => {
                            return <option onClick={(e)=>selectIncompatiblePosition(e)} key={index} id={item.id}>{item.site.description + " / " + item.area.description + " / " + item.type.description}</option>;
                        })
                    }
                    else{
                        return obj.map((item, index) => {
                            return <option onClick={(e)=>selectIncompatiblePosition(e)} key={index} id={item.id}>{item.site.description + " / " + item.area.description + " / " + item.type.description}</option>;
                        })
                    }
                }
                else{
                    if(fullSelect){
                        return obj.map((item, index) => {
                            return <option onClick={(e)=>selectIncompatiblePosition(e)} key={index} id={item.id}>{item.site.description + " / " + item.area.description + " / " + item.type.description}</option>;
                        })
                    }
                } 
            }
        }
    }

    const selectIncompatiblePosition = (e) =>{
        if(e.target.parentNode.id == 'select-1'){
            let select2 = document.getElementById('positions-incompatible');
            select2.append(e.target);
        }
        else{
            let select1 = document.getElementById('select-1');
            select1.append(e.target);
        }
    }


    const bindSelectedCustomTargeting = (fullSelect, tempObj) =>{
        if(globalState.isMobile){
            if(tempObj != undefined){
                let obj  = [...tempObj];
                return obj.map((item, index) => {
                    if(fieldValue.custom_targeting != null){
                        let selected = []
                        fieldValue.custom_targeting.map((item, index)=>{
                            selected.push(item.id)
                        });
                        if(selected.indexOf(item.id) > -1)
                            return <option selected={true} key={index} id={item.id}>{item.name + ' (' + item.dfp_key_id + ' - ' + item.dfp_value_id + ')'}</option>;
                        else
                            return <option key={index} id={item.id}>{item.name + ' (' + item.dfp_key_id + ' - ' + item.dfp_value_id + ')'}</option>;
                    }
                    else
                        return <option key={index} id={item.id}>{item.name + ' (' + item.dfp_key_id + ' - ' + item.dfp_value_id + ')'}</option>;
                })
            }
        }
        else{
            if(tempObj != undefined){
                let obj  = [...tempObj];
                let newObj  = [];
                if(fieldValue.custom_targeting!=null){
                    for (let i = 0; i<fieldValue.custom_targeting.length; i++){
                        for(let e = 0; e<obj.length; e++){
                            if(obj[e].id == fieldValue.custom_targeting[i].id){
                                newObj.push(obj[e]);
                                obj.splice(e, 1 );
                                break
                            }   
                        }
                    }
                    if(!fullSelect){
                        return newObj.map((item, index) => {
                            return <option onClick={(e)=>selectCustomTargeting(e)} key={index} id={item.id}>{item.name + ' (' + item.dfp_key_id + ' - ' + item.dfp_value_id + ')'}</option>;
                        })
                    }
                    else{
                        return obj.map((item, index) => {
                            return <option onClick={(e)=>selectCustomTargeting(e)} key={index} id={item.id}>{item.name + ' (' + item.dfp_key_id + ' - ' + item.dfp_value_id + ')'}</option>;
                        })
                    }
                }
                else{
                    if(fullSelect){
                        return obj.map((item, index) => {
                            return <option onClick={(e)=>selectCustomTargeting(e)} key={index} id={item.id}>{item.name + ' (' + item.dfp_key_id + ' - ' + item.dfp_value_id + ')'}</option>;
                        })
                    }
                } 
            }
        }
    }


    const selectCustomTargeting = (e) =>{
        if(e.target.parentNode.id == 'select-2'){
            let select2 = document.getElementById('custom_targeting');
            select2.append(e.target);
        }
        else{
            let select1 = document.getElementById('select-2');
            select1.append(e.target);
        }
    }

    const loadBody = () =>{
        if(dataSource != undefined){
            let colspan = 0;

            if(currentTab == Constants.tipi){
                colspan = 3
            }

            else if(currentTab == Constants.aggregate){
                colspan = 3
            }
                
            else if(currentTab == Constants.aree){
                colspan = 2
            }
                
            else if(currentTab == Constants.siti){
                colspan = 4
            }
                
            else if(currentTab == Constants.position){
                colspan = 6
            }
            else if(currentTab == Constants.adUnit){
                colspan = 7
            }
            else if(currentTab == Constants.customTargeting){
                colspan = 4
            }

            let detailButton = false;
            let updateButton = false;
            let deleteButton = false;

            if(currentTab == Constants.adUnit){
                detailButton = globalState.permissions.siteMenu.positions.ADunits.show != undefined;
                updateButton = globalState.permissions.siteMenu.positions.ADunits.update != undefined;
                deleteButton = globalState.permissions.siteMenu.positions.ADunits.delete != undefined;
            }

            if(currentTab == Constants.position){
                detailButton = globalState.permissions.siteMenu.positions.position.show != undefined;
                updateButton = globalState.permissions.siteMenu.positions.position.update != undefined;
                deleteButton = globalState.permissions.siteMenu.positions.position.delete != undefined;
            }

            if(currentTab == Constants.siti){
                detailButton = globalState.permissions.siteMenu.positions.site.show != undefined;
                updateButton = globalState.permissions.siteMenu.positions.site.update != undefined;
                deleteButton = globalState.permissions.siteMenu.positions.site.delete != undefined;
            }

            if(currentTab == Constants.aree){
                detailButton = globalState.permissions.siteMenu.positions.area.show != undefined;
                updateButton = globalState.permissions.siteMenu.positions.area.update != undefined;
                deleteButton = globalState.permissions.siteMenu.positions.area.delete != undefined;
            }

            if(currentTab == Constants.tipi){
                detailButton = globalState.permissions.siteMenu.positions.type.show != undefined;
                updateButton = globalState.permissions.siteMenu.positions.type.update != undefined;
                deleteButton = globalState.permissions.siteMenu.positions.type.delete != undefined;
            }

            if(currentTab == Constants.aggregate){
                detailButton = globalState.permissions.siteMenu.positions.aggregate.show != undefined;
                updateButton = globalState.permissions.siteMenu.positions.aggregate.update != undefined;
                deleteButton = globalState.permissions.siteMenu.positions.aggregate.delete != undefined;
            }

            if(currentTab == Constants.customTargeting){
                detailButton = globalState.permissions.siteMenu.positions.customtargeting.show != undefined;
                updateButton = globalState.permissions.siteMenu.positions.customtargeting.update != undefined;
                deleteButton = globalState.permissions.siteMenu.positions.customtargeting.delete != undefined;
            }
            
            return(
                <tbody>
                    {
                        (dataSource.results.length > 0)
                        ?
                            dataSource.results.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        {currentTab !== Constants.position && currentTab !== Constants.adUnit && currentTab !== Constants.customTargeting?
                                            <td>{item.description}</td>
                                        :null}

                                        {currentTab == Constants.tipi
                                        ?
                                            <td>{item.fixed?Constants.fissa:Constants.nonFissa}</td>
                                        :null}

                                        {currentTab == Constants.siti
                                        ?
                                            <>
                                                <td>{item.percentage}%</td>
                                                <td>{item.is_visible?Constants.si:Constants.no}</td>
                                            </>
                                        :null}

                                        {currentTab == Constants.position
                                        ?
                                            <>
                                                <td>{item.site.description}</td>
                                                <td>{item.area.description}</td>
                                                <td>{item.type.description}</td>
                                                <td>{item.type.fixed?Constants.fissa:item.impression_month}</td>
                                                <td>{item.is_visible?Constants.si:Constants.no}</td>
                                            </>
                                        :null}

                                        {currentTab == Constants.adUnit
                                        ?
                                            <>
                                                <td>{item.position.site.description + " / " + item.position.area.description + " / " + item.position.type.description}</td>
                                                <td>{item.is_dominance?Constants.si:Constants.no}</td>
                                                <td>{item.width}</td>
                                                <td>{item.height}</td>  
                                                <td>{item.name}</td>  
                                                <td>{item.percentage_distribution!= null?item.percentage_distribution + '%':''}</td>  
                                            </>
                                        :null}

                                        {currentTab == Constants.aggregate
                                        ?
                                            <>
                                                <td>{item.site.description}</td>   
                                            </>
                                        :null}

                                        {currentTab == Constants.customTargeting
                                        ?
                                            <>
                                                <td>{item.name}</td>   
                                                <td>{item.dfp_key_id}</td>
                                                <td>{item.dfp_value_id}</td>
                                            </>
                                        :null}
                                        
                                        <TableAction 
                                            setCurrentPosition = {()=>setCurrentPosition(item.id)}
                                            loadpositionBySite = {()=>ApiSelectPositionIncompatibleByPosition(Constants.getPositionIncompatible + '?position=' + item.id + '&size=9999','GET', item.site.id)}
                                            modalIncompatiblePositionShow = {()=>setModalIncompatiblePositionShow(true)}
                                            currentTab = {currentTab}
                                            detailVisible = {detailButton}
                                            updateVisible = {updateButton}
                                            deleteVisible = {deleteButton}
                                            setModalIsCreate={()=>setIsCreate(false)}
                                            setModalTitleShow={()=>setModalTitle(Constants.modalTitleShow)}
                                            setModalTitleEdit={()=>setModalTitle(Constants.modalTitleEdit)}
                                            setModalVisibility={()=>setModalVisibility(true)}  
                                            setFieldValue={()=>setFieldValue(item)} 
                                            setDeleteId={()=>setDeleteId(item.id)} 
                                            setIsEdit={()=>setIsEdit(true)} 
                                            disableEdit={()=>setIsEdit(false)} 
                                            setModalDeleteVisibility={()=>setModalDeleteVisibility(true)}/>
                                    </tr>
                                );
                            })
                        :
                        <tr>
                            <td colSpan={colspan} style={{textAlign:'center', color:'#ccc'}}><FontAwesomeIcon style={{marginRight:40, fontSize:30}} icon="box-open"/> {Constants.noDataFound}</td>
                        </tr>
                    }
                </tbody>
            );    
        }
    }

    const selectAdUnit= (e) =>{
        if(e.target.parentNode.id == 'select-1'){
            let select2 = document.getElementById('adunit');
            select2.append(e.target);
        }
        else{
            let select1 = document.getElementById('select-1');
            select1.append(e.target);
        }
    }

    const selectReferent = (e) =>{
        if(e.target.parentNode.id == 'select-1'){
            let select2 = document.getElementById('referents');
            select2.append(e.target);
        }
        else{
            let select1 = document.getElementById('select-1');
            select1.append(e.target);
        }
    }

    const bindSelectedReferent = (fullSelect) =>{
        if(globalState.isMobile){
            if(activeUsers != undefined){
                if(activeUsers.results != undefined){
                    let obj  = [...activeUsers.results];
                    return obj.map((item, index) => {
                        if(fieldValue.referents != null){
                            if(fieldValue.referents.indexOf(item.id) > -1)
                                return <option selected={true} key={index} id={item.id}>{item.first_name + " " + item.last_name}</option>;
                            else
                                return <option key={index} id={item.id}>{item.first_name + " " + item.last_name}</option>;
                        }
                        else
                            return <option key={index} id={item.id}>{item.first_name + " " + item.last_name}</option>;
                    })
                }
            }
        }
        else{
            if(activeUsers != undefined){
                if(activeUsers.results != undefined){
                    let obj  = [...activeUsers.results];
                    let newObj  = [];
    
                    if(fieldValue.referents!=null){
                        for (let i = 0; i<fieldValue.referents.length; i++){
                            for(let e = 0; e<obj.length; e++){
                                if(obj[e].id == fieldValue.referents[i]){
                                    newObj.push(obj[e]);
                                    obj.splice(e, 1 );
                                    break
                                }   
                            }
                        }
                        if(!fullSelect){
                            return newObj.map((item, index) => {
                                return <option onClick={(e)=>selectReferent(e)} key={index} id={item.id}>{item.first_name + " " + item.last_name}</option>;
                            })
                        }
                        else{
                            return obj.map((item, index) => {
                                return <option onClick={(e)=>selectReferent(e)} key={index} id={item.id}>{item.first_name + " " + item.last_name}</option>;
                            })
                        }
                    }
                    else{
                        if(fullSelect){
                            return obj.map((item, index) => {
                                return <option onClick={(e)=>selectReferent(e)} key={index} id={item.id}>{item.first_name + " " + item.last_name}</option>;
                            })
                        }
                    } 
                }
            }
        }
    }


    const loadAdUnit = (e) =>{
        serviceApiGetAdUnitFromSite(Constants.getAdUnit + '?position__site=' + e.target.value + '&size=9999','GET');
        if(e.target.value=='')
            setSelectAdUnitVisible(false)
        else
            setSelectAdUnitVisible(true)
    }

    const loadBodyModal = () =>{
        return(
            <Form.Group>
                {currentTab != Constants.position && currentTab != Constants.adUnit && currentTab != Constants.customTargeting?
                    <>
                        <Form.Label>{Constants.description}</Form.Label>
                        <Form.Control id="description" type="text" placeholder={Constants.descrizionePlaceholder} defaultValue={fieldValue.description} disabled={!isEdit} required/>
                        <br/>
                    </>
                :null}

                {currentTab == Constants.tipi?
                    <Form.Check custom id="fissa" type="checkbox" defaultChecked={fieldValue.fixed} label={Constants.fissaQuestion} disabled={!isEdit} />
                :null}

                {currentTab == Constants.siti?
                    <>
                        {globalState.isMobile?
                            <>
                                <Form.Label>{Constants.referentsToSelect}</Form.Label>
                                <Form.Control id="select-1" as="select" disabled={!isEdit} multiple={true}>
                                    {bindSelectedReferent(true)}
                                </Form.Control>
                            </>
                            :
                            <Row>
                                <Col>
                                    <Form.Label>{Constants.referentsToSelect}</Form.Label>
                                    <Form.Control id="select-1" as="select" disabled={!isEdit} multiple={true}>
                                        {bindSelectedReferent(true)}
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Label>{Constants.referents}</Form.Label>
                                    <Form.Control id="referents" as="select" disabled={!isEdit} multiple={true} required>
                                        {bindSelectedReferent(false)}
                                    </Form.Control>
                                </Col>
                            </Row>
                        }
                        <br/>
                        <Form.Label>{Constants.percentage}</Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl id="percentage" type="number" min="0" max="100" placeholder={Constants.quotaRipartizionePlaceholder} defaultValue={fieldValue.percentage} disabled={!isEdit} onInput={(e)=>(e.target.value>100 || e.target.value<0)?e.target.value = "":""} required/>
                            <InputGroup.Append>
                            <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <br/>
                        <Form.Check custom id="netSite" type="checkbox" defaultChecked={fieldValue.is_netaddiction_site} label={Constants.netSiteQuestion} disabled={!isEdit} />
                        <br/>
                        <Form.Check custom id="visible" type="checkbox" defaultChecked={fieldValue.is_visible} label={Constants.isActiveQuestion} disabled={!isEdit} />
                    </>
                :null}

                {currentTab == Constants.aggregate?
                    <>
                        <Row>
                            <Col>
                                <Form.Label>{Constants.site}</Form.Label>
                                <Form.Control id="site" defaultValue={fieldValue.site!=null?fieldValue.site.id:''} onChange={(e)=>loadAdUnit(e)} as="select" disabled={!isEdit}>
                                    <option value="">{Constants.seleziona}</option>
                                    {site}
                                </Form.Control>
                            </Col>
                        </Row>
                        <br/>
                        {selectAdUnitVisible?
                            <>
                                {globalState.isMobile?
                                    <>
                                        <Form.Label>{Constants.adUnitDaSelezionare}</Form.Label>
                                        <Form.Control id="select-1" as="select" disabled={!isEdit} multiple={true}>
                                            {unselectedAdUnits}
                                        </Form.Control>
                                    </>
                                    :
                                    <Row>
                                        <Col>
                                            <Form.Label>{Constants.adUnitDaSelezionare}</Form.Label>
                                            <Form.Control id="select-1" as="select" disabled={!isEdit} multiple={true}>
                                                {unselectedAdUnits}
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Label>{Constants.adUnitSelezionate}</Form.Label>
                                            <Form.Control id="adunit" as="select" disabled={!isEdit} multiple={true} required>
                                                {selectedAdUnits}
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                }
                                <br/>
                                {globalState.isMobile?
                                    <>
                                        <Form.Label>{Constants.chiaveValoreToSelect}</Form.Label>
                                        <Form.Control id="select-2" as="select" disabled={!isEdit} multiple={true}>
                                            {unselectedCustomTargeting}
                                        </Form.Control>
                                    </>
                                    :
                                    <Row>
                                        <Col>
                                            <Form.Label>{Constants.chiaveValoreToSelect}</Form.Label>
                                            <Form.Control id="select-2" as="select" disabled={!isEdit} multiple={true}>
                                                {unselectedCustomTargeting}
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Label>{Constants.chiaveValoreSelected}</Form.Label>
                                            <Form.Control id="custom_targeting" as="select" disabled={!isEdit} multiple={true} required>
                                                {selectedCustomTargeting}
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                }
                            </>
                            :
                            null
                        }
                    </>
                :null}

                {currentTab == Constants.position?
                    <>
                        <Form.Label>{Constants.site}</Form.Label>
                        <Form.Control id="site" defaultValue={fieldValue.site!=null?fieldValue.site.id:''} as="select" disabled={!isEdit}>
                            <option value="">{Constants.seleziona}</option>
                            {site}
                        </Form.Control>
                        <br/>
                        <Form.Label>{Constants.area}</Form.Label>
                        <Form.Control id="area" defaultValue={fieldValue.area!=null?fieldValue.area.id:''} as="select" disabled={!isEdit}>
                            <option value="">{Constants.seleziona}</option>
                            {area}
                        </Form.Control>
                        <br/>
                        <Form.Label>{Constants.type}</Form.Label>
                        <Form.Control id="type" defaultValue={fieldValue.type!=null?fieldValue.type.id:''} as="select" disabled={!isEdit}>
                            <option value="">{Constants.seleziona}</option>
                            {type}
                        </Form.Control>
                        <br/>
                        <Form.Label>{Constants.impression_month}</Form.Label>
                        <Form.Control id="impression_month" type="number" placeholder={Constants.impression_monthPlaceholder} defaultValue={fieldValue.impression_month} disabled={!isEdit} required/>
                        <br/>
                        <Form.Check custom id="visibile" type="checkbox" defaultChecked={fieldValue.is_visible} label={Constants.visibileQuestion} disabled={!isEdit} />
                    </>
                :null}

                {currentTab == Constants.adUnit?
                    <>
                        {globalState.isMobile?
                            <>
                                <Form.Label>{Constants.name}</Form.Label>
                                <Form.Control id="name" type="text" placeholder={Constants.adUnitNamePlaceholder} defaultValue={fieldValue.name} disabled={!isEdit}/>
                                <br/>
                                <Form.Label>{Constants.dfp_adunit_id}</Form.Label>
                                <Form.Control id="dfp_adunit_id" type="text" placeholder={Constants.dfp_adunit_idPlaceholder} defaultValue={fieldValue.dfp_adunit_id} disabled={!isEdit}/>
                                <br/>
                                <Form.Label>{Constants.line_item_name}</Form.Label>
                                <Form.Control id="line_item_name" type="text" placeholder={Constants.line_item_namePlaceholder} defaultValue={fieldValue.line_item_name} disabled={!isEdit}/>
                                <br/>
                                <Form.Label>{Constants.percentage_distribution}</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl id="percentage_distribution" type="number" min="0" max="100" placeholder={Constants.percentage_distributionPlaceholder} defaultValue={fieldValue.percentage_distribution!=null?fieldValue.percentage_distribution:100} disabled={!isEdit} onInput={(e)=>(e.target.value>100 || e.target.value<0)?e.target.value = "":""} required/>
                                    <InputGroup.Append>
                                    <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </>
                            :
                            <>
                                <Row>
                                    <Col>
                                        <Form.Label>{Constants.name}</Form.Label>
                                        <Form.Control id="name" type="text" placeholder={Constants.adUnitNamePlaceholder} defaultValue={fieldValue.name} disabled={!isEdit}/>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col>
                                        <Form.Label>{Constants.dfp_adunit_id}</Form.Label>
                                        <Form.Control id="dfp_adunit_id" type="text" placeholder={Constants.dfp_adunit_idPlaceholder} defaultValue={fieldValue.dfp_adunit_id} disabled={!isEdit}/>
                                    </Col>
                                    <Col>
                                        <Form.Label>{Constants.line_item_name}</Form.Label>
                                        <Form.Control id="line_item_name" type="text" placeholder={Constants.line_item_namePlaceholder} defaultValue={fieldValue.line_item_name} disabled={!isEdit}/>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col>
                                        <Form.Label>{Constants.percentage_distribution}</Form.Label>
                                        <InputGroup className="mb-3">
                                            <FormControl id="percentage_distribution" type="number" min="0" max="100" placeholder={Constants.percentage_distributionPlaceholder} defaultValue={fieldValue.percentage_distribution!=null?fieldValue.percentage_distribution:100} disabled={!isEdit} onInput={(e)=>(e.target.value>100 || e.target.value<0)?e.target.value = "":""} required/>
                                            <InputGroup.Append>
                                            <InputGroup.Text>%</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </>
                        }
                        <br/>
                        <Form.Label>{Constants.position}</Form.Label>
                        <Form.Control onChange={(e)=>verifyDominance(e)} id="position" as="select" disabled={!isEdit} defaultValue={fieldValue.position !=null?fieldValue.position.id:''}>
                            <option value="">{Constants.seleziona}</option>
                            {positions}
                        </Form.Control>
                        <br/>
                        {dominancePosIdVisibility?
                            <>
                                {globalState.isMobile?
                                    <>
                                        <Form.Label>{Constants.adUnitDaSelezionare}</Form.Label>
                                        <Form.Control id="select-1" as="select" disabled={!isEdit} multiple={true}>
                                            {unselectedDominancePosition}
                                        </Form.Control>
                                    </>
                                    :
                                    <Row>
                                        <Col>
                                            <Form.Label>{Constants.adUnitDaSelezionare}</Form.Label>
                                            <Form.Control id="select-1" as="select" disabled={!isEdit} multiple={true}>
                                                {unselectedDominancePosition}
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Label>{Constants.adUnitSelezionate}</Form.Label>
                                            <Form.Control id="positions-dominance" as="select" disabled={!isEdit} multiple={true} required>
                                                {selectedDominancePosition}
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                }
                                <br/>
                            </>
                            :null
                        }
                        <Row>
                            <Col>
                                <Form.Label>{Constants.width}</Form.Label>
                                <Form.Control id="width" type="text" onChange={(e)=>verifyDimension(e)} placeholder={Constants.widthPlaceholder} defaultValue={fieldValue.width} disabled={!isEdit}/>
                            </Col>
                            <Col>
                                <Form.Label>{Constants.height}</Form.Label>
                                <Form.Control id="height" type="text" onChange={(e)=>verifyDimension(e)} placeholder={Constants.heightPlaceholder} defaultValue={fieldValue.height} disabled={!isEdit}/>
                            </Col>
                        </Row>
                        <br/>
                        {customTargetingVisibility?
                            <>
                                {globalState.isMobile?
                                    <>
                                        <Form.Label>{Constants.chiaveValoreToSelect}</Form.Label>
                                        <Form.Control id="select-2" as="select" disabled={!isEdit} multiple={true}>
                                            {unselectedCustomTargeting}
                                        </Form.Control>
                                    </>
                                    :
                                    <Row>
                                        <Col>
                                            <Form.Label>{Constants.chiaveValoreToSelect}</Form.Label>
                                            <Form.Control id="select-2" as="select" disabled={!isEdit} multiple={true}>
                                                {unselectedCustomTargeting}
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Label>{Constants.chiaveValoreSelected}</Form.Label>
                                            <Form.Control id="custom_targeting" as="select" disabled={!isEdit} multiple={true} required>
                                                {selectedCustomTargeting}
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                }
                            </>
                            :null
                        }
                    </>
                :null}   
                {currentTab == Constants.customTargeting?
                    <>
                        <Col>
                            <Form.Label>{Constants.name}</Form.Label>
                            <Form.Control id="name" type="text" placeholder={Constants.placeholderCTname} defaultValue={fieldValue.name} disabled={!isEdit}/>
                        </Col>
                        <Col>
                            <Form.Label>{Constants.dfp_key_id}</Form.Label>
                            <Form.Control id="dfp_key_id" type="number" placeholder={Constants.placeholderDFPkey} defaultValue={fieldValue.dfp_key_id} disabled={!isEdit}/>
                        </Col>
                        <Col>
                            <Form.Label>{Constants.dfp_value_id}</Form.Label>
                            <Form.Control id="dfp_value_id" type="number" placeholder={Constants.placeholderDFPvaluje} defaultValue={fieldValue.dfp_value_id} disabled={!isEdit}/>
                        </Col>
                    </>
                :null}       
            </Form.Group>
        );
    }

    const loadTable = () =>{
        return(
            <div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            {currentTab != Constants.position && currentTab != Constants.adUnit && currentTab != Constants.customTargeting?
                                <th>{Constants.description}</th>
                            :null}

                            {currentTab == Constants.tipi?
                                <th>{Constants.posizione}</th>
                            :null}

                            {currentTab == Constants.siti?
                                <>
                                    <th>{Constants.percentage}</th>
                                    <th>{Constants.attivo}</th>
                                </>
                            :null}

                            {currentTab == Constants.position?
                                <>
                                    <th>{Constants.site}</th>
                                    <th>{Constants.area}</th>
                                    <th>{Constants.type}</th>
                                    <th>{Constants.impression_month}</th>
                                    <th>{Constants.visibile}</th>
                                </>
                            :null}

                            {currentTab == Constants.adUnit?
                                <>
                                    <th>{Constants.position}</th>
                                    <th>{Constants.dominanceQuestion}</th>
                                    <th>{Constants.width}</th>
                                    <th>{Constants.height}</th>
                                    <th>{Constants.name}</th>
                                    <th>{Constants.percentage_distribution}</th>
                                </>
                            :null}

                            {currentTab == Constants.aggregate?
                                <th>{Constants.site}</th>
                            :null}

                            {currentTab == Constants.customTargeting?
                                <>
                                    <th>{Constants.name}</th>
                                    <th>{Constants.dfp_key_id}</th>
                                    <th>{Constants.dfp_value_id}</th>
                                </>
                            :null}

                            <th>{Constants.azioni}</th>
                        </tr>
                    </thead>
                    {content}
                </Table>
                <TablePagination 
                    dataSource={dataSource} 
                    serviceApiGetPrev={()=>serviceApiGet(endPoint + dataSource.previous, 'GET')} 
                    serviceApiGetNext={()=>serviceApiGet(endPoint + dataSource.next, 'GET')} />
            </div>
        );
    }

    const loadButtonAdd = () =>{
        let permitted = false;
        
        if(currentTab == Constants.adUnit)
            permitted=globalState.permissions.siteMenu.positions.ADunits.create!= undefined;
        if(currentTab == Constants.position)
            permitted=globalState.permissions.siteMenu.positions.position.create!= undefined;
        if(currentTab == Constants.siti)
            permitted=globalState.permissions.siteMenu.positions.site.create!= undefined;
        if(currentTab == Constants.aree)
            permitted=globalState.permissions.siteMenu.positions.area.create!= undefined;
        if(currentTab == Constants.tipi)
            permitted=globalState.permissions.siteMenu.positions.type.create!= undefined;
        if(currentTab == Constants.aggregate)
            permitted=globalState.permissions.siteMenu.positions.aggregate.create!= undefined;
        if(currentTab == Constants.customTargeting)
            permitted=globalState.permissions.siteMenu.positions.customtargeting.create!= undefined;

        if(permitted){
            return (
                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.crea + " " + currentTab}</Tooltip>}>
                    <Button variant="default" onClick={()=>{setIsCreate(true); setModalTitle(Constants.modalTitleNew); setModalVisibility(true); setFieldValue(emptyObj); setIsEdit(true);}}>
                        <FontAwesomeIcon style={{marginRight:10}} icon="plus-circle"/>
                        {Constants.crea + " " + currentTab}
                    </Button>
                </OverlayTrigger>
            )
        }
    }

    const loadButtonTab= (tabName) =>{
        let permitted = false;
        
        if(tabName == Constants.adUnit)
            permitted=globalState.permissions.siteMenu.positions.ADunits.show != undefined;
        if(tabName == Constants.position)
            permitted=globalState.permissions.siteMenu.positions.position.show != undefined;
        if(tabName == Constants.siti)
            permitted=globalState.permissions.siteMenu.positions.site.show != undefined;
        if(tabName == Constants.aree)
            permitted=globalState.permissions.siteMenu.positions.area.show != undefined;
        if(tabName == Constants.tipi)
            permitted=globalState.permissions.siteMenu.positions.type.show != undefined;
        if(tabName == Constants.aggregate)
            permitted=globalState.permissions.siteMenu.positions.aggregate.show != undefined;
        if(tabName == Constants.customTargeting)
            permitted=globalState.permissions.siteMenu.positions.customtargeting.show != undefined;

        if(permitted){
            return (
                <Tab eventKey={tabName} title={tabName}>
                    {loadTable()}
                </Tab>
            )
        }
    }
    
    return (
        <div className="container col-sm-12 table-responsive fade-in-left" style={{padding:'40px'}}>
            {loadButtonAdd()}
            <br/><br/>
                <Filtri endPoint={endPoint} setDatasource={setDatasource} setCurrentEndPoint={setCurrentEndPoint} dataSource={dataSource} />
            <br/>
            <Tabs activeKey={key} onSelect={k => {setKey(k); setCurrentTab(k);}}>
                {loadButtonTab(Constants.aggregate)}
                {loadButtonTab(Constants.adUnit)}
                {loadButtonTab(Constants.position)}
                {loadButtonTab(Constants.siti)}
                {loadButtonTab(Constants.aree)}
                {loadButtonTab(Constants.tipi)}
                {loadButtonTab(Constants.customTargeting)}
            </Tabs>

            <Modal onHide={(e)=>{return}} show={modalVisibility} size="lg"  centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {modalTitle}
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.chiudi}</Tooltip>}>
                            <Button style={{right:17, top:17, position:'absolute'}} variant="danger" onClick={()=>{setModalVisibility(false); setSelectAdUnitVisible(false); setFieldValue(emptyObj); setIsEdit(true);}}>
                                <FontAwesomeIcon style={{fontSize:30}} icon="times"/>
                            </Button>
                        </OverlayTrigger>
                    </Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        {loadBodyModal()}
                    </Modal.Body>
                    <Modal.Footer>
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.annulla}</Tooltip>}>
                            <Button variant="secondary" onClick={()=>{setSelectAdUnitVisible(false); setModalVisibility(false); setFieldValue(emptyObj); setIsEdit(true);}}>{Constants.annulla}</Button>
                        </OverlayTrigger>
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.salva}</Tooltip>}>
                            <Button type="submit" variant="primary" onClick={(e)=>{saveData(e); e.preventDefault();}} style={{display:!isEdit?'none':'block'}} disabled={!isEdit}>{Constants.salva}</Button>
                        </OverlayTrigger>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal onHide={(e)=>{return}} show={modalIncompatiblePositionShow} size="lg"  centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {Constants.posizioniIncompatibili}
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.chiudi}</Tooltip>}>
                            <Button style={{right:17, top:17, position:'absolute'}} variant="danger" onClick={()=>setModalIncompatiblePositionShow(false)}>
                                <FontAwesomeIcon style={{fontSize:30}} icon="times"/>
                            </Button>
                        </OverlayTrigger>
                    </Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                    {globalState.isMobile?
                        <>
                            <Form.Label>{Constants.positionToSelect}</Form.Label>
                            <Form.Control id="select-1" as="select" multiple={true}>
                                {unselectedIncompatiblePosition}
                            </Form.Control>
                        </>
                        :
                        <Row>
                            <Col>
                                <Form.Label>{Constants.positionToSelect}</Form.Label>
                                <Form.Control style={{height:300}} id="select-1" as="select" multiple={true}>
                                    {unselectedIncompatiblePosition}
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>{Constants.positionSelected}</Form.Label>
                                <Form.Control style={{height:300}} id="positions-incompatible" as="select" multiple={true} required>
                                    {selectedIncompatiblePosition}
                                </Form.Control>
                            </Col>
                        </Row>
                    }  
                    </Modal.Body>
                    <Modal.Footer>
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.annulla}</Tooltip>}>
                            <Button variant="secondary" onClick={()=>setModalIncompatiblePositionShow(false)}>{Constants.annulla}</Button>
                        </OverlayTrigger>
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.salva}</Tooltip>}>
                            <Button type="submit" variant="primary" onClick={(e)=>savePositionIncompatibili(e)}>{Constants.salva}</Button>
                        </OverlayTrigger>
                    </Modal.Footer>
                </Form>
            </Modal>

            <ModalDelete reloadTable={()=>serviceApiGet(currentEndPoint, 'GET')} closeModal={()=>setModalDeleteVisibility(false)} visible={modalDeleteVisibility} rowid={deleteId} endpoint={endPointDelete}/>
        </div>
    );


    /////////////API//////////////////////////////////

    function serviceApiSendData(obj, url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{
                'Content-Type':'application/json', 
                'Authorization':'Bearer ' + globalState.accessToken,
                'Accept-Language': 'it'
            },
            data:obj
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.successMessage, typeAlert:'success'});
            serviceApiGet(currentEndPoint, 'GET');
            setModalIncompatiblePositionShow(false);
            setModalVisibility(false);
            setSelectAdUnitVisible(false);
        })
        .catch(function (error) {
            if(error.response.status == 400){
                let Message = Constants.requiredFieldError;
                var keys = [];
                for (var k in error.response.data){
                    if(document.getElementById(k) != null){
                        keys.push(k);
                        Message = Message + Constants[k] + ", ";
                        document.getElementById(k).classList.add('setErrorField');
                    }
                }
                setGlobalState({showSpinner:false, showAlert:true, messageAlert:Message.substring(0, Message.length - 2), typeAlert:'warning'});
            }
            else
                setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});

            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGet(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setDatasource(response.data);
            setCurrentEndPoint(url);
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetUtentiAttivi(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setActiveUsers(response.data);
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }


    function serviceApiGetArea(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setFullArea(response.data)
            setArea(populateAreaSiteTipe(response.data))
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetSiti(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setFullSite(response.data)
            setSite(populateAreaSiteTipe(response.data))
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetType(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setFullType(response.data)
            setType(populateAreaSiteTipe(response.data))
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetAdUnitFromSite(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setUnselectedAdUnits(bindSelectedAdUnit(true, response.data.results));
            setSelectedAdUnits(bindSelectedAdUnit(false, response.data.results));
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetPosizioni(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setFullPositions(response.data)
            populatePositions(response.data)
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function ApiSelectPositionBySite(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setUnselectedDominancePosition(bindSelectedPosition(true, response.data.results));
            setSelectedDominancePosition(bindSelectedPosition(false, response.data.results));
            setDominancePosIdVisibility(true);
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }


    function getCustomTargeting(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setUnselectedCustomTargeting(bindSelectedCustomTargeting(true, response.data.results));
            setSelectedCustomTargeting(bindSelectedCustomTargeting(false, response.data.results));
            setCustomTargetingVisibility(true);
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function ApiSelectPositionIncompatibleByPosition(url, method, siteId){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            if(response.data.results != null){
                setExcludedPositions(response.data.results)
                ApiSelectPositionIncompatibleBySite(Constants.getPosition + '?site=' + siteId + '&size=9999','GET', response.data.results)
            }
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function ApiSelectPositionIncompatibleBySite(url, method, excPos){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setModalIncompatiblePositionShow(true);
            setUnselectedIncompatiblePosition(bindSelectedIncompatiblePosition(true, response.data.results, excPos));
            setSelectedIncompatiblePosition(bindSelectedIncompatiblePosition(false, response.data.results, excPos));
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }
}


    

