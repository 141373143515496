import React, {useState, useEffect} from 'react';
import {Table, Button, Modal, Form, Row, Col, InputGroup, FormControl, Tabs, Tab, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useGlobalState, setGlobalState} from '../GlobalState';
import * as Constants from '../constants/constants';
import axios from 'axios';
import {deleteCookie} from '../cookie';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalDelete from '../components/modalDelete';
import TableAction from '../components/tableAction';
import TablePagination from '../components/tablePagination';
import Filtri from '../components/filter';

export default function Clienti() {
    onkeydown = (evt) =>{
        evt = evt || window.event;
        if (evt.keyCode == 27) {
            setModalVisibility(false); 
            setFieldValue(emptyObj); 
            setIsEdit(true);
        }
    };
    const [dataSource, setDatasource] = useState();
    const [key, setKey] = useState();
    const [content, setContent] = useState();
    const [modalTitle, setModalTitle] = useState();
    const [modalVisibility, setModalVisibility] = useState(false);
    const [modalDeleteVisibility, setModalDeleteVisibility] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [currentTab, setCurrentTab] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [sectors, setSectors] = useState([]);
    const [endPoint, setEndPoint] = useState()
    const [endPointDelete, setEndPointDelete] = useState()
    const [endPointCreate, setEndPointCreate] = useState()
    const [endPointUpdate, setEndPointUpdate] = useState()
    const [payment, setPayment] = useState()
    const [isCreate, setIsCreate] = useState(false);
    const [currentEndPoint, setCurrentEndPoint] = useState();

    let emptyObj = {
        description:"",
        sectors:null,
        dfp_id:null,
        percentage_commission:null,
        payment_type:null,
        business_name:'',
        vat_number: '',
        address: '',
        city: '',
        recipient_code: '',
        pec: '',
        administrative_email: '',
        administrative_contact: '',
        telephone_number: '',
    }
    const [fieldValue, setFieldValue] = useState(emptyObj);

    let globalState = useGlobalState();

    useEffect(() => {
        setGlobalState({currentPage:2});
        let firstTabSelected = false;

        if(globalState.permissions.siteMenu.customers.agency.show != null){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.agenzie);
                setEndPoint(Constants.getAgenzie);
                setCurrentTab(Constants.agenzie);
            } 
        }

        if(globalState.permissions.siteMenu.customers.customer.show != null){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.clientiDiretti);
                setEndPoint(Constants.getClientiDiretti);
                setCurrentTab(Constants.clientiDiretti);
            } 
        }

        if(globalState.permissions.siteMenu.customers.sector.show != null){
            if(!firstTabSelected){
                firstTabSelected = true;
                setKey(Constants.settori);
                setEndPoint(Constants.getSettori);
                setCurrentTab(Constants.settori);
            } 
        }
    },[]);

    useEffect(() => {
        if(currentTab == Constants.settori){
            setEndPoint(Constants.getSettori);
            setEndPointDelete(Constants.deleteSector);
            setEndPointCreate(Constants.insertSettori);
            setEndPointUpdate(Constants.updateSettori);
        }
            
        if(currentTab == Constants.clientiDiretti){
            serviceApiGetPayment(Constants.getPagamenti, 'GET');
            serviceApiGetSectors(Constants.getSettori + '?size=9999', 'GET');
            setEndPoint(Constants.getClientiDiretti);
            setEndPointDelete(Constants.deleteClientiDiretti);
            setEndPointCreate(Constants.insertClientiDiretti);
            setEndPointUpdate(Constants.updateClientiDiretti);
        }
           
        if(currentTab == Constants.agenzie){
            serviceApiGetPayment(Constants.getPagamenti, 'GET');
            setEndPoint(Constants.getAgenzie);
            setEndPointDelete(Constants.deleteAgenzie);
            setEndPointCreate(Constants.insertAgenzie);
            setEndPointUpdate(Constants.updateAgenzie);
        }
    },[currentTab]);

    useEffect(() => {
        if(endPoint != null)
            serviceApiGet(endPoint, 'GET');
    },[endPoint]);

    useEffect(() => {
        setContent(loadBody());
    },[dataSource]);

    const saveData = () =>{
        let tempObj = {...fieldValue};

        if(currentTab == Constants.settori){
            tempObj.description = document.getElementById('description').value;
        }

        if(currentTab == Constants.clientiDiretti){
            tempObj.description = document.getElementById('description').value;
            if(globalState.isMobile){
                const selected = document.querySelectorAll('#select-1 option:checked');
                const values = Array.from(selected).map(el => el.id);
                if(values.length>0)
                    tempObj.sectors = values;
                else
                    return setGlobalState({showAlert:true, messageAlert:Constants.requiredFieldError + Constants.sectorToSelect, typeAlert:'warning'});
            }
            else{
                let obj = [];
                let sector = document.getElementById('sector').children;
                
                for(let i=0; i< sector.length; i++){
                    obj.push(parseInt(sector[i].id));
                }

                tempObj.sectors = obj;
            }
            tempObj.dfp_id = document.getElementById('dfp_id').value;
            tempObj.payment_type = document.getElementById('payment').value;
            tempObj.business_name = document.getElementById('business_name').value;
            tempObj.vat_number = document.getElementById('vat_number').value.toUpperCase();
            tempObj.address = document.getElementById('address').value;
            tempObj.city = document.getElementById('city').value;
            tempObj.recipient_code = document.getElementById('recipient_code').value.toUpperCase();
            tempObj.pec = document.getElementById('pec').value;
            tempObj.administrative_email = document.getElementById('administrative_email').value;
            tempObj.administrative_contact = document.getElementById('administrative_contact').value;
            tempObj.telephone_number = document.getElementById('telephone_number').value;
        }

        if(currentTab == Constants.agenzie){
            tempObj.description = document.getElementById('description').value;
            tempObj.percentage_commission = document.getElementById('percentage_commission').value;
            tempObj.dfp_id = document.getElementById('dfp_id').value;
            tempObj.payment_type = document.getElementById('payment').value;
            tempObj.business_name = document.getElementById('business_name').value;
            tempObj.vat_number = document.getElementById('vat_number').value.toUpperCase();
            tempObj.address = document.getElementById('address').value;
            tempObj.city = document.getElementById('city').value;
            tempObj.recipient_code = document.getElementById('recipient_code').value.toUpperCase();
            tempObj.pec = document.getElementById('pec').value;
            tempObj.administrative_email = document.getElementById('administrative_email').value;
            tempObj.administrative_contact = document.getElementById('administrative_contact').value;
            tempObj.telephone_number = document.getElementById('telephone_number').value;
        }


        if(tempObj.id != null)
            serviceApiSendData(tempObj, endPointUpdate.replace('*', tempObj.id), 'PUT');
        else
            serviceApiSendData(tempObj, endPointCreate, 'POST');
    }

    const loadBody = () =>{
        if(dataSource != undefined){

            let detailButton = false;
            let updateButton = false;
            let deleteButton = false;

            if(currentTab == Constants.agenzie){
                detailButton = globalState.permissions.siteMenu.customers.agency.show != undefined;
                updateButton = globalState.permissions.siteMenu.customers.agency.update != undefined;
                deleteButton = globalState.permissions.siteMenu.customers.agency.delete != undefined;
            }

            if(currentTab == Constants.clientiDiretti){
                detailButton = globalState.permissions.siteMenu.customers.customer.show != undefined;
                updateButton = globalState.permissions.siteMenu.customers.customer.update != undefined;
                deleteButton = globalState.permissions.siteMenu.customers.customer.delete != undefined;
            }

            if(currentTab == Constants.settori){
                detailButton = globalState.permissions.siteMenu.customers.sector.show != undefined;
                updateButton = globalState.permissions.siteMenu.customers.sector.update != undefined;
                deleteButton = globalState.permissions.siteMenu.customers.sector.delete != undefined;
            }
    
            return(
                <tbody>
                    {
                        (dataSource.results.length > 0)
                        ?
                            dataSource.results.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.description}</td>
                                        {currentTab != Constants.agenzie?null:<td>{item.percentage_commission}%</td>}
                                        <TableAction
                                            detailVisible = {detailButton}
                                            updateVisible = {updateButton}
                                            deleteVisible = {deleteButton} 
                                            setModalIsCreate={()=>setIsCreate(false)}
                                            setModalTitleShow={()=>setModalTitle(Constants.modalTitleShow)}
                                            setModalTitleEdit={()=>setModalTitle(Constants.modalTitleEdit)}
                                            setModalVisibility={()=>setModalVisibility(true)}  
                                            setFieldValue={()=>setFieldValue(item)} 
                                            setDeleteId={()=>setDeleteId(item.id)} 
                                            setIsEdit={()=>setIsEdit(true)} 
                                            disableEdit={()=>setIsEdit(false)} 
                                            setModalDeleteVisibility={()=>setModalDeleteVisibility(true)}/>
                                    </tr>
                                );
                            })
                        :
                        <tr>
                            <td colSpan={currentTab != Constants.agenzie?'2':'3'} style={{textAlign:'center', color:'#ccc'}}><FontAwesomeIcon style={{marginRight:40, fontSize:30}} icon="box-open"/> {Constants.noDataFound}</td>
                        </tr>
                    }
                </tbody>
            );    
        }
    }

    const selectSector = (e) =>{
        if(e.target.parentNode.id == 'select-1'){
            let select2 = document.getElementById('sector');
            select2.append(e.target);
        }
        else{
            let select1 = document.getElementById('select-1');
            select1.append(e.target);
        }
    }

    const bindSelectedSector = (fullSelect) =>{
        if(globalState.isMobile){
            if(sectors.results != undefined){
                let obj  = [...sectors.results];
                return obj.map((item, index) => {
                    if(fieldValue.sectors != null){
                        if(fieldValue.sectors.indexOf(item.id) > -1)
                            return <option selected={true} key={index} id={item.id}>{item.description}</option>;
                        else
                            return <option key={index} id={item.id}>{item.description}</option>;
                    }
                    else
                        return <option key={index} id={item.id}>{item.description}</option>;
                })
            }
        }
        else{
            if(sectors != undefined){
                if(sectors.results != undefined){
                    let obj  = [...sectors.results];
                    let newObj  = [];
    
                    if(fieldValue.sectors!=null){
                        for (let i = 0; i<fieldValue.sectors.length; i++){
                            for(let e = 0; e<obj.length; e++){
                                if(obj[e].id == fieldValue.sectors[i]){
                                    newObj.push(obj[e]);
                                    obj.splice(e, 1 );
                                    break
                                }   
                            }
                        }
                        if(!fullSelect){
                            return newObj.map((item, index) => {
                                return <option onClick={(e)=>selectSector(e)} key={index} id={item.id}>{item.description}</option>;
                            })
                        }
                        else{
                            return obj.map((item, index) => {
                                return <option onClick={(e)=>selectSector(e)} key={index} id={item.id}>{item.description}</option>;
                            })
                        }   
                    }
                    else{
                        if(fullSelect){
                            return obj.map((item, index) => {
                                return <option onClick={(e)=>selectSector(e)} key={index} id={item.id}>{item.description}</option>;
                            })
                        }
                    } 
                }
            }
        }
    }

    const populatePayment = (obj) =>{
        return obj.results.map((item, index) => {
            return <option key={index} value={item.id}>{item.description}</option>;
        })
    }

    const loadBodyModal = () =>{
        return(
            <Form.Group>
                <Form.Label>{Constants.description}</Form.Label>
                <Form.Control id="description" type="text" placeholder={Constants.descrizionePlaceholder} defaultValue={fieldValue.description} disabled={!isEdit} required/>
                <br/>
                {currentTab == Constants.clientiDiretti
                ?  
                <div>
                    {globalState.isMobile?
                        <>
                        <Form.Label>{Constants.sectorToSelect}</Form.Label>
                        <Form.Control id="select-1" as="select" disabled={!isEdit} multiple={true}>
                            {bindSelectedSector(true)}
                        </Form.Control>
                        </>
                        :
                        <Row>
                            <Col>
                                <Form.Label>{Constants.sectorToSelect}</Form.Label>
                                <Form.Control id="select-1" as="select" disabled={!isEdit} multiple={true}>
                                    {bindSelectedSector(true)}
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>{Constants.sector}</Form.Label>
                                <Form.Control id="sector" as="select" disabled={!isEdit} multiple={true} required>
                                    {bindSelectedSector(false)}
                                </Form.Control>
                            </Col>
                        </Row>
                    }
                    <br/>
                </div>
                :""}

                {currentTab == Constants.agenzie
                ?  
                <div>
                    <Form.Label>{Constants.percentage_commission}</Form.Label>
                    <InputGroup className="mb-3">
                        <FormControl id="percentage_commission" type="number" min="0" max="100" placeholder={Constants.percentualePlaceholder} defaultValue={fieldValue.percentage_commission} disabled={!isEdit} onInput={(e)=>(e.target.value>100 || e.target.value<0)?e.target.value = "":""} required/>
                        <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    <br/>
                </div>
                :""}

                {currentTab != Constants.settori
                ?  
                <div>
                    {globalState.isMobile?
                        <>
                            <Form.Label>{Constants.business_name}</Form.Label>
                            <Form.Control id="business_name" type="text" placeholder={Constants.descrizionePlaceholder} defaultValue={fieldValue.business_name} disabled={!isEdit} required/>
                            <br/>
                            <Form.Label>{Constants.terminiPagamento}</Form.Label>
                            <Form.Control id="payment" as="select" defaultValue={fieldValue.payment_type!=null?fieldValue.payment_type:''} disabled={!isEdit}>
                                <option value="">{Constants.seleziona}</option>
                                {payment}
                            </Form.Control>
                            <br/>
                            <Form.Label>{Constants.address}</Form.Label>
                            <Form.Control id="address" type="text" placeholder={Constants.addressPlaceholder} defaultValue={fieldValue.address} disabled={!isEdit} required/>
                            <br/>
                            <Form.Label>{Constants.city}</Form.Label>
                            <Form.Control id="city" type="text" placeholder={Constants.cityPlaceholder} defaultValue={fieldValue.city} disabled={!isEdit} required/>
                            <br/>
                            <Form.Label>{Constants.vat_number}</Form.Label>
                            <Form.Control id="vat_number" maxLength="11" type="text" placeholder={Constants.vat_numberPlaceholder} defaultValue={fieldValue.vat_number} disabled={!isEdit} required/>
                            <br/>
                            <Form.Label>{Constants.recipient_code}</Form.Label>
                            <Form.Control id="recipient_code" type="text" placeholder={Constants.recipient_codePlaceholder} defaultValue={fieldValue.recipient_code} disabled={!isEdit} required/>
                            <br/>
                            <Form.Label>{Constants.pec}</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl id="pec" type="email" placeholder={Constants.pecPlaceholder} defaultValue={fieldValue.pec} disabled={!isEdit} required/>
                                <InputGroup.Append>
                                <InputGroup.Text>@</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                            <br/>
                            <Form.Label>{Constants.administrative_email}</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl id="administrative_email" type="email" placeholder={Constants.administrative_emailPlaceholder} defaultValue={fieldValue.administrative_email} disabled={!isEdit}  required/>
                                <InputGroup.Append>
                                <InputGroup.Text>@</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                            <br/>
                            <Form.Label>{Constants.administrative_contact}</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl id="administrative_contact" type="text" placeholder={Constants.administrative_contactPlaceholder} defaultValue={fieldValue.administrative_contact} disabled={!isEdit} required/>
                                <InputGroup.Append>
                                <InputGroup.Text><FontAwesomeIcon icon="user"/></InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                            <br/>
                            <Form.Label>{Constants.telephone_number}</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl id="telephone_number" type="number" placeholder={Constants.telephone_numberPlaceholder} defaultValue={fieldValue.telephone_number} disabled={!isEdit}  required/>
                                <InputGroup.Append>
                                <InputGroup.Text><FontAwesomeIcon icon="phone"/></InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Form.Label>{Constants.business_name}</Form.Label>
                                    <Form.Control id="business_name" type="text" placeholder={Constants.descrizionePlaceholder} defaultValue={fieldValue.business_name} disabled={!isEdit} required/>
                                </Col>
                                <Col>
                                    <Form.Label>{Constants.terminiPagamento}</Form.Label>
                                    <Form.Control id="payment" as="select" defaultValue={fieldValue.payment_type!=null?fieldValue.payment_type:''} disabled={!isEdit}>
                                        <option value="">{Constants.seleziona}</option>
                                        {payment}
                                    </Form.Control>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <Form.Label>{Constants.address}</Form.Label>
                                    <Form.Control id="address" type="text" placeholder={Constants.addressPlaceholder} defaultValue={fieldValue.address} disabled={!isEdit} required/>
                                </Col>
                                <Col>
                                    <Form.Label>{Constants.city}</Form.Label>
                                    <Form.Control id="city" type="text" placeholder={Constants.cityPlaceholder} defaultValue={fieldValue.city} disabled={!isEdit} required/>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <Form.Label>{Constants.vat_number}</Form.Label>
                                    <Form.Control id="vat_number" maxLength="11" type="text" placeholder={Constants.vat_numberPlaceholder} defaultValue={fieldValue.vat_number} disabled={!isEdit} required/>
                                </Col>
                                <Col>
                                    <Form.Label>{Constants.recipient_code}</Form.Label>
                                    <Form.Control id="recipient_code" type="text" placeholder={Constants.recipient_codePlaceholder} defaultValue={fieldValue.recipient_code} disabled={!isEdit} required/>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                <Form.Label>{Constants.pec}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="pec" type="email" placeholder={Constants.pecPlaceholder} defaultValue={fieldValue.pec} disabled={!isEdit} required/>
                                        <InputGroup.Append>
                                        <InputGroup.Text>@</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label>{Constants.administrative_email}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="administrative_email" type="email" placeholder={Constants.administrative_emailPlaceholder} defaultValue={fieldValue.administrative_email} disabled={!isEdit}  required/>
                                        <InputGroup.Append>
                                        <InputGroup.Text>@</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <Form.Label>{Constants.administrative_contact}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="administrative_contact" type="text" placeholder={Constants.administrative_contactPlaceholder} defaultValue={fieldValue.administrative_contact} disabled={!isEdit} required/>
                                        <InputGroup.Append>
                                        <InputGroup.Text><FontAwesomeIcon icon="user"/></InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label>{Constants.telephone_number}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="telephone_number" type="number" placeholder={Constants.telephone_numberPlaceholder} defaultValue={fieldValue.telephone_number} disabled={!isEdit}  required/>
                                        <InputGroup.Append>
                                        <InputGroup.Text><FontAwesomeIcon icon="phone"/></InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </>
                    }
                    <br/>
                    <Form.Label>{Constants.dfp_id}</Form.Label>
                    <Form.Control id="dfp_id" type="number" defaultValue={fieldValue.dfp_id} disabled={globalState.permissions.siteMenu.ADaction.add && isEdit == true?false:true} onInput={(e)=>(e.target.value<0)?e.target.value = "":""} required/> 
                    <br/>
                </div>
                :""}                  
            </Form.Group>
        );
    }

    const loadTable = () =>{
        return(
            <div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{Constants.description}</th>
                            {currentTab != Constants.agenzie?null:<th>{Constants.percentage_commission}</th>}
                            <th>{Constants.azioni}</th>
                        </tr>
                    </thead>
                    {content}
                </Table>
                <TablePagination 
                    dataSource={dataSource} 
                    serviceApiGetPrev={()=>serviceApiGet(endPoint + dataSource.previous, 'GET')} 
                    serviceApiGetNext={()=>serviceApiGet(endPoint + dataSource.next, 'GET')} />
            </div>
        );
    }

    const loadButtonAdd = () =>{
        let permitted = false;

        if(currentTab == Constants.agenzie)
            permitted=globalState.permissions.siteMenu.customers.agency.create!= null;
        if(currentTab == Constants.clientiDiretti)
            permitted=globalState.permissions.siteMenu.customers.customer.create!= null;
        if(currentTab == Constants.settori)
            permitted=globalState.permissions.siteMenu.customers.sector.create!= null;

        if(permitted){
            return(
                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.crea + " " + currentTab}</Tooltip>}>
                    <Button variant="default" onClick={()=>{setIsCreate(true); setModalTitle(Constants.modalTitleNew); setModalVisibility(true); setFieldValue(emptyObj); setIsEdit(true);}}>
                        <FontAwesomeIcon style={{marginRight:10}} icon="plus-circle"/>
                        {Constants.crea + " " + currentTab}
                    </Button>
                </OverlayTrigger>
            )
        }
    }

    const loadButtonTab= (tabName) =>{
        let permitted = false;
        
        if(tabName == Constants.agenzie)
            permitted=globalState.permissions.siteMenu.customers.agency.show != null;
        if(tabName == Constants.clientiDiretti)
            permitted=globalState.permissions.siteMenu.customers.customer.show != null;
        if(tabName == Constants.settori)
            permitted=globalState.permissions.siteMenu.customers.sector.show != null;

        if(permitted){
            return (
                <Tab eventKey={tabName} title={tabName}>
                    {loadTable()}
                </Tab>
            )
        }
    }
    
    return (
        <div className="container col-sm-12 table-responsive fade-in-left" style={{padding:'40px'}}>
            {loadButtonAdd()}
            <br/><br/>
                <Filtri endPoint={endPoint} setDatasource={setDatasource} setCurrentEndPoint={setCurrentEndPoint} dataSource={dataSource} />
            <br/>
            <Tabs activeKey={key} onSelect={k => {setKey(k); setCurrentTab(k);}}>
                {loadButtonTab(Constants.agenzie)}
                {loadButtonTab(Constants.clientiDiretti)}
                {loadButtonTab(Constants.settori)}
            </Tabs>

            <Modal onHide={(e)=>{return}} show={modalVisibility} size="lg"  centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {modalTitle}
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.chiudi}</Tooltip>}>
                            <Button style={{right:17, top:17, position:'absolute'}} variant="danger" onClick={()=>{setModalVisibility(false); setFieldValue(emptyObj); setIsEdit(true);}}>
                                <FontAwesomeIcon style={{fontSize:30}} icon="times"/>
                            </Button>
                        </OverlayTrigger>
                    </Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        {loadBodyModal()}
                    </Modal.Body>
                    <Modal.Footer>
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.annulla}</Tooltip>}>
                            <Button variant="secondary" onClick={()=>{setModalVisibility(false); setFieldValue(emptyObj); setIsEdit(true);}}>{Constants.annulla}</Button>
                        </OverlayTrigger>
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.salva}</Tooltip>}>
                            <Button type="submit" variant="primary" onClick={(e)=>{saveData(); e.preventDefault();}} style={{display:!isEdit?'none':'block'}} disabled={!isEdit}>{Constants.salva}</Button>
                        </OverlayTrigger>
                    </Modal.Footer>
                </Form>
            </Modal>

            <ModalDelete reloadTable={()=>serviceApiGet(currentEndPoint, 'GET')} closeModal={()=>setModalDeleteVisibility(false)} visible={modalDeleteVisibility} rowid={deleteId} endpoint={endPointDelete}/>
        </div>
    );


    /////////////API//////////////////////////////////

    function serviceApiSendData(obj, url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{
                'Content-Type':'application/json', 
                'Authorization':'Bearer ' + globalState.accessToken,
                'Accept-Language': 'it'
            },
            data:obj
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.successMessage, typeAlert:'success'});
            serviceApiGet(currentEndPoint, 'GET');
            setModalVisibility(false);
        })
        .catch(function (error) {
            if(error.response.status == 400){
                let Message = Constants.requiredFieldError;
                var keys = [];
                for (var k in error.response.data){
                    keys.push(k);
                    Message = Message + Constants[k] + ", ";
                    document.getElementById(k).classList.add('setErrorField');
                }
                setGlobalState({showSpinner:false, showAlert:true, messageAlert:Message.substring(0, Message.length - 1), typeAlert:'warning'});
            }
            else
                setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});

            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGet(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setDatasource(response.data);
            setCurrentEndPoint(url);
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }


    function serviceApiGetPayment(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setPayment(populatePayment(response.data));
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }


    function serviceApiGetSectors(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setSectors(response.data);
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }
}


    

