import React, {useState} from 'react';
import * as Constants from '../constants/constants';
import {setGlobalState} from '../GlobalState';
import {Row, Col, InputGroup, Form, FormControl, Modal, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CopyPosition(props) {

    const [newPos, setNewPos] = useState();

    const beginningDateChange = () =>{
        let sDate = document.getElementById('posDateStart').value;
        let sHour = document.getElementById('posHourStart').value;

        if(sDate!=""){
            if(sHour == "")
                sHour = "T00:01" ;
            else
                sHour="T"+ sHour;
            
            return sDate + sHour;
        }
    }

    const endDateChange = () =>{
        let eDate = document.getElementById('posDateEnd').value;
        let eHour = document.getElementById('posHourEnd').value;

        if(eDate!=""){
            if(eHour == "" || eHour=="00:00")
                eHour = "T23:59" ;
            else
                eHour="T"+ eHour;

            return eDate + eHour;
        }
    }

    const changeValue = () =>{
        let tempObj = {
            position:null,
            beginning_date:null,
            end_date:null,
            cpm_sale:null,
            cpm_back:null,
            forfait_sale:null,
            forfait_back:null,
            impression:null
        }
        
        tempObj.position=document.getElementById('pos').value;
        tempObj.beginning_date = beginningDateChange();
        tempObj.end_date = endDateChange();

        if(!props.fixed){
            tempObj.cpm_sale=document.getElementById('posSell').value;
            tempObj.cpm_back=document.getElementById('posBack').value;
        }
        else{
            tempObj.forfait_sale=document.getElementById('posSell').value;
            tempObj.forfait_back=document.getElementById('posBack').value;
        }
        
        if(document.getElementById('posImpression').value == "")
            tempObj.impression=null;
        else
            tempObj.impression=document.getElementById('posImpression').value;

        setNewPos(tempObj)
    }

    const appendCopiedPosition = () =>{
        let sDate = document.getElementById('posDateStart');
        let eDate = document.getElementById('posDateEnd');
        let posSell = document.getElementById('posSell');
        if(sDate.value!="" && eDate.value !="" && posSell.value!=""){
            let oldObj = {...props.positionElement};
            if(props.fixed)
                oldObj.fixed.push(newPos);
            else
                oldObj.unfixed.push(newPos);

            props.setPositionElement(oldObj);
            props.setModalCopyPosition();
            setGlobalState({showAlert:true, messageAlert:Constants.successCopy, typeAlert:'primary'});
        }
        else{
            eDate.classList.add('setErrorField');
            sDate.classList.add('setErrorField');
            posSell.classList.add('setErrorField');
            if(sDate.value == "" || eDate.value == "")
                return setGlobalState({showAlert:true, messageAlert:Constants.dateEmptyError, typeAlert:'warning'});
            else if(posSell.value == "")
                return setGlobalState({showAlert:true, messageAlert:Constants.positionsRequiredField + " : " + Constants.cpmVendita + "/" + Constants.forfaitVendita, typeAlert:'warning'});
        }
    }


    const verifyDatePosition = (fixed, index) =>{     
        let sDate = document.getElementById('posDateStart');
        let eDate = document.getElementById('posDateEnd');
        let sHour = document.getElementById('posHourStart');
        let eHour = document.getElementById('posHourEnd');
        if(parseInt(sDate.value.replace(/-/g,''))>parseInt(eDate.value.replace(/-/g,''))){
            //sDate.value=""
            eDate.value="";
            return setGlobalState({showAlert:true, messageAlert:Constants.dateError, typeAlert:'warning'});
        }
        else if(parseInt(sDate.value.replace(/-/g,'')) == parseInt(eDate.value.replace(/-/g,''))){
            if(parseInt(sHour.value.replace(/T/g,'').replace(/:/g,''))>=parseInt(eHour.value.replace(/T/g,'').replace(/:/g,''))){
                //sHour.value=""
                eHour.value="";
                return setGlobalState({showAlert:true, messageAlert:Constants.hourError, typeAlert:'warning'});
            }
        }
    }
    
    return (
        <Modal onHide={(e)=>{return}} style={{backgroundColor:'rgba(0,0,0,0.3)'}} show={true} centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {Constants.copiaPosizione}
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.chiudi}</Tooltip>}>
                        <Button style={{right:17, top:17, position:'absolute'}} variant="danger" onClick={()=>props.setModalCopyPosition()}>
                            <FontAwesomeIcon style={{fontSize:30}} icon="times"/>
                        </Button>
                    </OverlayTrigger>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Label>{Constants.posizione}</Form.Label>
            <Form.Control id='pos' as="select" disabled={!props.isEdit} defaultValue={props.item.position !=null?props.item.position:''} onChange={()=>changeValue()}>
                <option value="">{Constants.seleziona}</option>
                {props.fixed?props.positionsFixed:props.positionsUnfixed}
            </Form.Control>
            
            <Row>
                <Col>
                    <Form.Label>{Constants.dataInizio}</Form.Label>
                    <Form.Control 
                        id='posDateStart'
                        type="date" 
                        disabled={!props.isEdit} 
                        onChange={()=>{changeValue(); verifyDatePosition(props.fixed, props.index);}} 
                        onKeyDown={(e) => e.preventDefault()} required/>
                </Col>
                <Col>
                    <Form.Label>{Constants.oraInizio}</Form.Label>
                    <Form.Control id='posHourStart' type="time" disabled={!props.isEdit} onChange={()=>{changeValue(); verifyDatePosition(props.fixed, props.index);}} required/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label>{Constants.dataFine}</Form.Label>
                    <Form.Control 
                        id='posDateEnd' 
                        type="date" 
                        disabled={!props.isEdit}
                        onChange={()=>{changeValue(); verifyDatePosition(props.fixed, props.index);}} 
                        onKeyDown={(e) => e.preventDefault()} required/>
                </Col>
                <Col>
                    <Form.Label>{Constants.oraFine}</Form.Label>
                    <Form.Control id='posHourEnd' type="time" disabled={!props.isEdit} onChange={()=>{changeValue(); verifyDatePosition(props.fixed, props.index);}} required/>
                </Col>
            </Row>
            <Form.Label>{props.fixed?Constants.forfaitVendita:Constants.cpmVendita}</Form.Label>
            <InputGroup>
                <FormControl id='posSell' type="number" min="0" defaultValue={props.fixed?props.item.forfait_sale:props.item.cpm_sale} placeholder={Constants.inserisciValorePlaceholder} disabled={!props.isEdit} onChange={()=>changeValue()} required/>
                <InputGroup.Append>
                <InputGroup.Text>€</InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
            <Form.Label>{props.fixed?Constants.forfaitGiro:Constants.cpmGiro}</Form.Label>
            <InputGroup>
                <FormControl id='posBack' type="number" min="0" defaultValue={props.fixed?props.item.forfait_back:props.item.cpm_back} placeholder={Constants.inserisciValorePlaceholder} disabled={!props.isEdit} onChange={()=>changeValue()} required/>
                <InputGroup.Append>
                <InputGroup.Text>€</InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
            <Form.Label>{Constants.impression}</Form.Label>
            <Form.Control id='posImpression' type="number" placeholder={Constants.impressionPlaceholder} defaultValue={props.item.impression} disabled={!props.isEdit} onChange={()=>changeValue()} required/>
            </Modal.Body>
            <Modal.Footer>
            <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.annulla}</Tooltip>}>
                <Button variant="secondary" onClick={()=>props.setModalCopyPosition()}>{Constants.annulla}</Button>
            </OverlayTrigger>
            <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.copiaPosizione}</Tooltip>}>
                <Button variant="success" onClick={()=>appendCopiedPosition()}>{Constants.copiaPosizione}</Button>
            </OverlayTrigger>
            </Modal.Footer>
        </Modal>
    );
}


    

