import { useState } from 'react';

let state;
let setState;

/**
 * @typedef GlobalState
 * @property {Object} currentUser
 * @property {String} accessToken
 */

/**
 * 
 * @param {GlobalState} initialState
 * @returns {[GlobalState, Function]}
 * 
*/

export const useCreateGlobalState = (initialState) => {
    [state, setState] = useState(initialState);
    return state;
};

/**
 * 
 * @returns {GlobalState}
 * 
*/

export const useGlobalState = () => {
    return state;
};

/**
 * 
 * @param {Object} newState
 * @returns {void}
 *  
*/

export const setGlobalState = (newState) => {
    setState(prevState => {
        return { ...prevState, ...newState }
    });
};