import React, {useState, useEffect} from 'react';
import {useGlobalState, setGlobalState} from '../GlobalState';
import {Button, Form, Row, Col, InputGroup, FormControl, Accordion, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import * as Constants from '../constants/constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from 'axios';
import {deleteCookie} from '../cookie';

export default function Filtri(props) {
    let globalState = useGlobalState();
    const [area, setArea] = useState();
    const [site, setSite] = useState();
    const [type, setType] = useState();
    const [status, setStatus] = useState();
    const [chevron, setChevron] = useState(false);

    useEffect(()=>{
        if(props.endPoint == Constants.getPosition){
            serviceApiGetArea(Constants.getArea + '?size=9999', 'GET');
            serviceApiGetSiti(Constants.getSiti + '?size=9999', 'GET');
            serviceApiGetType(Constants.getTipi + '?size=9999', 'GET');
        }
        if(props.endPoint == Constants.getAggregate){
            serviceApiGetSiti(Constants.getSiti + '?size=9999&is_visible=True', 'GET');
        }
        if(props.endPoint == Constants.getAdUnit){
            serviceApiGetSiti(Constants.getSiti + '?size=9999', 'GET');
        }
    },[props.endPoint])

    useEffect(() => {
        if(props.dataSource != null){
            if(props.endPoint == Constants.getCampagne)
                loadStatus();
        }
    },[props.dataSource]);

    const loadStatus = () =>{
        serviceApiGetStatus(Constants.getStatus + '?size=9999', 'GET')
    }


    const populateStatus = (obj) =>{
        return obj.results.map((item, index) => {
            return <option key={index} value={item.id}>{item.description}</option>;
        })
    }

    const startSearch = () =>{
        let description;
        if(props.endPoint != Constants.getPosition)
            description = document.getElementById('descrizioneFilter').value;
        if(props.endPoint == Constants.getCampagne){
            let startDate = document.getElementById('startDateFilter').value;
            let endDate = document.getElementById('endDateFilter').value;
            let status = document.getElementById('status_filter').value;
            serviceApiGetFilterResult(props.endPoint + '?search=' + description + '&beginning_date=' + startDate + '&end_date=' + endDate + '&states_campaign=' + status , 'GET');
        }
        if(props.endPoint == Constants.getClientiDiretti || props.endPoint == Constants.getAgenzie || props.endPoint == Constants.getSettori)
            serviceApiGetFilterResult(props.endPoint + '?search=' + description, 'GET');
        if(props.endPoint == Constants.getTipi){
            let fixed = document.getElementById('fixed_filter').value;
            serviceApiGetFilterResult(props.endPoint + '?search=' + description + '&fixed=' + fixed, 'GET');
        }
        if(props.endPoint == Constants.getArea){
            serviceApiGetFilterResult(props.endPoint + '?search=' + description, 'GET');
        }
        if(props.endPoint == Constants.getSiti){
            let netSite = document.getElementById('netSite_filter').value;
            let visible = document.getElementById('visibleFilter').value;
            serviceApiGetFilterResult(props.endPoint + '?search=' + description + '&is_netaddiction_site=' + netSite + '&is_visible=' + visible, 'GET');
        }
        if(props.endPoint == Constants.getAggregate){
            let siti = document.getElementById('sito_filter').value;
            serviceApiGetFilterResult(props.endPoint + '?search=' + description + '&site=' + siti, 'GET');
        }
        if(props.endPoint == Constants.getPosition){
            let siti = document.getElementById('sito_filter').value;
            let aree = document.getElementById('area_filter').value;
            let tipi = document.getElementById('tipo_filter').value;
            let visible = document.getElementById('visible_filter').value;
            serviceApiGetFilterResult(props.endPoint + '?type=' + tipi + '&area=' + aree + '&site=' + siti + '&is_visible=' + visible , 'GET');
        }
        if(props.endPoint == Constants.getAdUnit){
            let dominance = document.getElementById('dominance').value;
            let siti = document.getElementById('sito_filter').value;
            serviceApiGetFilterResult(props.endPoint + '?search=' + description + '&is_dominance=' + dominance + '&position__site=' + siti, 'GET');
        }
    }
 
    const verifyDateFilter = () =>{     
        let sDate = document.getElementById('startDateFilter');
        let eDate = document.getElementById('endDateFilter');
        if(parseInt(sDate.value.replace(/-/g,''))>parseInt(eDate.value.replace(/-/g,''))){
            //sDate.value=""
            eDate.value="";
            setGlobalState({showAlert:true, messageAlert:Constants.dateError, typeAlert:'warning'});
        }
    }

    const populateAreaSiteTipe = (obj) =>{
        return obj.results.map((item, index) => {
            return <option key={index} value={item.id}>{item.description}</option>;
        });
    }

    return(
        <Accordion>
            <Card>
                <Accordion.Toggle onClick={()=>setChevron(!chevron)} as={Card.Header} eventKey="0" style={{cursor:'pointer'}}>
                    <h5><FontAwesomeIcon style={{marginRight:10}} icon={chevron?'chevron-up':'chevron-down'}/>{Constants.filtiDiRicerca}</h5>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body style={{padding:20}}>
                    <Form>
                        {globalState.isMobile?
                        <Row style={{paddingRight:16, paddingLeft:16, paddingBottom:20}}>
                            {props.endPoint != Constants.getPosition?
                                <>
                                    <Form.Label>{Constants.cerca}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl id="descrizioneFilter" type="text" placeholder={Constants.ricercaDescrizionePlaceholder}/>
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon="search"/>
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <br/>
                                </>
                            :null
                            }
                            {props.endPoint == Constants.getCampagne?
                                <>
                                    <Form.Label>{Constants.dataInizio}</Form.Label>
                                    <Form.Control id="startDateFilter" type="date" onChange={()=>verifyDateFilter()}/>
                                    <br/>
                                    <Form.Label>{Constants.dataFine}</Form.Label>
                                    <Form.Control id="endDateFilter" type="date" onChange={()=>verifyDateFilter()}/>
                                    <br/>
                                    <Form.Label>{Constants.states_campaign}</Form.Label>
                                    <Form.Control id="status_filter" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        {status}
                                    </Form.Control>
                                    <br/>
                                </>
                            :null
                            }
                            {props.endPoint == Constants.getTipi?
                                <>
                                    <Form.Label>{Constants.tipologia}</Form.Label>
                                    <Form.Control id="fixed_filter" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        <option value="true">{Constants.fissa}</option>
                                        <option value="false">{Constants.nonFissa}</option>
                                    </Form.Control>
                                    <br/>
                                </>
                            :null
                            }
                            {props.endPoint == Constants.getSiti?
                                <>
                                    <Form.Label>{Constants.netSiteQuestion}</Form.Label>
                                    <Form.Control id="netSite_filter" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        <option value="true">{Constants.si}</option>
                                        <option value="false">{Constants.no}</option>
                                    </Form.Control>
                                    <br/>
                                    <Form.Label>{Constants.isActiveQuestion}</Form.Label>
                                    <Form.Control id="visibleFilter" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        <option value="true">{Constants.si}</option>
                                        <option value="false">{Constants.no}</option>
                                    </Form.Control>
                                </>
                            :null
                            }
                            {props.endPoint == Constants.getPosition?
                                <>
                                    <Form.Label>{Constants.site}</Form.Label>
                                    <Form.Control id="sito_filter" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        {site}
                                    </Form.Control>
                                    <br/>
                                    <Form.Label>{Constants.area}</Form.Label>
                                    <Form.Control id="area_filter" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        {area}
                                    </Form.Control>
                                    <br/>
                                    <Form.Label>{Constants.type}</Form.Label>
                                    <Form.Control id="tipo_filter" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        {type}
                                    </Form.Control>
                                    <br/>
                                    <Form.Label>{Constants.visibileQuestion}</Form.Label>
                                    <Form.Control id="visible_filter" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        <option value="true">{Constants.si}</option>
                                        <option value="false">{Constants.no}</option>
                                    </Form.Control>
                                    <br/>
                                </>
                            :null
                            }
                            {props.endPoint == Constants.getAggregate?
                                <>
                                    <Form.Label>{Constants.site}</Form.Label>
                                    <Form.Control id="sito_filter" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        {site}
                                    </Form.Control>
                                    <br/>
                                </>
                            :null
                            }
                            {props.endPoint == Constants.getAdUnit?
                                <>
                                    <Form.Label>{Constants.site}</Form.Label>
                                    <Form.Control id="sito_filter" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        {site}
                                    </Form.Control>
                                    <br/>
                                    <Form.Label>{Constants.dominanceQuestion}</Form.Label>
                                    <Form.Control id="dominance" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        <option value="true">{Constants.si}</option>
                                        <option value="false">{Constants.no}</option>
                                    </Form.Control>
                                    <br/>
                                </>
                            :null
                            }
                        </Row>
                        :
                        <Row>
                            {props.endPoint != Constants.getPosition?
                            <Col>
                                <Form.Label>{Constants.cerca}</Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl id="descrizioneFilter" type="text" placeholder={Constants.ricercaDescrizionePlaceholder}/>
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon="search"/>
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                            :null
                            }
                            {props.endPoint == Constants.getCampagne?
                                <>
                                    <Col>
                                        <Form.Label>{Constants.dataInizio}</Form.Label>
                                        <Form.Control id="startDateFilter" type="date" onChange={()=>verifyDateFilter()}/>
                                    </Col>
                                    <Col>
                                        <Form.Label>{Constants.dataFine}</Form.Label>
                                        <Form.Control id="endDateFilter" type="date" onChange={()=>verifyDateFilter()}/>
                                    </Col>
                                    <Col>
                                        <Form.Label>{Constants.states_campaign}</Form.Label>
                                        <Form.Control id="status_filter" as="select">
                                            <option value="">{Constants.seleziona}</option>
                                            {status}
                                        </Form.Control>
                                    </Col>
                                </>
                            :null
                            }
                            {props.endPoint == Constants.getTipi?
                                <>
                                    <Col>
                                        <Form.Label>{Constants.tipologia}</Form.Label>
                                        <Form.Control id="fixed_filter" as="select">
                                            <option value="">{Constants.seleziona}</option>
                                            <option value="true">{Constants.fissa}</option>
                                            <option value="false">{Constants.nonFissa}</option>
                                        </Form.Control>
                                    </Col>
                                </>
                            :null
                            }
                            {props.endPoint == Constants.getSiti?
                                <>
                                    <Col>
                                        <Form.Label>{Constants.netSiteQuestion}</Form.Label>
                                        <Form.Control id="netSite_filter" as="select">
                                            <option value="">{Constants.seleziona}</option>
                                            <option value="true">{Constants.si}</option>
                                            <option value="false">{Constants.no}</option>
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label>{Constants.isActiveQuestion}</Form.Label>
                                        <Form.Control id="visibleFilter" as="select">
                                            <option value="">{Constants.seleziona}</option>
                                            <option value="true">{Constants.si}</option>
                                            <option value="false">{Constants.no}</option>
                                        </Form.Control>
                                    </Col>
                                </>
                            :null
                            }
                            {props.endPoint == Constants.getPosition?
                                <>
                                    <Col style={{marginBottom:15}}>
                                        <Form.Label>{Constants.site}</Form.Label>
                                        <Form.Control id="sito_filter" as="select">
                                            <option value="">{Constants.seleziona}</option>
                                            {site}
                                        </Form.Control>
                                    </Col>
                                    <Col style={{marginBottom:15}}>
                                        <Form.Label>{Constants.area}</Form.Label>
                                        <Form.Control id="area_filter" as="select">
                                            <option value="">{Constants.seleziona}</option>
                                            {area}
                                        </Form.Control>
                                    </Col>
                                    <Col style={{marginBottom:15}}>
                                        <Form.Label>{Constants.type}</Form.Label>
                                        <Form.Control id="tipo_filter" as="select">
                                            <option value="">{Constants.seleziona}</option>
                                            {type}
                                        </Form.Control>
                                    </Col>
                                    <Col style={{marginBottom:15}}>
                                        <Form.Label>{Constants.visibileQuestion}</Form.Label>
                                        <Form.Control id="visible_filter" as="select">
                                            <option value="">{Constants.seleziona}</option>
                                            <option value="true">{Constants.si}</option>
                                            <option value="false">{Constants.no}</option>
                                        </Form.Control>
                                    </Col>
                                </>
                            :null
                            }
                            {props.endPoint == Constants.getAggregate?
                                <>
                                    <Col>
                                        <Form.Label>{Constants.site}</Form.Label>
                                        <Form.Control id="sito_filter" as="select">
                                            <option value="">{Constants.seleziona}</option>
                                            {site}
                                        </Form.Control>
                                    </Col>
                                </>
                            :null
                            }
                            {props.endPoint == Constants.getAdUnit?
                                <>  
                                    <Col style={{marginBottom:15}}>
                                        <Form.Label>{Constants.site}</Form.Label>
                                        <Form.Control id="sito_filter" as="select">
                                            <option value="">{Constants.seleziona}</option>
                                            {site}
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label>{Constants.dominanceQuestion}</Form.Label>
                                        <Form.Control id="dominance" as="select">
                                            <option value="">{Constants.seleziona}</option>
                                            <option value="true">{Constants.si}</option>
                                            <option value="false">{Constants.no}</option>
                                        </Form.Control>
                                    </Col>
                                </>
                            :null
                            }
                            <br/>
                        </Row>
                        }
                        <Row>
                            <Col>
                                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.cerca}</Tooltip>}>
                                    <Button variant="default" type="submit" style={{marginRight:20}} onClick={(e)=>{startSearch(); e.preventDefault();}}>
                                        <FontAwesomeIcon style={{marginRight:10}} icon="search"/>
                                        {Constants.cerca}
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.reset}</Tooltip>}>
                                    <Button variant="default" type="reset" style={{marginRight:20}}>
                                        <FontAwesomeIcon style={{marginRight:10}} icon="times"/>
                                        {Constants.reset}
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );


    function serviceApiGetFilterResult(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.searchMessage + ' ' + response.data.count, typeAlert:'primary'});
            props.setDatasource(response.data);
            props.setCurrentEndPoint(url);
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetStatus(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setStatus(populateStatus(response.data));
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetArea(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setArea(populateAreaSiteTipe(response.data))
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetSiti(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setSite(populateAreaSiteTipe(response.data))
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetType(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setType(populateAreaSiteTipe(response.data))
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }
}