import React from 'react';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import * as Constants from '../constants/constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useGlobalState} from '../GlobalState';

export default function TableAction(props) {

    let globalState = useGlobalState();

    const createCopyObj = () =>{
        let emptyPosition={
            fixed:[],
            unfixed:[]
        }
        let newObj = {...props.item};
        delete newObj.id;
        delete newObj.states_campaign;
        delete newObj.unique_code;
        delete newObj.beginning_date;
        delete newObj.end_date;
        delete newObj.dfp_id;
        delete newObj.amount;
        delete newObj.amount_back;
        delete newObj.date_creations;
        delete newObj.date_latest_modification;
    
        newObj.positions.fixed.map((item, index)=>{
            let tempObj = {
                position:null,
                beginning_date:null,
                end_date:null,
                cpm_sale:null,
                cpm_back:null,
                forfait_sale:null,
                forfait_back:null,
                impression:null
            }
            tempObj.position = item.position
            tempObj.forfait_sale = item.forfait_sale
            tempObj.forfait_back = item.forfait_back
            tempObj.impression = item.impression
            emptyPosition.fixed.push(tempObj)
        })

        newObj.positions.unfixed.map((item, index)=>{
            let tempObj = {
                position:null,
                beginning_date:null,
                end_date:null,
                cpm_sale:null,
                cpm_back:null,
                forfait_sale:null,
                forfait_back:null,
                impression:null
            }
            tempObj.position = item.position
            tempObj.cpm_sale = item.cpm_sale
            tempObj.cpm_back = item.cpm_back
            tempObj.impression = item.impression
            emptyPosition.unfixed.push(tempObj)
        })

        newObj.positions = emptyPosition;

        return newObj;
    }

    return (
        <td style={{width:50}}>
            <DropdownButton variant="default" drop="left" id="dropdown-basic-button" className="action-btn" title={<FontAwesomeIcon style={{color:'#000000', fontSize:20, border:'none'}} icon="ellipsis-h"/>}>
                {globalState.currentPage == 0 && globalState.permissions.siteMenu.campaigns.create!= undefined?
                    <Dropdown.Item onClick={()=>{props.setModalIsCreateTrue(); props.setModalTitleCopy(); props.setModalVisibility(); props.setFieldValueForCopy(createCopyObj()); props.setIsEdit();}}><FontAwesomeIcon style={{marginRight:10}} icon="copy"/><span> {Constants.copiaCampagna}</span></Dropdown.Item>
                    :
                    null
                }

                {props.currentTab != null?
                    <Dropdown.Item onClick={()=>{props.loadpositionBySite(); props.setCurrentPosition();}}><FontAwesomeIcon style={{marginRight:10}} icon="exclamation-circle"/><span> {Constants.posizioniIncompatibili}</span></Dropdown.Item>
                    :null
                }

                {props.detailVisible?
                    <Dropdown.Item onClick={()=>{props.setModalIsCreate(); props.setModalTitleShow(); props.setModalVisibility(); props.setFieldValue(); props.disableEdit();}}><FontAwesomeIcon style={{marginRight:10}} icon="info"/><span> {Constants.dettaglio}</span></Dropdown.Item>
                    :
                    null
                } 

                {props.updateVisible?
                    <Dropdown.Item onClick={()=>{props.setModalIsCreate(); props.setModalTitleEdit(); props.setModalVisibility(); props.setFieldValue(); props.setIsEdit();}}><FontAwesomeIcon style={{marginRight:10}} icon="edit"/><span> {Constants.modifica}</span></Dropdown.Item>
                    :
                    null
                }

                {props.deleteVisible?
                    <Dropdown.Item onClick={()=>{props.setModalDeleteVisibility(); props.setDeleteId()}}><FontAwesomeIcon style={{marginRight:10}} icon="trash"/><span> {Constants.elimina}</span></Dropdown.Item>
                    :
                    null
                }
            </DropdownButton>
        </td>
    );
}


    

