import React, {useState, useEffect} from 'react';
import {useGlobalState, setGlobalState} from '../GlobalState';
import * as Constants from '../constants/constants';
import axios from 'axios';
import {Button, Accordion, Card, Form, Col, Row, Table, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {deleteCookie} from '../cookie';
import moment from 'moment';

export default function Forecast() {
    const [chevron, setChevron] = useState(false);
    const [site, setSite] = useState();
    const [adUnit, setAdUnit] = useState();
    const [aggregate, setAggregate] = useState();
    const [content, setContent] = useState();
    const [contentInvolved, setContentInvolved] = useState();
    const [dataSource, setDatasource] = useState();

    let globalState = useGlobalState();

    useEffect(()=>{
        setGlobalState({currentPage:5});
        serviceApiGetSiti(Constants.getSiti + '?size=9999&is_visible=True', 'GET');
    },[])


    const verifyDate = (fixed, index) =>{     
        let sDate = document.getElementById('startDate');
        let eDate = document.getElementById('endDate');
        let sHour = document.getElementById('hourStart');
        let eHour = document.getElementById('hourEnd');
        if(parseInt(sDate.value.replace(/-/g,''))>parseInt(eDate.value.replace(/-/g,''))){
            //sDate.value=""
            eDate.value="";
            return setGlobalState({showAlert:true, messageAlert:Constants.dateError, typeAlert:'warning'});
        }
        else if(parseInt(sDate.value.replace(/-/g,'')) == parseInt(eDate.value.replace(/-/g,''))){
            if(parseInt(sHour.value.replace(/T/g,'').replace(/:/g,''))>=parseInt(eHour.value.replace(/T/g,'').replace(/:/g,''))){
                //sHour.value=""
                eHour.value="";
                return setGlobalState({showAlert:true, messageAlert:Constants.hourError, typeAlert:'warning'});
            }
        }
    }

    const calcolaForecast = () =>{
        if(document.getElementById('startDate').value == '')
            return setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.requiredFieldError + Constants.dataInizio, typeAlert:'warning'});
        if(document.getElementById('endDate').value == '')
            return setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.requiredFieldError + Constants.dataFine, typeAlert:'warning'});
        if(document.getElementById('hourStart').value == '')
            return setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.requiredFieldError + Constants.oraInizio, typeAlert:'warning'});
        if(document.getElementById('hourEnd').value == '')
            return setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.requiredFieldError + Constants.oraFine, typeAlert:'warning'});
        if(document.getElementById('unit').value == '' && document.getElementById('aggregate').value == '')
            return setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.requiredFieldError + Constants.adUnit + ' o ' + Constants.aggregate, typeAlert:'warning'});

        let beginningDate = document.getElementById('startDate').value + "T" + document.getElementById('hourStart').value
        let endDate = document.getElementById('endDate').value + "T" + document.getElementById('hourEnd').value
        
        serviceApiGetForecast(Constants.getForecast + '?beginning_date=' + beginningDate + '&size=9999&end_date=' + endDate + '&ad_unit_id=' + document.getElementById('unit').value + '&aggregate_id=' + document.getElementById('aggregate').value + '&device=' + document.getElementById('device').value, 'GET')
    }

    const populateSite = (obj) =>{
        return obj.results.map((item, index) => {
            return <option key={index} value={item.id}>{item.description}</option>;
        });
    }

    const populateAdUnit = (obj) =>{

        return obj.results.map((item, index) => {
            if(item.name != null && item.name.trim() != ''){
                return <option key={index} value={item.id}>{item.name}</option>;
            }
        });
    }

    const populateAggregate = (obj) =>{
        return obj.results.map((item, index) => {
            return <option key={index} value={item.id}>{item.description}</option>;
        });
    }

    useEffect(() => {
        setContent(loadBody())
        setContentInvolved(loadBodyInvolved());
    },[dataSource])

    const loadSelect = (e) =>{
        serviceApiGetAdUnitFromSite(Constants.getAdUnit + '?position__site=' + e.target.value + '&size=9999', 'GET');
        serviceApiGetAggregateSite(Constants.getAggregate + '?site=' + e.target.value + '&size=9999', 'GET');
        document.getElementById('unit').value='';
        document.getElementById('aggregate').value='';
    }

    const loadTable = () =>{
        return(
            <div>
                <Table className="table-forecast" striped bordered hover>
                    <thead>
                        <tr>
                            <th>{Constants.name}</th>
                            <th>{Constants.avaiableImpression}</th>
                            <th>{Constants.totalImpression}</th>
                        </tr>
                    </thead>
                    {content}
                </Table>
            </div>
        );
    }

    const loadTableInvolved = () =>{
        return(
            <div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{Constants.campagne}</th>
                            <th>{Constants.position}</th>
                            <th>{Constants.impression}</th>
                            <th>{Constants.dataInizio}</th>
                            <th>{Constants.dataFine}</th>
                        </tr>
                    </thead>
                    {contentInvolved}
                </Table>
            </div>
        );
    }

    const loadBody = () =>{
        if(dataSource != undefined){
            let sommaDisponibile = 0;
            let sommaTotale = 0;
            if(dataSource.results.length > 0){
                dataSource.results.map((item, index)=>{
                    sommaDisponibile = sommaDisponibile +  parseInt(item.available_impression.replace(/[^0-9]/g, ''));
                    sommaTotale = sommaTotale + parseInt(item.total_impression.replace(/[^0-9]/g, ''));
                })
            }
            return(
                <tbody>
                    {(dataSource.results.length > 0)
                    ?
                        dataSource.results.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.ad_unit_name}</td>
                                    <td style={{textAlign:'right'}}>{item.available_impression}</td>
                                    <td style={{textAlign:'right'}}>{item.total_impression}</td>
                                </tr>
                            );
                        })
                    :
                    <tr>
                        <td colSpan="3" style={{textAlign:'center', color:'#ccc'}}><FontAwesomeIcon style={{marginRight:40, fontSize:30}} icon="box-open"/> {Constants.noDataFound}</td>
                    </tr>
                    }
                    <tr style={{backgroundColor:'#b3dcff'}}>
                        <td ><strong>{Constants.totale}</strong></td>
                        <td style={{textAlign:'right'}}><strong>{sommaDisponibile.toLocaleString().replace(/,/g, '.')}</strong></td>
                        <td style={{textAlign:'right'}}><strong>{sommaTotale.toLocaleString().replace(/,/g, '.')}</strong></td>
                    </tr>

                </tbody>
            );    
        }
    }

    const loadBodyInvolved = () =>{
        if(dataSource != undefined){
            return(
                <tbody>
                    {(dataSource.positions_involved.length > 0)
                    ?
                        dataSource.positions_involved.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.campaign}</td>
                                    <td>{item.position}</td>
                                    <td>{item.impression}</td>
                                    <td>{moment(item.beginning_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
                                    <td>{moment(item.end_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
                                </tr>
                            );
                        })
                    :
                    null}
                </tbody>
            );    
        }
    }

    return (
        <div className="container col-sm-12 table-responsive fade-in-left" style={{padding:'40px'}}>
                <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle onClick={()=>setChevron(!chevron)} as={Card.Header} eventKey="0" style={{cursor:'pointer'}}>
                        <h5><FontAwesomeIcon style={{marginRight:10}} icon={chevron?'chevron-down':'chevron-up'}/>{Constants.calcolaForecast}</h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body style={{padding:20}}>
                        <Form>
                            {globalState.isMobile?
                                <>
                                    <Form.Label>{Constants.dataInizio}</Form.Label>
                                    <Form.Control id="startDate" type="date" onChange={()=>verifyDate()}/>
                                    <br/>
                                    <Form.Label>{Constants.oraInizio}</Form.Label>
                                    <Form.Control id='hourStart' defaultValue="00:00" type="time" onChange={()=>verifyDate()}/>
                                    <br/>
                                    <Form.Label>{Constants.dataFine}</Form.Label>
                                    <Form.Control id="endDate" type="date" onChange={()=>verifyDate()}/>
                                    <br/>
                                    <Form.Label>{Constants.oraFine}</Form.Label>
                                    <Form.Control id='hourEnd' defaultValue="23:59" type="time" onChange={()=>verifyDate()}/>
                                    <br/>
                                    <Form.Label>{Constants.site}</Form.Label>
                                    <Form.Control onChange={(e)=>loadSelect(e)} id="sito" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        {site}
                                    </Form.Control>
                                    <br/>
                                    <Form.Label>{Constants.aggregate}</Form.Label>
                                    <Form.Control onChange={()=>{document.getElementById('unit').value='';}} id="aggregate" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        {aggregate}
                                    </Form.Control>
                                    <br/>
                                    <Form.Label>{Constants.adUnit}</Form.Label>
                                    <Form.Control onChange={()=>{document.getElementById('aggregate').value='';}} id="unit" as="select">
                                        <option value="">{Constants.seleziona}</option>
                                        {adUnit}
                                    </Form.Control>
                                    <br/>
                                    <Form.Label>{Constants.device}</Form.Label>
                                    <Form.Control id="device" as="select">
                                        <option value="">{Constants.allDevice}</option>
                                        <option value="mobile">{Constants.mobile}</option>
                                        <option value="desktop">{Constants.desktop}</option>
                                    </Form.Control>
                                </>
                                :
                                <>
                                    <Row>
                                        <Col>
                                            <Form.Label>{Constants.dataInizio}</Form.Label>
                                            <Form.Control id="startDate" type="date" onChange={()=>verifyDate()}/>
                                        </Col>
                                        <Col>
                                            <Form.Label>{Constants.oraInizio}</Form.Label>
                                            <Form.Control id='hourStart' defaultValue="00:00" type="time" onChange={()=>verifyDate()}/>
                                        </Col>
                                        <Col>
                                            <Form.Label>{Constants.dataFine}</Form.Label>
                                            <Form.Control id="endDate" type="date" onChange={()=>verifyDate()}/>
                                        </Col>
                                        <Col>
                                            <Form.Label>{Constants.oraFine}</Form.Label>
                                            <Form.Control id='hourEnd' defaultValue="23:59" type="time" onChange={()=>verifyDate()}/>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <Form.Label>{Constants.site}</Form.Label>
                                            <Form.Control onChange={(e)=>loadSelect(e)} id="sito" as="select">
                                                <option value="">{Constants.seleziona}</option>
                                                {site}
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Label>{Constants.aggregate}</Form.Label>
                                            <Form.Control onChange={()=>{document.getElementById('unit').value='';}} id="aggregate" as="select">
                                                <option value="">{Constants.seleziona}</option>
                                                {aggregate}
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Label>{Constants.adUnit}</Form.Label>
                                            <Form.Control onChange={()=>{document.getElementById('aggregate').value='';}} id="unit" as="select">
                                                <option value="">{Constants.seleziona}</option>
                                                {adUnit}
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Label>{Constants.device}</Form.Label>
                                            <Form.Control id="device" as="select">
                                                <option value="">{Constants.allDevice}</option>
                                                <option value="mobile">{Constants.mobile}</option>
                                                <option value="desktop">{Constants.desktop}</option>
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                </>
                            }
                            <br/>
                            <Row>
                                <Col style={{display:'flex'}}>
                                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.calcolaForecast}</Tooltip>}>
                                        <Button variant="default" type="submit" style={{marginRight:20}} onClick={(e)=>{calcolaForecast(); e.preventDefault();}}>
                                            <FontAwesomeIcon style={{marginRight:10}} icon="search"/>
                                            {Constants.calcolaForecast}
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.reset}</Tooltip>}>
                                        <Button variant="default" type="reset" style={{marginRight:20}}>
                                            <FontAwesomeIcon style={{marginRight:10}} icon="times"/>
                                            {Constants.reset}
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <br/>
            {loadTable()}
            <br/>
            {dataSource != null?
                dataSource.positions_involved.length > 0 ?
                    <>
                        <h4>{Constants.involvedPosition}</h4>
                        <br/>
                        {loadTableInvolved()}
                    </>
                :null
                
                :null
            }
            
        </div>
    );

    /////////////API//////////////////////////////////

    function serviceApiGetSiti(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setSite(populateSite(response.data))
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetForecast(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:response.data.message, typeAlert:'success'});
            setDatasource(response.data);
        })
        .catch(function (error) {
            if(error.response!=undefined && error.response.status == 500){
                setGlobalState({showSpinner:false, showAlert:true, messageAlert:error.response.data.message, typeAlert:'danger'});
            }
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetAggregateSite(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setAggregate(populateAggregate(response.data))
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetAdUnitFromSite(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setAdUnit(populateAdUnit(response.data))
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }
}


    

