import React from 'react';
import {ButtonGroup, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import * as Constants from '../constants/constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


export default function TablePagination(props) {

    const renderPagination = () =>{
        if(props.dataSource!=null){
            return(
                <div className="table-page-button">
                    <span>{Constants.actualPage}</span><strong>{props.dataSource.current}</strong> | <span>{Constants.numRow}</span><strong>{props.dataSource.count}</strong>
                    <br/><br/>
                    <ButtonGroup aria-label="First group">
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.prevPage}</Tooltip>}>
                            <Button variant="dark" style={{display:(props.dataSource.previous!=null)?"block":"none"}} onClick={()=>props.serviceApiGetPrev()}><FontAwesomeIcon icon="chevron-left"/></Button>
                        </OverlayTrigger>
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.nextPage}</Tooltip>}>
                            <Button variant="dark" style={{display:(props.dataSource.next!=null)?"block":"none"}} onClick={()=>props.serviceApiGetNext()}><FontAwesomeIcon icon="chevron-right"/></Button>
                        </OverlayTrigger>
                    </ButtonGroup>
                </div>
            )
        }
    }
    
    return(
        <>
            {renderPagination()}
        </>
    );
}


    

