
import React, { useState, useEffect } from "react";
import moment from "moment";
import ModalCampagne from '../components/modalCampagne';

import * as Constants from '../constants/constants';
import {useGlobalState, setGlobalState} from '../GlobalState';
import axios from 'axios';
import {deleteCookie} from '../cookie';
import {Spinner, Button, Form, Row, Col} from 'react-bootstrap';

import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader
} from "react-calendar-timeline/lib";
import 'react-calendar-timeline/lib/Timeline.css'

var keys = {
  groupIdKey: "id",
  groupTitleKey: "title",
  groupRightTitleKey: "rightTitle",
  itemIdKey: "id",
  itemTitleKey: "title",
  itemDivTitleKey: "title",
  itemGroupKey: "group",
  itemTimeStartKey: "start",
  itemTimeEndKey: "end",
  groupLabelKey: "title",
};

export default function Calendario(props){
    let globalState=useGlobalState();

    const [content, setContent]  = useState();
    const [datasource, setDatasource]  = useState();
    const [selectedSite, setSelectedSite]  = useState('');
    const [site, setSite]  = useState();
    const [status, setStatus]  = useState();
    const [tempSpinner, setTempSpinner] = useState('none')
    const [legend, setLegend] = useState();
    const [selectedStatus, setSelectedStatus] = useState('');
    const [statusObj, setStatusObj] = useState();
    const [modalVisibility, setModalVisibility] = useState(false);
    const [fieldValue, setFieldValue] = useState({});
    const [defaultStart, setDefaultStart] = useState(moment().startOf("month").toDate());
    const [defaultEnd, setDefaultEnd] = useState(moment().endOf("month").toDate());
    const [modalCampagne, setModalCampagne] = useState();

    useEffect(()=>{
        serviceApiGetStatus(Constants.getStatus + '?size=9999', 'GET')
    },[])

    useEffect(()=>{
        setContent();
        serviceApiGetFilterResult(Constants.getCalendar + '?date_before=' + moment(defaultStart).format('YYYY-MM-DD') + '&date_after=' + moment(defaultEnd).format('YYYY-MM-DD') + '&states_campaign=' + selectedStatus + '&site=' + selectedSite,'GET');
    },[defaultEnd, selectedSite, selectedStatus])

    useEffect(()=>{
        if(fieldValue != null){
            setModalCampagne(
                <ModalCampagne 
                    isCreate={false} 
                    reloadTable={()=>{}} 
                    closeModal={()=>closeModal()} 
                    visible={modalVisibility} 
                    dataSource={fieldValue} 
                    isEdit={false} 
                    title={Constants.dettaglio}/>
            );
        }
    },[fieldValue])

    const groupRenderer = ({ group }) => {
        const className = group.groupOnly? "highlight" : "";
        return <div className={className}>{group.title}</div>;
    };

    const closeModal = () =>{
        setModalCampagne();
        setModalVisibility(false);
        setFieldValue({});
    }
    const selectStatus = (e)=> {
        setSelectedStatus(e.target.value);
    }
    const selectSite = (e)=> {
        setSelectedSite(e.target.value);
    }

    const changeDate = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) =>{}

    useEffect(()=>{
        if(datasource != null){
            if(datasource.resources != null && datasource.events != null){
                setContent(
                    <>
                        <Timeline
                            groups={datasource.resources}
                            groupRenderer={groupRenderer}
                            horizontalLineClassNamesForGroup={(group) => group.groupOnly ? ["highlight"] : []}
                            items={datasource.events}
                            keys={keys}
                            sidebarWidth={150}
                            canSelect = {false}
                            itemsSorted
                            canMove={false}
                            canResize={false}
                            itemTouchSendsClick={false}
                            stackItems
                            itemHeightRatio={0.75}
                            itemRenderer={itemRenderer}
                            sidebarWidth={300}
                            defaultTimeStart={defaultStart}
                            defaultTimeEnd={defaultEnd}
                            onTimeChange={()=>changeDate()}
                        >
                            <TimelineHeaders style={{position:'sticky', top:0, zIndex:1000, pointerEvents:'none'}}>
                            <SidebarHeader>
                                {({ getRootProps }) => {
                                return <div {...getRootProps()}>Lista siti</div>;
                                }}
                            </SidebarHeader>
                            <DateHeader unit="primaryHeader" />
                            <DateHeader unit="day" labelFormat="D"/>
                            </TimelineHeaders>
                        </Timeline>
                    </>
                );
            }
        }
    },[datasource])
    
    const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
        const backgroundColor = itemContext.selected ? (itemContext.dragging ? "red" : item.selectedBgColor) : item.bgColor;
        const borderColor = itemContext.resizing ? "red" : item.color;
        return (
        <div
            {...getItemProps({
            style: {
                backgroundColor,
                color: item.color,
                borderColor,
                borderStyle: "solid",
                borderWidth: 0,
                borderRadius: 10,
                borderLeftWidth: itemContext.selected ? 3 : 1,
                borderRightWidth: itemContext.selected ? 3 : 1
            },
            onMouseDown: () => {
                setFieldValue(item.obj.campaign);
                setModalVisibility(true);
            }
            })}
        >
            {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

            <div
            style={{
                height: itemContext.dimensions.height,
                overflow: "hidden",
                paddingLeft: 3,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }}
            >
            {itemContext.title}
            </div>

            {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
        </div>
    );};

    const loadLegend=(obj)=>{
        return obj.results.map((item, index)=>{
            return (
                <div key={index} style={{display:'flex', float:'left', marginRight:30, marginBottom:10}}>
                    <div style={{width:25, height:25, backgroundColor:'#' + item.color, marginRight:10}}></div>
                    <span style={{width:70}}>{item.description}</span>
                </div>
            );
        })
    }

    const populateStatus = (obj) =>{
        return obj.results.map((item, index) => {
            return <option key={index} value={item.id}>{item.description}</option>;
        })
    }

    return (
        <>
            <div className="custom-overlay-2" style={{display:tempSpinner, zIndex:4000}}>
                <div className='spinner-position fade-in'>
                <Spinner animation="border" variant="primary" />
                </div>
            </div>
            <div className="container col-sm-12 table-responsive fade-in-left" style={{paddingLeft:40, paddingRight:40, paddingBottom:40, paddingTop:10, overflow:'hidden'}}>
                <Row style={{marginLeft:3}}>
                    <Col>
                        <div>
                            <Form.Label>Mese</Form.Label>
                            <Form.Control id="select-month" type="month" onChange={(e)=>{
                                let newDate = new Date(moment(e.target.value + '-01').endOf('month'));
                                newDate.setDate(newDate.getDate() - 1);
                                let start = moment(newDate).startOf('month');
                                let end = moment(newDate).endOf('month');
                                setDefaultStart(moment(start).toDate());
                                setDefaultEnd(moment(end).toDate());
                            }}/>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <Form.Label>{Constants.states_campaign}</Form.Label>
                            <Form.Control id="status_filter" as="select" value={selectedStatus} onChange={(e)=>selectStatus(e)}>
                                <option value="">{Constants.seleziona}</option>
                                {status}
                            </Form.Control>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <Form.Label>{Constants.site}</Form.Label>
                            <Form.Control id="site_filter" as="select" value={selectedSite} onChange={(e)=>selectSite(e)}>
                                <option value="">{Constants.tutti}</option>
                                {site}
                            </Form.Control>
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row style={{marginLeft:18}}>
                    {legend}
                </Row>
            </div>
            <Row style={{position:'sticky', top:0, zIndex:1000, width:'100%', justifyContent:'flex-end', zIndex:1001}}>
                <div>
                    <Button variant="info" style={{marginRight:20, fontSize:10, fontWeight:'bold', marginTop:0, outline:'none', boxShadow:'none'}} onClick={()=>{
                        document.getElementById('select-month').value = "";
                        let newDate = new Date(defaultStart);
                        newDate.setDate(newDate.getDate() - 1);
                        let start = moment(newDate).startOf('month');
                        let end = moment(newDate).endOf('month');
                        setDefaultStart(moment(start).toDate());
                        setDefaultEnd(moment(end).toDate());
                    }}>{"<"} Mese precedente</Button>
                    <Button variant="info" style={{fontSize:10, fontWeight:'bold', marginTop:0, outline:'none', boxShadow:'none'}} onClick={()=>{
                        document.getElementById('select-month').value = "";
                        let newDate = new Date(defaultEnd);
                        newDate.setDate(newDate.getDate() + 1);
                        let start = moment(newDate).startOf('month');
                        let end = moment(newDate).endOf('month');
                        setDefaultStart(moment(start).toDate());
                        setDefaultEnd(moment(end).toDate());
                    }}>Mese successivo {">"}</Button>
                </div>
            </Row>
            <br/>
            {content}
            {modalCampagne}
        </>
    );

    function serviceApiGetStatus(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setStatusObj(response.data);
            setLegend(loadLegend(response.data));
            setStatus(populateStatus(response.data));
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetFilterResult(url, method){
        setTempSpinner('block');
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {

            let dataSource = {
                resources:[],
                events:[],
            }

            let posDist = [];
            let pos = [];
            let app_id = [];
            let pos_father = [];            
            response.data.results.map((item, index)=>{
                let currentPos ={
                    obj:item,
                    position:null,
                    states_campaign:null,
                    id_campaign:null,
                    description_campaign:null
                }
                currentPos.position=item.position;
                currentPos.states_campaign=item.campaign.states_campaign;
                currentPos.id_campaign=item.campaign.id;
                currentPos.description_campaign=item.campaign.description;
                pos.push(currentPos)
            })

            pos.map((item, index)=>{
                if (! pos_father.includes(item.position.site.id)){
                    pos_father.push(item.position.site.id)
                    let current = {
                        id: item.position.site.id,
                        title: item.position.site.description,
                        groupOnly: true
                    }
                    dataSource.resources.push(current)
                    
                }
                if (! app_id.includes(item.position.id)){
                    posDist.push(item.position);
                    app_id.push(item.position.id)
                }
                let beginning = item.obj.beginning_date.split("T");
                let beginningHour = beginning[1].split("+");
                let end = item.obj.end_date.split("T");
                let endHour = end[1].split("+");
                let event= {
                    obj:item.obj,
                    id: index,
                    start: new Date(beginning[0] + ' ' + beginningHour[0]).getTime(),
                    end: new Date(end[0] + ' ' + endHour[0]).getTime(),
                    group: item.position.id + "_" + item.position.site.id,
                    title: item.description_campaign,
                    bgColor: '#' + item.states_campaign.color,
                    selectedBgColor:'#' + item.states_campaign.color,
                    color: '#ffffff',
                }
  
                dataSource.events.push(event);
            })

            posDist.map((item,index)=>{
                let current = {
                    id: item.id + "_" + item.site.id,
                    title: item.area.description + " / " + item.type.description,
                    parentId: item.site.id
                }
                dataSource.resources.push(current)
            })

            
            dataSource.resources.sort(function (a, b) {
                let asort = a.id.toString().split('_')[0];
                if(a.id.toString().split('_')[1] != null)
                    asort = a.id.toString().split('_')[1]

                let bsort = b.id.toString().split('_')[0];
                if(b.id.toString().split('_')[1] != null)
                    bsort = b.id.toString().split('_')[1]
                return asort - bsort;
            });

            

            let siteOptions = [];
            dataSource.resources.map((item, index)=>{
                if(item.groupOnly == true)
                    siteOptions.push(<option key={index} value={item.id}>{item.title}</option>)
            })

            setSite(siteOptions);
            setDatasource(dataSource);

            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.eventCalendarMessage + ' ' + siteOptions.length, typeAlert:'primary'});
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
        setTimeout(() => {
            setTempSpinner('none');
        }, 5000);
    }
}

