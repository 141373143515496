export const nameSite = "ADVM";

/*ALERT MESSAGE*/
export const genericError = "Si è verificato un errore, riprova tra qualche istante.";
export const unautorizedError = "Utente o password non validi!";
export const successMessage = "Operazione avvenuta con successo!";
export const searchMessage = "Numero di righe trovate:";
export const eventCalendarMessage = "Esistono posizioni per il seguente numero di siti:";
export const dateError = "La data di inizio non può essere maggiore alla data di fine!";
export const requiredFieldError = "Ci sono dei campi obbligatori che non sono stati compilati : ";
export const maxPosition = "Hai raggiunto il numero massimo di posizioni!"
export const vecchiaPassErrata = "La vecchia password inserita è errata!"
export const passAggiornata = "Password aggiornata con successo!"
export const hourError = "Verificare che l'ora di fine sia maggiore di quella d'inizio!";
export const dateEmptyError = "Devi compilare i campi data!";
export const successCopy = "La posizione è stata copiata correttamente!";
export const successCopyCampaingLink = "Il link della campagna è stato copiato correttamente!";
export const searchMessagePositions = "Numero di posizioni trovate:";

/*NETWORK ID*/
//export const networkID = "21927738582"; //TEST
export const networkID = "9528481"; //PROD

/*ENDPOINT*/
export const getToken = "token/";
export const refreshToken = "token/refresh/";
export const verifyToken = "token/verify/";
export const getUtentiAttivi = "users/list/";
export const getCampagne = "campaigns/list/";
export const getCampagneChecklist = "campaigns/*/checklist/";
export const getCampagnePositions = "campaigns/position/list/";
export const updateCampagnePositions = "campaigns/position/*/update/";
export const updateCampagnePositionsMaterial = "campaigns/position/*/update-material/";
export const updateCampagneChecklist = "campaigns/*/checklist/+/update/";
export const getCalendar = "campaigns/calendar/list/";
export const updateCampagne = "campaigns/*/update/";
export const insertCampagne = "campaigns/create/";
export const deleteCampagne = "campaigns/*/delete/";
export const getClientiDiretti = "customers/direct/list/";
export const deleteClientiDiretti = "customers/direct/*/delete/";
export const updateClientiDiretti = "customers/direct/*/update/";
export const insertClientiDiretti = "customers/direct/create/";
export const getSettori = "customers/sector/list/";
export const deleteSector = "customers/sector/*/delete/";
export const updateSettori = "customers/sector/*/update/";
export const insertSettori = "customers/sector/create/";
export const getSettoriPerIdCliente = "customers/direct/*/sector/";
export const getAgenzie = "customers/agency/list/";
export const insertAgenzie = "customers/agency/create/";
export const deleteAgenzie = "customers/agency/*/delete/";
export const updateAgenzie = "customers/agency/*/update/";
export const getPagamenti = "customers/payment/list/";
export const getTipi = "positions/type/list/";
export const insertTipi = "positions/type/create/";
export const updateTipi = "positions/type/*/update/";
export const deleteTipi = "positions/type/*/delete/";
export const getArea = "positions/area/list/";
export const insertArea = "positions/area/create/";
export const updateArea = "positions/area/*/update/";
export const deleteArea = "positions/area/*/delete/";
export const getSiti = "positions/site/list/";
export const insertSiti = "positions/site/create/";
export const updateSiti = "positions/site/*/update/";
export const deleteSiti = "positions/site/*/delete/";
export const getPosition = "positions/position/list/";
export const getPositionIncompatible = "positions/incompatible/list/";
export const insertPositionIncompatible = "positions/incompatible/create/";
export const updatePositionIncompatible = "positions/incompatible/*/update/";
export const insertPosition = "positions/position/create/";
export const updatePosition = "positions/position/*/update/";
export const deletePosition = "positions/position/*/delete/";
export const getStatus = "campaigns/state/list/";
export const getReport="reports/generate/";
export const changePassword = "users/password/change/";
export const importToAdManager = "admanager/campaign/*/import/"
export const getAdUnit = "positions/unit/list/";
export const insertAdUnit = "positions/unit/create/";
export const deleteAdUnit = "positions/unit/*/delete/";
export const updateAdUnit = "positions/unit/*/update/";
export const getForecast = "admanager/forecast/availability/";
export const getAggregate = "positions/aggregate/list/";
export const insertAggregate = "positions/aggregate/create/";
export const updateAggregate = "positions/aggregate/*/update/";
export const deleteAggregate = "positions/aggregate/*/delete/";
export const updateStatistics = "admanager/campaign/*/delivered/";
export const getCustomTargeting = "positions/custom_targeting/list/";
export const deleteCustomTargeting = "positions/custom_targeting/*/delete/";
export const insertCustomTargeting = "positions/custom_targeting/create/";
export const updateCustomTargeting = "positions/custom_targeting/*/update/"; 
export const getScheduler = "campaigns/position/scheduler/";


export const goToAD = "https://admanager.google.com/*#delivery/order/order_overview/order_id=";
export const shareLink = "https://advmanager.netaddiction.it/" //PROD;
//export const shareLink = "http://localhost:3000/" //LOCALE



/*GENERAL*/
export const modifica = "Modifica";
export const elimina = "Elimina";
export const dettaglio = "Dettaglio";
export const nuovo = "Nuovo";
export const salva = "Salva";
export const chiudi = "Chiudi";
export const annulla = "Annulla";
export const esci = "Esci";
export const identificativo = "Identificativo";
export const numRow = "Numero di righe totali: ";
export const crea = "Crea";
export const deleteQuestion = "Sei sicuro di voler eliminare la seguente riga?";
export const cerca = "Cerca";
export const filtiDiRicerca = "Filtri di ricerca";
export const actualPage = "Pagina corrente: ";
export const noDataFound = "Non ci sono dati da visualizzare.."
export const nome = "Nome";

/*LOGIN*/
export const username = "Nome Utente";
export const placeholderUsername = "Inserisci Nome Utente..";
export const password = "Password";
export const placeholderPassword = "Inserisci Password..";
export const login = "Accedi";

/*MENU*/
export const campagne = "Campagne";
export const clienti = "Clienti";
export const calendario = "Calendario";
export const position = "Posizioni";
export const report = "Report";
export const forecast = "Forecast"

/*INPUT-COMPONENTS*/
export const referente = "Referente";
export const creatore = "Venditore";
export const referentePlaceholder = "Scrivi il referente..";
export const description = "Descrizione";
export const cliente = "Cliente";
export const categoriaCentromedia = "Categoria Centromedia";
export const categoriaCentromediaRequired = "Hai selezionato un 'Agenzia' devi selezionare una Categoria Centromedia";
export const customersRequired =  "Devi selezionare un cliente!"
export const customersOrAgencyRequired = "Devi selezionare un cliente o un agenzia!"
export const agenzia = "Agenzia";
export const descrizionePlaceholder = "Scrivi una descrizione..";
export const nota = "Nota";
export const notaCliente = "Nota cliente";
export const notaPlaceholder = "Scrivi una nota..";
export const dataInizio = "Data inizio";
export const dataFine = "Data fine";
export const oraInizio = "Ora inizio";
export const oraFine = "Ora fine";
export const diretta = "Diretto?";
export const percentage_commission = "Percentuale";
export const percentualePlaceholder = "Inserisci una percentuale %..";
export const settore = "Settore";
export const dfp_id = "DFP";
export const dfpPlaceholder = "inserisci un DFP id valido..";
export const ricercaDescrizionePlaceholder = "Cerca..";
export const sector = "Settori selezionati";
export const sectorToSelect = "Settori da selezionare";
export const seleziona = "Seleziona..";
export const tutti = "Tutti i siti";
export const codiceUnivoco = "Codice univoco";
export const business_name = "Ragione sociale";
export const ragSocialePlaceholder = "Inserisci una ragione sociale..";
export const terminiPagamento = "Termini di pagamento";
export const addTerminiPagamento = "Aggiungi termini di pagamento";
export const descrizioneCampagna = "Descrizione";
export const referent = "Referente";
export const dataInizioCampagna = "Data di inizio";
export const dataFineCampagna = "Data di fine";
export const dataCreazioneCampagna = "Data creazione";
export const dataModificaCampagna = "Data ultima modifica";
export const modalTitleNew = "Creazione";
export const modalTitleEdit = "Modifica";
export const modalTitleShow = "Dettaglio";
export const fissaQuestion = "è una posizione fissa?"
export const nuovaCampagna = "Nuova campagna";
export const agenzie = "Agenzie";
export const clientiDiretti = "Clienti diretti";
export const settori = "Settori";
export const siti = "Siti";
export const aree = "Aree";
export const tipi = "Tipi";
export const fissa = "Fissa";
export const nonFissa = "Non fissa";
export const posizione = "Posizione";
export const percentage = "Quota ripartizione";
export const quotaRipartizionePlaceholder = "Inserisci quota ripartizione..";
export const site = "Sito";
export const area = "Area";
export const type = "Tipo";
export const impression_month = "Impression mensili";
export const impression_monthPlaceholder = "inserisci le Impression mensili..";
export const impression = "Impression";
export const click = "Click";
export const ctr = "CTR";
export const impressionPlaceholder = "Inserisci un numero di impression..";
export const visibileQuestion = "Visibile?";
export const visibile = "Visibile";
export const si = "SI";
export const no = "NO";
export const cpmVendita = "CPM vendita";
export const cpmGiro = "CPM giro";
export const forfaitVendita = "Forfait vendita";
export const forfaitGiro = "Forfait giro";
export const nuovaPosizione = "Nuova posizione";
export const inserisciValorePlaceholder = "Inserisci un valore..";
export const noPaymentFound = "Non ci sono metodi di pagamento..";
export const positionsRequiredField = "Verificare il seguente campo obbligatorio nella posizione";
export const states_campaign = "Stato";
export const stati = "Stati";
export const sectorRequired = "Hai selezionato un cliente, devi selezionare un settore!";
export const paymentRequired="Attivare un metodo di fatturazione!";
export const sconto="Sconto";
export const placeholderSconto="Scrivi un importo per lo sconto";
export const motivazioneSconto="Motivazione sconto";
export const placeholderMotivazioneSconto="Per quale motivo si sta effettuando lo sconto?";
export const importo="Importo";
export const importoGiro="Importo giro";
export const tipologia="Tipologia";
export const netSiteQuestion="È un sito netaddiction?";
export const downloadReport="Scarica report";
export const nuovoMetodoQuestion="Vuoi usare il nuovo metodo?";
export const generaReport="Genera il report";
export const nomeCampagna="Nome campagna";
export const cambiaPassword="Cambia password";
export const vecchiaPassword="Vecchia password";
export const nuovaPassword="Nuova password";
export const confermaNuovaPassword="Conferma nuova password";
export const passwordNonCorrispondono="Le due nuove password inserite non corrispondono!";
export const inserisciVecchiaPass="Devi inserire la vecchia password!";
export const importAdManager="Importa in AD Manager";
export const importAdManagerQuestion="Sei sicuro di voler importare questa campagna in AD Manager?";
export const adUnit="AD Unit";
export const dominanceQuestion="È una dominance?";
export const width="Larghezza";
export const widthPlaceholder="Inserisci una larghezza..";
export const height="Altezza";
export const heightPlaceholder="Inserisci un altezza..";
export const dfp_adunit_id = "AdManager AD Unit ID";
export const dfp_adunit_idPlaceholder = "Inserisci un AdManager AD Unit ID..";
export const line_item_name = "Nome dell'elemento pubblicitario";
export const line_item_namePlaceholder = "Inserisci un nome pubblicitario..";
export const dominance_position_ids = "ID posizioni dominance";
export const dominance_position_idsPlaceholder = "Inserisci alcuni ID..";
export const positionToSelect = "Posizioni da selezionare";
export const positionSelected = "Posizioni selezionate";
export const dominancePositionRequired = "Devi scegliere almeno una posizione per la dominance selezionata";
export const unsupportedDimension = "Questa dimensione non è supportata!";
export const viewonADManager = "Visualizza in AD Manager";
export const referentsToSelect = "Referenti da selezionare";
export const referents = "Referenti selezionati";
export const venditoreSecondarioQuestion = "Vuoi inserire un venditore secondario?";
export const venditoreSecondario = "Venditore secondario";
export const percentualeVenditoreSecondario = "Percentuale";
export const percentualeVenditoreSecondarioPlaceholder = "Inserisci una percentuale %..";
export const copiaPosizione = "Copia posizione";
export const copiaCampagna = "Copia campagna";
export const vat_number = "Partita IVA";
export const address = "Indirizzo";
export const city = "Città";
export const recipient_code = "Codice destinatario fattura elettronica";
export const pec = "Pec";
export const administrative_email = "E-mail amministrativa";
export const administrative_contact = "Referente amministrativo";
export const telephone_number = "Numero di telefono";
export const vat_numberPlaceholder = "Inserisci una partita IVA..";
export const addressPlaceholder = "Inserisci una indirizzo, un civico ed un CAP..";
export const cityPlaceholder = "Inserisci una città..";
export const recipient_codePlaceholder = "Inserisci un codice destinatario..";
export const pecPlaceholder = "Inserisci una pec..";
export const administrative_emailPlaceholder = "Inserisci un e-mail amministrativa..";
export const administrative_contactPlaceholder = "Inserisci un referente amministrativo..";
export const telephone_numberPlaceholder = "Inserisci un numero di telefono..";
export const rotateDevice = "Ruota il tuo dispositivo!";
export const calcolaForecast = "Calcola forecast";
export const azioni = "Azioni";
export const attivo = "Attivo";
export const isActiveQuestion = "È un sito attivo?";
export const mostUsed = "TUTTE LE POSIZIONI";
export const name = "Nome";
export const adUnitNamePlaceholder = "Inserisci un nome..";
export const reset = "Reset";
export const aggregate = "Aggregati";
export const adUnitDaSelezionare = "AD Unit da selezionare";
export const adUnitSelezionate = "AD Unit selezionate";
export const avaiableImpression = "Impression disponibili";
export const totalImpression = "Impression totali";
export const incompatiblePosition = "La seguente posizione è incompatibile:";
export const totale = "TOTALE:";
export const percentage_distribution = "Percentuale erogabile";
export const percentage_distributionPlaceholder = "Inserisci una percentuale..";
export const device = "Tipo dispositivo";
export const allDevice = "Tutti i dispositivi";
export const mobile = "Mobile";
export const desktop = "Desktop";
export const copiaLink = "Copia link";
export const prevPage = "Pagina precedente";
export const nextPage = "Prossima pagina";
export const posizioniIncompatibili = "Posizioni incompatibili";
export const scheduler = "Scheduler";
export const oggi = "Oggi";
export const domani = "Domani";
export const tra2gg = "Tra 2 gg";
export const tra3gg = "Tra 3 gg";
export const tra4gg = "Tra 4 gg";
export const tra5gg = "Tra 5 gg";
export const tra6gg = "Tra 6 gg";
export const thisWeek = "Questa settimana";
export const nextWeek = "Prossima settimana";
export const tuttoIlPrenotato = "Tutto il prenotato da oggi";
export const proiezione = "Proiezione";
export const hd = "HDBlog.it";
export const multiplayer = "Multiplayer.it";
export const materialiPresenti = "Materiali presenti?";
export const deficit = "Deficit";
export const adBlockTitle = "ADBlock rilevato!";
export const verifyAdBlock = "Controlla ADBlock";
export const messageAdBlock = "Disattiva AdBlock per continuare la navigazione!";
export const diFatturazione = "di fatturazione";
export const utenteUltimaModifica = "Utente ultima modifica";
export const fatturazione = "Fatturazione";
export const invoiceAgencyRequired = "Hai selezionato un agenzia, devi selezionare un agenzia di fatturazione!";
export const reportCampagna = "Per campagna";
export const reportPosizione = "Per posizione";
export const reportCliente = "Per cliente / agenzia";
export const needDate = "Devi compilare i campi data!";
export const nellaPosizione = "nella posizione";
export const statistiche = "Statistiche campagna";
export const aggiorna = "Aggiorna statistiche";
export const attendi = "attendi";
export const secondi = "secondi";
export const saveCampaingMessage = "Ci sono una o più posizioni con la data di inizio inferiore alla data odierna, vuoi salvare lo stesso la campagna?";
export const confermaSalvataggioCampagna = "Conferma salvataggio";
export const nonSalvare = "Non salvare";
export const exitWithoutSave = "Sei sicuro di voler uscire senza salvare?";
export const salvaContinua = "Salva e continua";
export const impressionBooked = "Impression prenotate";
export const involvedPosition = "Posizioni Coinvolte";
export const underground = "Underground";
export const customTargeting = "Custom targeting";
export const chiaveValoreToSelect = "Coppie chiave-valore da selezionare";
export const chiaveValoreSelected = "Coppie chiave-valore selezionate";
export const dfp_key_id = "DFP KEY";
export const dfp_value_id = "DFP VALUE";
export const placeholderCTname = "Inserisci il nome custom targeting..";
export const placeholderDFPkey = "Inserisci DFP KEY..";
export const placeholderDFPvaluje = "Inserisci il valore DFP..";
export const purchase_order = "Ordine di acquisto";
export const purchaseOrderPlaceholder = "Inserisci PO..";
export const downloadStatistics = "Scarica statistiche"
export const perFatturazione = "Vuoi generare il report per fatturazione?";
export const exportGO = "Esporta file GO";
export const toDO = "Cose da fare";